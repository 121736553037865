import SummaryCard from "../Cards/Summary/Summary";
import { Project, Summary } from "@/types/entities/Project";
import RequirementsSummaryCard from "../Cards/RequirementsSummary/requirementsSummaryCard";
import { TrackingCard } from "../Cards/TrackingCard";
import { SeevClient } from "@/lib/SeevClient";
import { useState } from "react";

interface DashboardProps {
  project: Project;
  onSave: () => Promise<void>;
}
function Dashboard({ project, onSave }: DashboardProps) {
  const [loading, setLoading] = useState(false);

  const updateSummary = async (updatedSummary: Summary) => {
    setLoading(true);
    try {
      await SeevClient.project.updateProject(project.id, {
        summary: updatedSummary,
      });
      await onSave();
    } catch (error) {
      console.error("Failed to update summary", error);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      <div className="flex-1 w-full xl:w-1/2 h-full">
        <SummaryCard
          project={project}
          loading={loading}
          updateSummary={updateSummary}
        />
      </div>
      <div className="flex w-full xl:w-1/2 h-full flex-col gap-4">
        <RequirementsSummaryCard project={project} />
        <TrackingCard project={project} />
      </div>
    </div>
  );
}

export default Dashboard;
