import { FormattedMessage } from "react-intl";

interface ComingSoonOverlayProps {
  color?: "none";
}

function ComingSoonOverlay({ color }: ComingSoonOverlayProps) {
  const bg = color === "none" ? "bg-transparent" : "bg-slate-500";
  return (
    <div
      className={`absolute left-[220] h-full w-full  ${bg} opacity-70 text-6xl text-gray-300 `}
    >
      <div className="flex justify-center items-center h-full w-full">
        <FormattedMessage id="global.comingSoon" />
      </div>
    </div>
  );
}

export { ComingSoonOverlay };
