import { useForm } from "react-hook-form";
import { z } from "zod";
import * as Sentry from "@sentry/react";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useIntl, FormattedMessage } from "react-intl";
import { Card } from "@/components/ui/card";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import { toast } from "@/hooks/ToastHook/use-toast";
import { Toaster } from "@/components/Toaster/Toaster";

export function SignUp() {
  const intl = useIntl();

  const signupSchema = z
    .object({
      firstName: z.string().min(1, "signup.validation.firstNameRequired"),
      lastName: z.string().min(1, "signup.validation.lastNameRequired"),
      companyName: z.string().min(1, "signup.validation.companyNameRequired"),
      workEmail: z
        .string()
        .email("signup.validation.invalidEmail")
        .min(1, "signup.validation.emailRequired"),
      password: z
        .string()
        .min(8, "signup.validation.passwordLength")
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "signup.validation.passwordComplexity",
        ),
      confirmPassword: z.string(),
      terms: z.boolean().refine((val) => val === true, {
        message: "signup.validation.termsRequired",
      }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "signup.validation.passwordsMatch",
      path: ["confirmPassword"],
    });

  type SignupSchemaType = z.infer<typeof signupSchema>;

  const customResolver = async (values: SignupSchemaType) => {
    try {
      await signupSchema.parseAsync(values);
      return { values, errors: {} };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors = error.errors.reduce(
          (acc: Record<string, { type: string; message: string }>, curr) => {
            const path = curr.path.join(".");
            acc[path] = {
              type: curr.code,
              message: intl.formatMessage({ id: curr.message }),
            };
            return acc;
          },
          {},
        );
        return { values: {}, errors };
      }
      return { values: {}, errors: {} };
    }
  };

  const form = useForm<SignupSchemaType>({
    resolver: customResolver,
    defaultValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      workEmail: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
  });

  const onSubmit = async (data: SignupSchemaType) => {
    try {
      const response_status = await SeevClient.authManager.signUp({
        firstName: data.firstName,
        lastName: data.lastName,
        organizationName: data.companyName,
        email: data.workEmail,
        password: data.password,
      });

      if (response_status === 201) {
        toast({
          title: intl.formatMessage({ id: "signup.successTitle" }),
          description: intl.formatMessage({
            id: "signup.successDescription",
          }),
          variant: "success",
        });
        await SeevClient.authManager.login(data.workEmail, data.password);
      } else {
        form.setError("root", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.generalError" }),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof Error && error.message === "user_already_exists") {
        toast({
          title: intl.formatMessage({ id: "signup.userAlreadyExistsTitle" }),
          description: intl.formatMessage({
            id: "signup.userAlreadyExistsDescription",
          }),
          variant: "warning",
        });
        form.setError("workEmail", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.userAlreadyExistsTitle" }),
        });
      } else {
        form.setError("root", {
          type: "manual",
          message: intl.formatMessage({ id: "signup.generalError" }),
        });
      }
    }
  };

  return (
    <div className="w-full max-w-md">
      <Card className="p-8 bg-white/80 dark:bg-gray-800/80 rounded-lg shadow-lg">
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100">
            <FormattedMessage id="signup.title" />
          </h1>
          <p className="mt-2 text-xl text-center text-indigo-600 dark:text-indigo-400">
            <FormattedMessage id="signup.catchphrase" />
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            {/* First Name */}
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "signup.firstName",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Last Name */}
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "signup.lastName",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Work Email */}
            <FormField
              control={form.control}
              name="workEmail"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={intl.formatMessage({
                        id: "signup.workEmail",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Company Name */}
            <FormField
              control={form.control}
              name="companyName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "signup.companyName",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Password */}
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={intl.formatMessage({
                        id: "signup.password",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Confirm Password */}
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={intl.formatMessage({
                        id: "signup.confirmPassword",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Privacy Policy Check */}
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                    />
                  </FormControl>
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    <FormattedMessage
                      id="signup.agreeTerms"
                      values={{
                        termsLink: (chunks) => (
                          <a
                            href="https://www.example.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-600 dark:text-indigo-400 hover:underline"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </span>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              <FormattedMessage id="global.signUp" />
            </Button>
          </form>
        </Form>
        <div className="mt-4 text-center">
          <a
            href="/login"
            className="text-sm text-indigo-600 dark:text-indigo-400 hover:underline"
          >
            <FormattedMessage id="signup.alreadySignedUp" />
          </a>
        </div>
      </Card>
      <Toaster />
    </div>
  );
}

export default SignUp;
