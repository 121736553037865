import { useEffect, useRef, useMemo, useState } from "react";
import Chart from "chart.js/auto";
import {
  subMonths,
  subWeeks,
  subDays,
  addMonths,
  addWeeks,
  addDays,
  parseISO,
  format,
} from "date-fns";
import { BiddingFunnelRate } from "@/types/entities/Analytics";
import { ChartConfiguration } from "chart.js";
import "chartjs-adapter-date-fns";
import { Switch } from "@/components/ui/switch";

interface Props {
  data: BiddingFunnelRate[];
  groupBy: "month" | "week" | "day";
  chartColors: { gridColor: string; textColor: string };
}

function BiddingFunnelRatesChart({ data, groupBy, chartColors }: Props) {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [showFuture, setShowFuture] = useState(false);

  const { filteredData, startDate, endDate } = useMemo(() => {
    const currentDate = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (groupBy) {
      case "month":
        startDate = subMonths(currentDate, showFuture ? 0 : 3);
        endDate = addMonths(currentDate, showFuture ? 3 : 0);
        break;
      case "week":
        startDate = subWeeks(currentDate, showFuture ? 0 : 6);
        endDate = addWeeks(currentDate, showFuture ? 6 : 0);
        break;
      case "day":
        startDate = subDays(currentDate, showFuture ? 0 : 15);
        endDate = addDays(currentDate, showFuture ? 15 : 0);
        break;
    }

    const filteredData = data.filter(
      (item) =>
        parseISO(item.submissionDate) >= startDate &&
        parseISO(item.submissionDate) <= endDate,
    );

    return { filteredData, startDate, endDate };
  }, [data, groupBy, showFuture]);

  useEffect(() => {
    if (chartRef.current && filteredData.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const nonZeroFields = Object.keys(filteredData[0]).filter(
        (key) =>
          key !== "date" &&
          filteredData.some(
            (item) => Number(item[key as keyof BiddingFunnelRate]) > 0,
          ),
      );

      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const config: ChartConfiguration<"bar", { x: number; y: number }[]> = {
          type: "bar",
          data: {
            labels: filteredData.map((item) => parseISO(item.submissionDate)),
            datasets: nonZeroFields.map((field) => ({
              label: field.charAt(0).toUpperCase() + field.slice(1),
              data: filteredData.map((item) => ({
                x: parseISO(item.submissionDate).getTime(),
                y: item[field as keyof BiddingFunnelRate] as number,
              })),
              backgroundColor: getColorForField(field),
            })),
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                bottom: 4,
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  font: {
                    size: 16,
                  },
                  boxWidth: 15,
                  padding: 8,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    if (context[0].parsed.x) {
                      return format(new Date(context[0].parsed.x), "PPP");
                    }
                    return "";
                  },
                },
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                titleColor: "#ffffff",
                bodyColor: "#ffffff",
                borderColor: "rgba(255, 255, 255, 0.2)",
                borderWidth: 1,
              },
            },
            scales: {
              x: {
                stacked: true,
                type: "time",
                time: {
                  unit: groupBy,
                  displayFormats: {
                    day: "MMM d",
                    week: "MMM d",
                    month: "MMM yyyy",
                  },
                },
                min: startDate.getTime(),
                max: endDate.getTime(),
                title: {
                  display: true,
                  text: "Submission Deadline",
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                  color: chartColors.textColor,
                },
                ticks: {
                  source: "auto",
                  autoSkip: false,
                  maxRotation: 0,
                  font: {
                    size: 14,
                  },
                  color: chartColors.textColor,
                },
                grid: {
                  color: chartColors.gridColor,
                },
              },
              y: {
                stacked: true,
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Count",
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                  color: chartColors.textColor,
                },
                grid: {
                  color: chartColors.gridColor,
                },
                ticks: {
                  color: chartColors.textColor,
                  stepSize: 1,
                  precision: 0,
                  font: {
                    size: 16,
                  },
                },
              },
            },
          },
        };

        chartInstance.current = new Chart(ctx, config);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [filteredData, groupBy, chartColors, startDate, endDate]);

  function getColorForField(field: string): string {
    const colorMap: { [key: string]: string } = {
      goNoGo: "#645ae6",
      noGo: "#a6d2f0",
      proposal: "#9f8dee",
      canceled: "#d6ecf6",
      submitted: "#87d2f0",
      won: "#7a6ee6",
      execution: "#b19ee6",
      completed: "#a3c2f4",
      lost: "#cec2f4",
    };
    return colorMap[field] || "#cccccc"; // default to a neutral gray if no color is defined
  }

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-xl flex items-center justify-center h-full">
          No data available
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-end items-center mb-4 space-x-2">
        <span className="text-sm text-gray-400 dark:text-gray-600">Past</span>
        <Switch
          checked={showFuture}
          onCheckedChange={setShowFuture}
          aria-label="Toggle future datapoints"
        />
        <span className="text-sm text-gray-400 dark:text-gray-600">Future</span>
      </div>
      <div className="flex-grow" style={{ minHeight: "400px" }}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
}

export default BiddingFunnelRatesChart;
