import { FormattedMessage } from "react-intl";
import { RiseLoader } from "react-spinners";

interface LoadingProps {
  messageId: string;
}

function Loading({ messageId }: LoadingProps) {
  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="flex flex-col gap-10 justify-center items-center">
          <RiseLoader color="hsl(var(--primary))" />
          <p className="text-xl text-muted-foreground max-w-[600px] text-center">
            <FormattedMessage id={messageId} />
          </p>
        </div>
      </div>
    </div>
  );
}

export { Loading };
