import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { FormattedMessage } from "react-intl";

interface RequirementSummary {
  category: string;
  count: number;
  total: number;
}

interface RequirementsSummaryProps {
  requirements: RequirementSummary[];
}

function RequirementSummaryItem({
  category,
  count,
  total,
}: RequirementSummary) {
  return (
    <Card className="flex items-center justify-between p-4 rounded-lg shadow-md gap-4 flex-col">
      <div className="flex w-full items-center justify-between">
        <h3 className="text-sm font-medium">
          <FormattedMessage id={`project.requirementCategory.${category}`} />
        </h3>
        <p className="text-sm text-muted-foreground">
          {count}/{total} <FormattedMessage id="project.requirementSummary" />
        </p>
      </div>
      <Progress value={(count * 100) / total} />
    </Card>
  );
}

function RequirementsSummaryList({ requirements }: RequirementsSummaryProps) {
  return (
    <ScrollArea className=" h-[243px]">
      <div className="flex gap-4 flex-col">
        {requirements.map((requirement) => (
          <RequirementSummaryItem
            key={requirement.category}
            category={requirement.category}
            count={requirement.count}
            total={requirement.total}
          />
        ))}
      </div>
    </ScrollArea>
  );
}

export default RequirementsSummaryList;
