import { Summary } from "@/types/entities/Project";
import { Element } from "./EditTableView";
import { FormattedMessage } from "react-intl";
import EditableListCard from "./EditableListCard";

interface FirmRequiredExperiencesSectionProps {
  summary: Summary;
  loading: boolean;
  onSubmit: (summary: Summary) => Promise<void>;
}

function FirmRequiredExperiencesSection(
  props: FirmRequiredExperiencesSectionProps,
) {
  const { summary, onSubmit, loading } = props;

  const handleSubmit = async (firmLevelExperiencesAndExpertises: Element[]) => {
    await onSubmit({
      ...summary,
      firmLevelExperiencesAndExpertises,
    });
  };

  return (
    <EditableListCard
      title={
        <span>
          <FormattedMessage id="project.summaryFields.experiencesAndExpertises.firmLevel" />
          {" - "}
          <FormattedMessage id="project.summaryFields.experiencesAndExpertises.title" />
          {summary.firmLevelExperiencesAndExpertises.length === 0 && (
            <span className="text-muted-foreground text-sm ml-1">
              (<FormattedMessage id="global.empty" />)
            </span>
          )}
        </span>
      }
      items={summary.firmLevelExperiencesAndExpertises}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default FirmRequiredExperiencesSection;
