import { PlusIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

interface SelectMenuProps {
  scope: HTMLElement;
  onSubmit: (text: string) => void;
  buttonText: string;
}

const SelectMenu = ({ scope, onSubmit, buttonText }: SelectMenuProps) => {
  const [selection, setSelection] = useState<string>();
  const [position, setPosition] = useState<Record<string, number>>();

  const onSelectStart = useCallback(() => {
    setSelection(undefined);
    setPosition(undefined);
  }, []);

  const clearSelection = useCallback(() => {
    setSelection(undefined);
    setPosition(undefined);
    document.getSelection()?.removeAllRanges();
  }, []);

  const onSelectEnd = useCallback(
    (e: MouseEvent) => {
      if (selection) {
        return;
      }

      const activeSelection = document.getSelection();
      const text = activeSelection?.toString();

      if (!activeSelection || !text) {
        setSelection(undefined);
        return;
      }

      setSelection(text);

      const containerLeft = scope.getBoundingClientRect().left;
      const containerTop = scope.getBoundingClientRect().top;
      const eventPosX = e.x;
      const eventPosY = e.y;

      setPosition({
        x: eventPosX - containerLeft - 30 / 2,
        y: eventPosY - containerTop - 30 / 2,
        width: 100,
      });
    },
    [scope, selection],
  );

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        clearSelection();
      }
    };

    scope.addEventListener("selectstart", onSelectStart);
    scope.addEventListener("mouseup", onSelectEnd);
    // scope.addEventListener("mousedown", clearSelection);
    document.addEventListener("keydown", onEscape);
    window.addEventListener("wheel", clearSelection);
    return () => {
      scope.removeEventListener("selectstart", onSelectStart);
      scope.removeEventListener("mouseup", onSelectEnd);
      document.addEventListener("keydown", onEscape);
      window.addEventListener("wheel", clearSelection);
    };
  }, [scope, onSelectEnd, onSelectStart, clearSelection]);

  const handleSubmit = () => {
    if (selection) {
      onSubmit(selection);
      clearSelection();
    }
  };

  return (
    <div role="dialog" aria-labelledby="share" aria-haspopup="dialog">
      {selection && position && (
        <div
          className="z-[1000] relative -top-2 left-0 h-[25px] bg-black text-white rounded m-0
            after:absolute after:top-full after:left-1/2 after:-translate-x-2 after:h-0 after:w-0 after:border-x-[6px] after:border-x-transparent after:border-b-[8px] after:border-b-black after:rotate-180
            hover:bg-gray-800 hover:text-gray-200"
          style={{
            transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
            display: "inline-block",
          }}
        >
          <button
            className="flex w-full h-full justify-center items-center pr-2 pl-1 pointer-events-auto"
            onClick={handleSubmit}
          >
            <PlusIcon size={16} className="mr-1" />
            <span className="text-xs">{buttonText}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectMenu;
