import { Summary } from "@/types/entities/Project";
import EditableListCard from "./EditableListCard";
import { Element } from "./EditTableView";
import { FormattedMessage } from "react-intl";

interface CertificationsSectionProps {
  summary: Summary;
  loading: boolean;
  onSubmit: (summary: Summary) => Promise<void>;
}

function CertificationsSection(props: CertificationsSectionProps) {
  const { summary, onSubmit, loading } = props;

  const handleSubmit = async (certifications: Element[]) => {
    await onSubmit({
      ...summary,
      certifications,
    });
  };

  return (
    <EditableListCard
      title={
        <span>
          <FormattedMessage id="project.summaryFields.certifications.title" />
          {summary.certifications.length === 0 && (
            <span className="text-muted-foreground text-sm ml-1">
              (<FormattedMessage id="global.empty" />)
            </span>
          )}
        </span>
      }
      items={summary.certifications}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default CertificationsSection;
