import { Summary } from "@/types/entities/Project";

interface ScopeOfWorkProps {
  summary: Summary;
}

function ScopeOfWorkView(props: ScopeOfWorkProps) {
  const { summary } = props;
  return <p className="p-4">{summary.scopeOfWork}</p>;
}

export default ScopeOfWorkView;
