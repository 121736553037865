import { Moon, PanelRightOpen, Sun } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme, useSidebar } from "@/providers";
import { FormattedMessage } from "react-intl";
import { useUser } from "@/hooks/UserHook/UserHook";

function ModeToggle() {
  const { theme, setTheme } = useTheme();
  const { isCollapsed, toggleCollapse } = useSidebar();

  const user = useUser();

  if (user) {
    return (
      <div
        className={`flex items-center gap-2 mb-6 ${
          isCollapsed
            ? "flex-col-reverse gap-5 justify-center"
            : "flex-row justify-between"
        }`}
      >
        {/* Theme Toggle */}
        <div className="flex items-center gap-2">
          {!isCollapsed && (
            <Sun className="w-5 h-5 text-gray-400 dark:text-gray-600" />
          )}
          <Switch
            checked={theme === "dark"}
            onCheckedChange={(state) => setTheme(state ? "dark" : "light")}
            id="dark-mode"
            className={`${theme !== "dark" && "!bg-gray-300"}`}
          />
          {!isCollapsed && (
            <Moon className="w-5 h-5 text-gray-400 dark:text-gray-600" />
          )}
        </div>
        {/* SideBar Toggle */}
        <PanelRightOpen
          className={`cursor-pointer w-5 h-5 text-gray-400 dark:text-gray-600 hover:text-foreground ${
            isCollapsed ? "rotate-180" : ""
          }`}
          onClick={() => toggleCollapse()}
        />
      </div>
    );
  } else {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="icon">
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setTheme("light")}>
            <FormattedMessage id="theme.light" />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme("dark")}>
            <FormattedMessage id="theme.dark" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
}

export { ModeToggle };
