import Employee from "@/types/entities/Employee";
import { AuthManager } from "../AuthManager";

class EmployeeController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async getEmployees(): Promise<Employee[]> {
    try {
      const employees = await this.authManager.request<Employee[]>(
        "GET",
        `/organizations/${this.authManager.organizationId}/employees/`,
      );
      return employees;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getEmployeeDetails(
    employeeId: string,
  ): Promise<Employee | null> {
    try {
      const employee = await this.authManager.request<Employee>(
        "GET",
        `/organizations/${this.authManager.organizationId}/employees/${employeeId}/`,
      );
      return employee;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async deleteEmployee(employeeId: string) {
    try {
      return this.authManager.request(
        "DELETE",
        `/organizations/${this.authManager.organizationId}/employees/${employeeId}/`,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async createEmployee(employeeData: Partial<Employee>) {
    try {
      const employee = await this.authManager.request<Employee>(
        "POST",
        `/organizations/${this.authManager.organizationId}/employees/`,
        employeeData,
      );
      return employee;
    } catch (error) {
      console.error(error);
    }
  }

  public async updateEmployee(
    employeeId: string,
    employeeData: Partial<Employee>,
  ) {
    try {
      const employee = await this.authManager.request<Employee>(
        "PUT",
        `/organizations/${this.authManager.organizationId}/employees/${employeeId}/`,
        employeeData,
      );
      return employee;
    } catch (error) {
      console.error(error);
    }
  }
}

export { EmployeeController };
