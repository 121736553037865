import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Textarea } from "@/components/ui/textarea";
import { EvaluationCriterion } from "@/types/entities/Project";
import { Input } from "@/components/ui/input";

interface EvaluationCriteriaTableViewProps {
  edit: boolean;
  evaluationCriteria: EvaluationCriterion[];
  loading: boolean;
  onSubmit?: (newEvaluationCriteria: EvaluationCriterion[]) => void;
  onCancel?: () => void;
}

const evaluationCriterionSchema = z.object({
  title: z.string().min(1).max(255),
  description: z.string().min(1).max(255),
  weight: z.string(),
  point: z.string().nullable(),
});

// Now add this object into an array
const evaluationCriteriaSchema = z.object({
  evaluationCriteria: z.array(evaluationCriterionSchema),
});

function EvaluationCriteriaTableView(props: EvaluationCriteriaTableViewProps) {
  const { edit, onCancel, onSubmit, evaluationCriteria, loading } = props;

  const submit = async (values: z.infer<typeof evaluationCriteriaSchema>) => {
    if (!onSubmit) return;
    onSubmit(values.evaluationCriteria);
  };

  const form = useForm<z.infer<typeof evaluationCriteriaSchema>>({
    resolver: zodResolver(evaluationCriteriaSchema),
    defaultValues: {
      evaluationCriteria: [
        ...evaluationCriteria.map((e) => ({ ...e, weight: e.weight ?? "" })),
      ],
    },
  });

  const array = useFieldArray({
    control: form.control,
    name: "evaluationCriteria",
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex flex-col gap-6"
      >
        <Table>
          <TableBody>
            {array.fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormItem>
                    {edit ? (
                      <FormField
                        control={form.control}
                        name={`evaluationCriteria.${index}.title`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ) : (
                      <b>{field.title}</b>
                    )}
                  </FormItem>
                </TableCell>
                <TableCell>
                  {edit ? (
                    <FormItem>
                      <FormField
                        control={form.control}
                        name={`evaluationCriteria.${index}.description`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </FormItem>
                  ) : (
                    <div>{field.description}</div>
                  )}
                </TableCell>
                <TableCell>
                  {edit ? (
                    <FormItem>
                      <FormField
                        control={form.control}
                        name={`evaluationCriteria.${index}.weight`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </FormItem>
                  ) : (
                    <div>{field.weight}</div>
                  )}
                </TableCell>
                {edit && (
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={loading}
                      onClick={() => array.remove(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="flex w-full justify-center gap-2">
            <Button
              type="button"
              variant={"outline"}
              disabled={loading}
              onClick={() =>
                array.append({
                  title: "",
                  description: "",
                  weight: "",
                  point: null,
                })
              }
            >
              <PlusIcon />
              <FormattedMessage id="global.add" />
            </Button>
          </div>
        )}
        {edit && (
          <div className="flex gap-2 mr-4 justify-end">
            <Button onClick={onCancel} variant="outline" disabled={loading}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button type="submit" disabled={loading}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default EvaluationCriteriaTableView;
