import React from "react";
import { MainNav } from "@/modules/MainNav/MainNav";
import { TopNav } from "@/modules/MainNav/TopNav";
import { useUser } from "@/hooks/UserHook/UserHook";
import { useSidebar } from "@/providers";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isCollapsed } = useSidebar();
  const user = useUser();

  if (user) {
    return (
      <div className="flex h-screen w-screen !overflow-hidden bg-background">
        <div
          className={`transition-all duration-200 ${isCollapsed ? "w-[80px]" : "w-[220px]"}`}
        >
          <MainNav />
        </div>
        <div className="main-content relative flex-1 overflow-auto">
          <TopNav />
          <div className="h-screen flex-1 pb-4">{children}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-400/30 via-indigo-500/30 to-purple-600/30 backdrop-blur-sm dark:from-blue-900/30 dark:via-indigo-900/30 dark:to-purple-900/30">
        <MainNav />
        <div className="flex-grow flex items-center justify-center">
          {children}
        </div>
      </div>
    );
  }
};

export default Layout;
