import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage, useIntl } from "react-intl";
import OutlineTable from "./components/OutlineTable";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import { OutlineSection } from "@/types/entities/Outline";
import { SeevClient } from "@/lib/SeevClient";
import { Project } from "@/types/entities/Project";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { featureFlags } from "@/config/featureFlags";
import { SearchInput } from "@/components/SearchInput/SearchInput";

export interface OutlineCardProps {
  project: Project;
  setProject: (project: Project) => void;
  onSectionClick: (section: OutlineSection) => void;
}

function OutlineCard({
  project,
  setProject,
  onSectionClick,
}: OutlineCardProps) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sectionList, setSectionList] = useState<OutlineSection[]>(
    project?.outline?.sections || [],
  );
  const intl = useIntl();

  const updateSections = async (updatedSections: OutlineSection[]) => {
    try {
      setSectionList(updatedSections);
      await SeevClient.project.updateProject(project.id, {
        outline: { sections: updatedSections },
      });
      setProject({
        ...project,
        outline: {
          ...project.outline,
          sections: updatedSections,
        },
      });
    } catch (error) {
      console.error("Failed to update sections", error);
    }
  };

  const handleUpdate = (updatedSection: OutlineSection) => {
    updateSections(
      sectionList.map((sec) =>
        sec.id === updatedSection.id ? updatedSection : sec,
      ),
    );
  };

  const handleDelete = (sectionId: string) => {
    updateSections(sectionList.filter((sec) => sec.id !== sectionId));
  };

  return (
    <Card className="h-[650px]">
      <CardHeader>
        <CardTitle style={{ paddingRight: "10px" }}>
          <FormattedMessage id="project.outline.title" />
        </CardTitle>
        <div className="flex items-center gap-3">
          <SearchInput
            placeholder={intl.formatMessage({
              id: "project.outline.searchPlaceholder",
            })}
            onChange={(value) => {
              setGlobalFilter(value);
            }}
          />
          {featureFlags().enableOutlineV2 ? (
            <Button variant="outline" size="sm" className="w-max">
              <Plus className="mr-2 h-4 w-4" />
              <FormattedMessage id="project.addSection" />
            </Button>
          ) : null}
        </div>
      </CardHeader>
      <div>
        <ScrollArea className="h-[550px]">
          <OutlineTable
            outline={{ sections: sectionList }}
            globalFilter={globalFilter}
            onRowClick={onSectionClick}
            onChange={handleUpdate}
            onDelete={handleDelete}
          />
        </ScrollArea>
      </div>
    </Card>
  );
}

export default OutlineCard;
