import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { EventSourceInput } from "@fullcalendar/core/index.js";
import { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PortfolioProject } from "@/types/entities/Project";

export interface CalendarProps {
  projects: PortfolioProject[];
}

const projectProgressesToExclude = ["NO_GO", "CANCELED", "LOST"];

function Calendar({ projects }: CalendarProps) {
  const navigate = useNavigate();
  const [newEvents, setEvents] = useState<EventSourceInput>([]);
  const intl = useIntl();

  const fetchProject = useCallback(async () => {
    const newEvents: EventSourceInput = [];
    projects.forEach((project) => {
      if (
        project.summary?.rfpApplicationDeadline !== null &&
        !projectProgressesToExclude.includes(project.progress)
      ) {
        newEvents.push({
          id: project.id,
          title: intl.formatMessage(
            { id: "portfolio.calendar.dueDate" },
            { title: project.title },
          ),
          start: project.summary?.rfpApplicationDeadline,
          color: "red-burgundy",
        });
      }

      if (
        project.summary?.qnaDeadline !== null &&
        !projectProgressesToExclude.includes(project.progress)
      ) {
        newEvents.push({
          id: project.id,
          title: intl.formatMessage(
            { id: "portfolio.calendar.qnaDate" },
            { title: project.title },
          ),
          date: project.summary?.qnaDeadline,
          color: "darkblue",
        });
      }
    });
    setEvents(newEvents);
  }, [projects, intl]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      events={newEvents}
      height={650}
      locale={intl.locale}
      eventClick={(info) => navigate(`/project/${info.event.id}`)}
      buttonText={{
        today: intl.formatMessage({ id: "portfolio.calendar.today" }),
      }}
    />
  );
}

export default Calendar;
