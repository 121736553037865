import QualificationsCard from "../Cards/Qualifications/Qualifications";
import { Project } from "@/types/entities/Project";
import { useState } from "react";
import PDFExplorer from "../../../modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
interface CaptureProps {
  project: Project;
  setProject: (project: Project) => void;
}

function Capture({ project, setProject }: CaptureProps) {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [file, setFile] = useState<string | null>(null);
  const [cachedFiles, setCachedFiles] = useState<Record<string, string>>({});

  const showEmployeeCV = async (
    cv: string,
    searchText: string,
    pageNumber: number,
  ) => {
    try {
      let fileURL: string;
      if (cachedFiles[cv]) {
        fileURL = cachedFiles[cv];
      } else {
        const file = await SeevClient.file.downloadFile(cv);
        if (!file) return;
        fileURL = URL.createObjectURL(file);
        setCachedFiles((prev) => ({ ...prev, [cv]: fileURL }));
      }
      setFile(fileURL);
      setSearchText(searchText);
      setPageNumber(Number(pageNumber));
    } catch (error) {
      console.error("Failed to get file", error);
    }
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      <div className="flex w-full xl:w-2/3 h-full">
        <QualificationsCard
          project={project}
          showEmployeeCV={showEmployeeCV}
          setProject={setProject}
        />
      </div>
      <div className="flex-1 w-full xl:w-1/8 h-full">
        <PDFExplorer
          fileUrl={file}
          pageNumber={pageNumber}
          searchText={searchText}
          inDialog={true}
        />
      </div>
    </div>
  );
}

export default Capture;
