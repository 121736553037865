import { Summary } from "@/types/entities/Project";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import { Textarea } from "@/components/ui/textarea";

interface SummaryFormProps {
  loading: boolean;
  summary: Summary;
  onSubmit: (summary: Summary) => Promise<void>;
  onCancel: () => void;
}

const formSchema = z.object({
  summary: z.string().min(1).max(3000),
});

function SummaryForm(props: SummaryFormProps) {
  const { loading } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      summary: props.summary.rfpSummary,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await props.onSubmit({
      ...props.summary,
      rfpSummary: values.summary,
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6"
      >
        <div className="flex mt-4 mb-4 gap-6 flex-col">
          <FormField
            control={form.control}
            name="summary"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2 mr-4 justify-end">
          <Button onClick={props.onCancel} variant="outline" disabled={loading}>
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button type="submit" disabled={loading}>
            <FormattedMessage id="global.save" />
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default SummaryForm;
