import { useEffect } from "react";
import { User } from "@/types/User";
import * as Sentry from "@sentry/react";
import { SeevClient } from "@/lib/SeevClient";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { featureFlags } from "@/config/featureFlags";

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const feedbackIntegration = Sentry.feedbackIntegration({
  id: "seev-sentry-feedback",
  colorScheme: "system",
  showBranding: false,
  triggerLabel: "Report an issue",
  formTitle: "Report an issue",
  formDescription: "Please describe the issue you are experiencing.",
  formSubmitLabel: "Submit",
  formCancelLabel: "Cancel",
  submitButtonLabel: "Submit Issue",
  triggerAriaLabel: "Report an issue",
  onsubmit: () => {
    // Add custom tags when feedback is submitted
    Sentry.setTag("page_name", document.title);
    const user = SeevClient.authManager.getUser();
    if (user) {
      Sentry.setTag("user_id", user.id);
    }
    Sentry.setTag("org_id", SeevClient.authManager.getOrganizationId());
    Sentry.setTag("portfolio_id", SeevClient.authManager.getPortfolioId());
  },
});

export function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      ...(featureFlags().enableSentryFeedback ? [feedbackIntegration] : []),
    ],
    tracesSampleRate: 0.01,
    tracePropagationTargets: [
      "localhost",
      /^\//,
      /^https:\/\/app\.seev\.ai\/v1\/api/,
    ],
    profilesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
    environment: ENVIRONMENT,
  });
}

export function setSentryUser(user: User) {
  Sentry.setUser({
    fullName: `${user.firstName} ${user.lastName}`,
    email: user.email,
  });
}

export function clearSentryUser() {
  Sentry.setUser(null);
}
