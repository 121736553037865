import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { BiddingFunnelManagersRate } from "@/types/entities/Analytics";
import { ChartConfiguration } from "chart.js";

interface Props {
  data: BiddingFunnelManagersRate[] | undefined;
  chartColors: { gridColor: string; textColor: string };
}

function BiddingFunnelManagerRatesChart({ data, chartColors }: Props) {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current && data && data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const config: ChartConfiguration<"bar", number[]> = {
          type: "bar",
          data: {
            labels: data.map((item) => item.manager || "Unnamed"),
            datasets: Object.keys(data[0])
              .filter(
                (key) =>
                  key !== "manager" &&
                  data.some(
                    (item) =>
                      Number(item[key as keyof BiddingFunnelManagersRate]) > 0,
                  ),
              )
              .map((field) => ({
                label: field.charAt(0).toUpperCase() + field.slice(1),
                data: data.map(
                  (item) =>
                    item[field as keyof BiddingFunnelManagersRate] as number,
                ),
                backgroundColor: getColorForField(field),
              })),
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  font: {
                    size: 16,
                  },
                  boxWidth: 15,
                  padding: 8,
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                title: {
                  display: true,
                  text: "Account Manager",
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                  color: chartColors.textColor,
                },
                grid: {
                  color: chartColors.gridColor,
                  offset: true,
                },
                ticks: {
                  color: chartColors.textColor,
                  font: {
                    size: 12,
                  },
                  align: "center",
                },
              },
              y: {
                stacked: true,
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Count",
                  font: {
                    size: 16,
                    weight: "bold",
                  },
                  color: chartColors.textColor,
                },
                grid: {
                  color: chartColors.gridColor,
                  drawTicks: false,
                },
                ticks: {
                  color: chartColors.textColor,
                  stepSize: 1,
                  precision: 0,
                  font: {
                    size: 16,
                  },
                  padding: 8,
                },
              },
            },
          },
        };

        chartInstance.current = new Chart(ctx, config);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, chartColors]);

  function getColorForField(field: string): string {
    const colorMap: { [key: string]: string } = {
      goNoGo: "#645ae6",
      noGo: "#d6ecf6",
      proposal: "#9f8dee",
      canceled: "#cec2f4",
      submitted: "#87d2f0",
      won: "#7a6ee6",
      execution: "#b19ee6",
      completed: "#a3c2f4",
      lost: "#a6d2f0",
    };
    return colorMap[field] || "#cccccc"; // default to neutral gray if no color is defined
  }

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-xl flex items-center justify-center h-full">
          No data available
        </div>
      </div>
    );
  }

  return <canvas ref={chartRef} className="w-full h-full" />;
}

export default BiddingFunnelManagerRatesChart;
