import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { OpportunitiesTable } from "./Components/table";
import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { OpportunitiesProject } from "@/types/entities/Opportunities";
import {
  OpportunityProgress,
  OpportunityStatus,
  OpportunityDate,
} from "@/types/entities/Opportunities";
import { SeevClient } from "@/lib/SeevClient/SeevClient";

function Opportunities() {
  const [opportunities, setOpportunities] = useState<OpportunitiesProject[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    progress: OpportunityProgress;
    date: OpportunityDate;
  }>({
    progress: "ALL",
    date: "TODAY",
  });
  const [filteredOpportunities, setFilteredOpportunities] = useState<
    OpportunitiesProject[]
  >([]);

  const fetchOpportunities = useCallback(async () => {
    setIsLoading(true);
    try {
      const opportunities = await SeevClient.opportunities.getOpportunities();
      if (opportunities.length > 0) {
        setOpportunities(opportunities);
        setFilteredOpportunities(
          filterOpportunities(opportunities, filters.progress, filters.date),
        );
      }
    } finally {
      setIsLoading(false);
    }
  }, [filters]);

  //TODO:filter on backend side : check if progress/date define in opportunities filter
  const filterOpportunities = (
    opportunities: OpportunitiesProject[],
    progress: OpportunityProgress | "ALL",
    date: OpportunityDate | "TODAY",
  ) => {
    if (progress === "ALL" && date === "ALL") {
      return opportunities;
    }

    const today = new Date();
    const startDate = new Date(today);

    if (date === "LAST_WEEK") {
      startDate.setDate(today.getDate() - 7);
    } else if (date === "LAST_MONTH") {
      startDate.setMonth(today.getMonth() - 1);
    }

    // TODO:add progress filter when clarified
    return opportunities.filter((project) => {
      const projectDate = new Date(project.createdAt);
      if (date === "TODAY") {
        return projectDate.toDateString() === today.toDateString();
      } else {
        return projectDate >= startDate && projectDate <= today;
      }
    });
  };

  const filterOpportunitiesOnFilters = useCallback(
    (
      progress: OpportunityProgress | "ALL",
      date: OpportunityDate | "TODAY",
    ) => {
      setFilters({
        progress,
        date,
      });
      setFilteredOpportunities(
        filterOpportunities(opportunities, progress, date),
      );
    },
    [opportunities],
  );

  //TODO:filter on backend side : check if status define in opportunities api filter
  const fetchOpportunitiesOnStatus = (status: OpportunityStatus) => {
    console.info(status); //TODO:just to prevent lint error | remove after status checked
  };

  useEffect(() => {
    fetchOpportunities();
  }, [fetchOpportunities]);

  return (
    <div className="flex w-full flex-col gap-4 p-6 pt-4">
      <Tabs defaultValue="ACTIVE">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-3xl font-bold tracking-tight">
            <FormattedMessage id="opportunities.title" />
          </h2>
          <TabsList>
            <TabsTrigger
              value="ACTIVE"
              onClick={() => {
                fetchOpportunitiesOnStatus("ACTIVE");
              }}
            >
              <FormattedMessage id="opportunities.tabs.active" />
            </TabsTrigger>
            <TabsTrigger
              value="ARCHIVE"
              onClick={() => {
                fetchOpportunitiesOnStatus("ARCHIVE");
              }}
            >
              <FormattedMessage id="opportunities.tabs.archive" />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="ACTIVE">
          <OpportunitiesTable
            projects={filteredOpportunities}
            isLoading={isLoading}
            filterOnDate={(date) => {
              filterOpportunitiesOnFilters(filters.progress, date);
            }}
            filterOnProgress={(progress) => {
              filterOpportunitiesOnFilters(progress, filters.date);
            }}
          />
        </TabsContent>
        <TabsContent value="ARCHIVE">
          <OpportunitiesTable
            projects={filteredOpportunities}
            isLoading={isLoading}
            filterOnDate={(date) => {
              filterOpportunitiesOnFilters(filters.progress, date);
            }}
            filterOnProgress={(progress) => {
              filterOpportunitiesOnFilters(progress, filters.date);
            }}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Opportunities;
