import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import PageNotFound from "@/pages/404";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import Portfolio from "@/pages/Portfolio";
import Opportunities from "@/pages/Opportunities";
import Project from "@/pages/Project";
import Login from "@/pages/Login";
import { ReactElement } from "react";
import { useUser } from "@/hooks/UserHook/UserHook";
import Analytics from "@/pages/Analytics";
import ForgotPassword from "@/pages/ForgotPassword";
import ResetPassword from "@/pages/ResetPassword";
import Knowledge from "@/pages/Knowledge";
import SignUp from "@/pages/SignUp";
import { featureFlags } from "@/config/featureFlags";

interface PrivateRouteProps {
  element: ReactElement;
  loggedIn: boolean;
}

function PrivateRoute({ element, loggedIn }: PrivateRouteProps) {
  const location = useLocation();
  return loggedIn ? (
    element
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

function AppRoutes() {
  const user = useUser();
  const location = useLocation();

  useEffect(() => {
    (document.querySelector(".main-content") || window)?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={<PrivateRoute loggedIn={!!user} element={<Portfolio />} />}
      />
      <Route
        path="/analytics"
        element={<PrivateRoute loggedIn={!!user} element={<Analytics />} />}
      />
      <Route
        path="/portfolio"
        element={<PrivateRoute loggedIn={!!user} element={<Portfolio />} />}
      />
      <Route
        path="/opportunities"
        element={<PrivateRoute loggedIn={!!user} element={<Opportunities />} />}
      />
      <Route
        path="/knowledge"
        element={<PrivateRoute loggedIn={!!user} element={<Knowledge />} />}
      />
      <Route
        path="/project/:projectId"
        element={<PrivateRoute loggedIn={!!user} element={<Project />} />}
      />
      <Route
        path="/forgot-password"
        element={user ? <Navigate to="/" /> : <ForgotPassword />}
      />
      <Route
        path="/auth/reset-confirm"
        element={user ? <Navigate to="/" /> : <ResetPassword />}
      />
      <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
      <Route
        path="/signup"
        element={
          user ? (
            <Navigate to="/" />
          ) : featureFlags().enableSignup ? (
            <SignUp />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
