import { Summary } from "@/types/entities/Project";

interface ProjectStructureProps {
  summary: Summary;
}

function ProjectStructureView(props: ProjectStructureProps) {
  const { summary } = props;
  return <p className="p-4">{summary.projectStructure}</p>;
}

export default ProjectStructureView;
