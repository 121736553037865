import PDFExplorer from "@/modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";
import RequirementCard from "../../Cards/Requirement/Requirement";
import { useState } from "react";
import { Project } from "@/types/entities/Project";
import { Requirement } from "@/types/entities/Requirement";

interface RequirementsProps {
  project: Project;
  file: string | null;
  onRequirementsUpdate: (requirements: Requirement[]) => void;
}

function Requirements({
  project,
  file,
  onRequirementsUpdate,
}: RequirementsProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const handleRequirementClick = (requirement: Requirement) => {
    setPageNumber(Number(requirement.pageNumber));
    setSearchText(requirement.exactCopyFromTheSection);
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      <div className="flex h-full">
        <RequirementCard
          project={project}
          onRequirementsUpdate={onRequirementsUpdate}
          onRequirementClick={handleRequirementClick}
        />
      </div>
      <div className="flex h-full">
        <PDFExplorer
          fileUrl={file}
          pageNumber={pageNumber}
          searchText={searchText}
        />
      </div>
    </div>
  );
}

export default Requirements;
