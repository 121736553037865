import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent } from "@radix-ui/react-tabs";
import { TabsList, TabsTrigger } from "@/components/ui/tabs";
import TodoList from "./TodoList";
import Notes from "./Notes";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import { Project, Note, Todo } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";

interface TrackingCardProps {
  project: Project;
}

function TrackingCard({ project }: TrackingCardProps) {
  const intl = useIntl();

  const [todoList, setTodoList] = useState<Todo[]>(project.todos || []);

  const [noteList, setNoteList] = useState<Note[]>(project.notes || []);

  const [updating, setUpdating] = useState(false);

  const updateTodos = async (todos: Todo[]) => {
    setUpdating(true);
    try {
      setTodoList(todos);
      await SeevClient.project.updateProject(project.id, {
        todos,
      });
    } catch (error) {
      console.error("Failed to update todos", error);
    }
    setUpdating(false);
  };

  const updateNotes = async (notes: Note[]) => {
    setUpdating(true);
    try {
      setNoteList(notes);
      await SeevClient.project.updateProject(project.id, {
        notes,
      });
    } catch (error) {
      console.error("Failed to update notes", error);
    }
    setUpdating(false);
  };

  const addTodo = async () => {
    await updateTodos([
      {
        title: intl.formatMessage({ id: "project.tabs.todo" }),
        completed: false,
      },
      ...todoList,
    ]);
  };

  const addNote = async () => {
    await updateNotes([
      {
        property: intl.formatMessage({ id: "project.fields.property" }),
        value: intl.formatMessage({ id: "project.fields.value" }),
      },
      ...noteList,
    ]);
  };

  const handleAdd = async () => {
    setUpdating(true);
    if (selectedTab === "todo") {
      await addTodo();
    } else {
      await addNote();
    }
    setUpdating(false);
  };

  const [selectedTab, setSelectedTab] = useState<"notes" | "todo">("notes");

  return (
    <Card className="h-[317px]">
      <Tabs
        defaultValue="notes"
        onValueChange={(tab) => {
          const newTab = tab as "notes" | "todo";
          setSelectedTab(newTab);
        }}
      >
        <CardHeader>
          <CardTitle>
            <FormattedMessage id="project.tabs.tracking" />
          </CardTitle>
          <div className="flex justify-center items-center">
            <Button
              variant="outline"
              size={"sm"}
              className="mr-4 h-8"
              onClick={handleAdd}
              disabled={updating}
            >
              <PlusIcon className="w-4 h-4 mr-1" />
              <FormattedMessage id="global.add" />
            </Button>
            <TabsList>
              <TabsTrigger value="notes">
                <FormattedMessage id="project.tabs.notes" />
              </TabsTrigger>
              <TabsTrigger value="todo">
                <FormattedMessage id="project.tabs.todo" />
              </TabsTrigger>
            </TabsList>
          </div>
        </CardHeader>
        <ScrollArea className="h-[220px]">
          <TabsContent value="todo">
            <CardContent>
              <TodoList
                todoList={todoList}
                onChange={(todo, index) => {
                  updateTodos(todoList.map((t, i) => (i === index ? todo : t)));
                }}
                onDelete={async (index) => {
                  updateTodos(todoList.filter((_, i) => i !== index));
                }}
                updateDisabled={updating}
              />
            </CardContent>
          </TabsContent>
          <TabsContent value="notes">
            <CardContent>
              <Notes
                noteList={noteList}
                onChange={(note, index) => {
                  updateNotes(noteList.map((t, i) => (i === index ? note : t)));
                }}
                onDelete={(index) => {
                  updateNotes(noteList.filter((_, i) => i !== index));
                }}
                updateDisabled={updating}
              />
            </CardContent>
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </Card>
  );
}

export default TrackingCard;
