import { useEffect, useRef, useMemo } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { subMonths, subWeeks, subDays, format, parseISO } from "date-fns";
import { ParsedRfpsRate } from "@/types/entities/Analytics";
import { ChartConfiguration } from "chart.js";

interface Props {
  data: ParsedRfpsRate[];
  groupBy: "month" | "week" | "day";
  chartColors: { gridColor: string; textColor: string };
}

function ParsedRfpsChart({ data, groupBy, chartColors }: Props) {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const filteredData = useMemo(() => {
    const currentDate = new Date();
    let startDate: Date;

    switch (groupBy) {
      case "month":
        startDate = subMonths(currentDate, 6);
        break;
      case "week":
        startDate = subWeeks(currentDate, 12);
        break;
      case "day":
        startDate = subDays(currentDate, 30);
        break;
    }

    return data.filter((item) => parseISO(item.date) >= startDate);
  }, [data, groupBy]);

  useEffect(() => {
    const updateChart = () => {
      if (chartRef.current && filteredData.length > 0) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        if (ctx) {
          const endDate = new Date();
          const startDate = subMonths(
            endDate,
            groupBy === "month" ? 6 : groupBy === "week" ? 3 : 1,
          );

          const config: ChartConfiguration<"line", { x: number; y: number }[]> =
            {
              type: "line",
              data: {
                labels: filteredData.map((item) => parseISO(item.date)),
                datasets: [
                  {
                    label: "Analyzed RFPs",
                    data: filteredData.map((item) => ({
                      x: parseISO(item.date).getTime(),
                      y: item.count,
                    })),
                    fill: false,
                    backgroundColor: "#645ae6",
                    borderColor: "#645ae6",
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    bottom: 4,
                  },
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: groupBy,
                      displayFormats: {
                        day: "MMM d",
                        week: "MMM d",
                        month: "MMM yyyy",
                      },
                    },
                    min: startDate.getTime(),
                    max: endDate.getTime(),
                    title: {
                      display: true,
                      text: "Submitted Date",
                      font: {
                        size: 16,
                        weight: "bold",
                      },
                      color: chartColors.textColor,
                    },
                    ticks: {
                      source: "auto",
                      autoSkip: false,
                      maxRotation: 0,
                      color: chartColors.textColor,
                      font: {
                        size: 14,
                      },
                      align: "center",
                    },
                    grid: {
                      color: chartColors.gridColor,
                      offset: true,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Count",
                      font: {
                        size: 16,
                        weight: "bold",
                      },
                      color: chartColors.textColor,
                    },
                    ticks: {
                      color: chartColors.textColor,
                      stepSize: 1,
                      precision: 0,
                      font: {
                        size: 16,
                      },
                      padding: 8,
                    },
                    grid: {
                      color: chartColors.gridColor,
                      drawTicks: false,
                    },
                  },
                },
                plugins: {
                  legend: {
                    position: "bottom",
                    labels: {
                      font: {
                        size: 16,
                      },
                      boxWidth: 15,
                      padding: 8,
                    },
                  },
                  tooltip: {
                    callbacks: {
                      title: (context) => {
                        return format(context[0].parsed.x, "PPP");
                      },
                    },
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    titleColor: "#ffffff",
                    bodyColor: "#ffffff",
                    borderColor: "rgba(255, 255, 255, 0.2)",
                    borderWidth: 1,
                  },
                },
              },
            };

          chartInstance.current = new Chart(ctx, config);
        }
      }
    };

    updateChart();

    // Add event listener for theme changes
    window.addEventListener("themechange", updateChart);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      window.removeEventListener("themechange", updateChart);
    };
  }, [filteredData, groupBy, chartColors]);

  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-xl flex items-center justify-center h-full">
          No data available
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <canvas ref={chartRef} />
    </div>
  );
}

export default ParsedRfpsChart;
