import { SearchInput } from "@/components/SearchInput/SearchInput";
import { useIntl } from "react-intl";
// import AddFileDialog from "./AddfEmployeeDialog";

interface ToolBarProps {
  setGlobalFilter: (value: string) => void;
  onCreateFile: () => void;
}

function ToolBar({ setGlobalFilter }: ToolBarProps) {
  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({
    id: "employee.searchPlaceholder",
  });
  return (
    <div className="flex items-center py-4 justify-between">
      <div className="flex items-center space-x-4">
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={setGlobalFilter}
        />
      </div>
      {/* <AddFileDialog onCreate={onCreateFile} /> */}
    </div>
  );
}

export default ToolBar;
