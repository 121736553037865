import { FormattedMessage } from "react-intl";
import { useEffect, useRef, useState } from "react";
import FileTable from "@/modules/FilesTable/Table";
import EmployeeTable from "@/modules/EmployeeTable/Table";
import FileEntity, { SemanticSearchFileEntity } from "@/types/entities/File";
import Employee from "@/types/entities/Employee";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import React, { useCallback } from "react";
import PDFExplorer from "../../modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import { Button } from "@/components/ui/button";
import { ChevronLeft, FileText } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { DateTime } from "luxon";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Loading } from "@/components/Loading/Loading";
import { RelevantDocument } from "@/types/entities/RelevantDocument";
import { useLocation } from "react-router-dom";

function Knowledge() {
  //// Files ////
  const location = useLocation();
  const [files, setFiles] = useState<FileEntity[]>([]);
  const [filteredFiles, setFilteredFiles] = React.useState<
    SemanticSearchFileEntity[] | null
  >(null);
  const [areFilesLoading, setAreFilesLoading] = useState<boolean>(false);

  const fetchFiles = useCallback(
    async (silent: boolean = false, text: string = "") => {
      if (!silent) {
        setAreFilesLoading(true);
      }

      const filesResponse = await SeevClient.file.getFiles();
      setFiles(filesResponse);

      if (!text) {
        setFilteredFiles(null);
      } else {
        const documents: RelevantDocument[] =
          await SeevClient.file.semanticSearchFiles(text);
        // Combine the relevant documents with files
        const filteredFilesWithScores: (SemanticSearchFileEntity | null)[] =
          filesResponse
            .map((file) => {
              const relevantDoc = documents.find(
                (doc) => doc.documentId === file.id,
              );
              return relevantDoc
                ? {
                    ...file,
                    score: relevantDoc.score,
                    text: relevantDoc.text,
                    pageNumber: relevantDoc.metadata.pageNumber,
                  }
                : null;
            })
            .filter((entry) => entry !== null);
        setFilteredFiles(filteredFilesWithScores as SemanticSearchFileEntity[]);
      }

      if (!silent) {
        setAreFilesLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    if (query) {
      fetchFiles(false, query);
    } else {
      fetchFiles();
    }
    // 30 seconds pooling
    const interval = setInterval(async () => {
      await fetchFiles(true);
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchFiles, location.search]);

  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedFile, setSelectedFile] = useState<
    FileEntity | SemanticSearchFileEntity | null
  >(null);

  const handleFileDelete = async (file: FileEntity) => {
    setAreFilesLoading(true);
    await SeevClient.file.deleteFile(file);
    await fetchFiles();
    setAreFilesLoading(false);
  };

  const handleFileCreate = async () => {
    setAreFilesLoading(true);
    await fetchFiles();
    setAreFilesLoading(false);
  };
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<FileEntity | null>(null);

  const handleFilePreview = async (
    file: FileEntity | SemanticSearchFileEntity,
  ) => {
    const response = await SeevClient.file.downloadFile(file.id);
    if (!response) return;
    const fileURL = URL.createObjectURL(response);
    setPreviewFile({
      ...file,
      url: fileURL,
    });

    setIsFilePreviewOpen(true);
    if ("text" in file && "pageNumber" in file) {
      setSearchText(file.text);
      setPageNumber(file.pageNumber);
    }
  };

  //// Employees ////
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [areEmployeesLoading, setAreEmployeesLoading] =
    useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [employeeCVIsLoading, setEmployeeCVIsLoading] =
    useState<boolean>(false);

  const [selectedEmployeeFile, setSelectedEmployeeFile] =
    useState<FileEntity | null>(null);

  const fetchEmployees = async () => {
    setAreEmployeesLoading(true);

    const employeesResponse = await SeevClient.employee.getEmployees();

    setEmployees(employeesResponse);
    setAreEmployeesLoading(false);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleEmployeeSelect = async (employee: Employee) => {
    setEmployeeCVIsLoading(true);
    setSelectedEmployee(employee);
    setOpenDrawer(true);
    const file = await SeevClient.file.getFile(employee.cv);
    const fileContent = await SeevClient.file.downloadFile(employee.cv);
    setEmployeeCVIsLoading(false);
    if (!fileContent || !file) return;
    const fileURL = URL.createObjectURL(fileContent);
    setSelectedEmployeeFile({
      ...file,
      url: fileURL,
    });
  };

  const handleEmployeeDelete = async (employee: Employee) => {
    setAreEmployeesLoading(true);
    await SeevClient.employee.deleteEmployee(employee.id);
    await fetchEmployees();
    setAreEmployeesLoading(false);
  };

  const handleEmployeeCreate = async () => {
    setAreEmployeesLoading(true);
    await fetchEmployees();
    setAreEmployeesLoading(false);
  };

  const [selectedTab, setSelectedTab] = useState("files");
  const [openDrawer, setOpenDrawer] = useState(false);

  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState("100vh"); // Initial height

  useEffect(() => {
    // Function to update the height state
    const updateTableHeight = () => {
      if (tableRef.current) {
        setTableHeight(`${(tableRef.current as HTMLElement).clientHeight}px`);
      }
    };
    // Initialize height on mount
    updateTableHeight();
    // Create a ResizeObserver to watch for changes in the table's size
    const resizeObserver = new ResizeObserver(() => {
      updateTableHeight();
    });
    if (tableRef.current) {
      resizeObserver.observe(tableRef.current);
    }
    // Cleanup observer on component unmount
    return () => {
      if (tableRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(tableRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef.current]); // Run effect when tableRef changes
  return (
    <div
      className={`relative flex w-full flex-col gap-4 p-6 ${
        selectedTab === "employees" && "pr-14"
      }`}
    >
      <Tabs
        defaultValue="files"
        value={selectedTab}
        onValueChange={setSelectedTab}
      >
        <div className="flex justify-between space-y-2">
          <h2 className="text-3xl font-bold tracking-tight">
            <FormattedMessage id="knowledge.title" />
          </h2>
          <TabsList>
            <TabsTrigger value="files">
              <FormattedMessage id="knowledge.tabs.files" />
            </TabsTrigger>
            <TabsTrigger value="employees">
              <FormattedMessage id="knowledge.tabs.employees" />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="files">
          <div className="flex gap-4 w-full">
            <div className="flex h-full w-full">
              <FileTable
                files={files}
                filteredFiles={filteredFiles}
                isLoading={areFilesLoading}
                selected={selectedFile}
                semanticSearch={(text: string) => {
                  fetchFiles(false, text);
                }}
                onCreate={async () => {
                  await handleFileCreate();
                }}
                onSelect={setSelectedFile}
                onDelete={async (file) => {
                  await handleFileDelete(file);
                }}
                onPreview={handleFilePreview}
              />
            </div>
          </div>
          <Dialog open={isFilePreviewOpen} onOpenChange={setIsFilePreviewOpen}>
            <DialogContent className="!min-w-[700px]">
              <PDFExplorer
                fileUrl={previewFile?.url}
                extension={previewFile?.extension}
                searchText={searchText}
                pageNumber={pageNumber}
                noBorder={true}
                inDialog={true}
              />
            </DialogContent>
          </Dialog>
        </TabsContent>
        <TabsContent value="employees">
          <div className="flex gap-4 w-full">
            <div ref={tableRef} className="flex h-full w-full">
              <EmployeeTable
                employees={employees}
                isLoading={areEmployeesLoading}
                selected={selectedEmployee}
                onCreate={async () => {
                  await handleEmployeeCreate();
                }}
                onDelete={async (employee) => {
                  await handleEmployeeDelete(employee);
                }}
                onSelect={async (employee) => {
                  await handleEmployeeSelect(employee);
                }}
              ></EmployeeTable>
            </div>
            {/* Drawer */}
            <div
              className="absolute right-0 top-8"
              style={{ height: tableHeight }}
            >
              <div className="!sticky right-0 !top-24">
                <div
                  className={`transition-all duration-300 relative w-[30px] h-[750px]
                  rounded-l-[40px] bg-[#f3f4f6] dark:!bg-muted
                  ${openDrawer && "shadow-lg !w-[400px]"}`}
                >
                  <Button
                    variant="default"
                    size="icon"
                    className={`!h-9 !w-9 transition-all duration-300 absolute top-5 -left-4 !rounded-full ${
                      openDrawer && "rotate-180"
                    }`}
                    onClick={() => {
                      setOpenDrawer(!openDrawer);
                    }}
                  >
                    <ChevronLeft />
                  </Button>
                  {openDrawer && (
                    <div className="h-full overflow-y-auto">
                      {selectedEmployee ? (
                        <div className="text-gray-700 dark:text-white flex flex-col p-10 pr-8">
                          <div className="flex items-center mb-4">
                            <Avatar className="h-20 w-20 mr-4">
                              <AvatarImage />
                              <AvatarFallback className="!bg-white dark:!bg-muted-foreground">
                                <span className="text-2xl">
                                  {selectedEmployee?.firstName?.length
                                    ? `${selectedEmployee?.firstName.charAt(
                                        0,
                                      )}${selectedEmployee?.lastName.charAt(0)}`
                                    : ""}
                                </span>
                              </AvatarFallback>
                            </Avatar>
                            <div>
                              <h2 className="text-base font-medium line-clamp-2">
                                {selectedEmployee?.firstName +
                                  " " +
                                  selectedEmployee?.lastName}
                              </h2>
                              <p className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                {selectedEmployee?.currentTitle}
                              </p>
                              <p className="text-sm font-medium text-gray-400">
                                {DateTime.fromISO(
                                  `${selectedEmployeeFile?.updatedAt}`,
                                ).toFormat("MMMM d, yyyy")}
                              </p>
                            </div>
                          </div>
                          <div className="py-2 pl-2">
                            <div className="flex justify-between items-end mb-4">
                              <h3 className="text-xl font-semibold">
                                <FormattedMessage id="knowledge.employee.profile" />
                              </h3>
                              <Dialog>
                                <DialogTrigger asChild>
                                  <Button variant="default">
                                    <FileText className="mr-2 !h-5 !w-5" />
                                    <FormattedMessage id="knowledge.employee.resume" />
                                  </Button>
                                </DialogTrigger>
                                <DialogContent className="!min-w-[700px]">
                                  {employeeCVIsLoading ? (
                                    <div className="bg-white w-[600px] h-[600px] rounded-lg flex items-center justify-center">
                                      <Loading messageId="knowledge.employee.cvLoading" />
                                    </div>
                                  ) : (
                                    <PDFExplorer
                                      fileUrl={selectedEmployeeFile?.url}
                                      extension={
                                        selectedEmployeeFile?.extension
                                      }
                                      searchText=""
                                      pageNumber={1}
                                      noBorder={true}
                                      inDialog={true}
                                    />
                                  )}
                                </DialogContent>
                              </Dialog>
                            </div>
                            <p className="text-sm line-clamp-6">
                              {selectedEmployee?.summary}
                            </p>
                          </div>
                          <div className="my-6">
                            <h3 className="text-xl font-semibold mb-4">
                              <FormattedMessage id="knowledge.employee.certifications" />
                            </h3>
                            <ul className="list-disc list-inside text-sm">
                              {selectedEmployee?.certifications.map(
                                (certification) => (
                                  <li key={certification.name}>
                                    {certification.name}
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                          <div className="my-6">
                            <h3 className="text-xl font-semibold mb-4">
                              <FormattedMessage id="knowledge.employee.education" />
                            </h3>
                            <ul className="list-disc list-inside text-sm">
                              {selectedEmployee?.educations.map((education) => (
                                <li key={education.institution}>
                                  {education.institution} - {education.degree}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="text-2xl text-gray-400 flex justify-center w-full mx-auto py-36">
                          <FormattedMessage id="knowledge.employee.noEmployeeSelected" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Knowledge;
