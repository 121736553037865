import { ReactNode, useState } from "react";
import EditableCard, { ViewMode } from "../../components/EditableCard";
import EvaluationCriteriaTableView from "./EvaluationCriteriaTableView";
import { EvaluationCriterion } from "@/types/entities/Project";

interface EvaluationCriteriaCardProps {
  title: ReactNode;
  evaluationCriteria: EvaluationCriterion[];
  onSubmit: (newEvaluationCriteria: EvaluationCriterion[]) => void;
  loading: boolean;
}

function EvaluationCriteriaCard(props: EvaluationCriteriaCardProps) {
  const { evaluationCriteria, onSubmit, loading, title } = props;
  const [viewMode, setViewMode] = useState<ViewMode>("view");

  const handleSubmit = async (newEvaluationCriteria: EvaluationCriterion[]) => {
    await onSubmit(newEvaluationCriteria);
    setViewMode("view");
  };

  return (
    <EditableCard
      title={title}
      loading={loading}
      viewMode={viewMode}
      onViewModeChange={(newViewMode) => setViewMode(newViewMode)}
      form={
        <EvaluationCriteriaTableView
          edit={true}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={() => setViewMode("view")}
          evaluationCriteria={evaluationCriteria}
        />
      }
      view={
        <EvaluationCriteriaTableView
          edit={false}
          evaluationCriteria={evaluationCriteria}
          loading={loading}
        />
      }
    />
  );
}

export default EvaluationCriteriaCard;
