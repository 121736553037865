import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import { ArrowUpDown } from "lucide-react";
import Employee from "@/types/entities/Employee";
import { DownloadIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";

function createColumns(
  onDownload: (employee: Employee) => void,
  onDelete: (employee: Employee) => void,
): ColumnDef<Employee>[] {
  return [
    {
      accessorKey: "firstName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="employee.fields.firstName" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("firstName")}</div>,
    },
    {
      accessorKey: "lastName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="employee.fields.lastName" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("lastName")}</div>,
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="employee.fields.updatedAt" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div>
          {DateTime.fromISO(row.getValue("updatedAt")).toFormat("MMMM d, yyyy")}
        </div>
      ),
    },
    {
      accessorKey: "actions",
      header: () => {
        return <></>;
      },
      cell: ({ row }) => (
        <div className="gap-2 flex flex-row">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDownload(row.original)}
          >
            <DownloadIcon />
          </Button>
          {false && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => console.log("Edit", row.original.id)}
            >
              <Pencil1Icon />
            </Button>
          )}
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(row.original)}
          >
            <TrashIcon />
          </Button>
        </div>
      ),
    },
    {
      accessorKey: "createdAt",
    },
  ];
}

export default createColumns;
