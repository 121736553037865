interface File {
  fileData: Blob;
  lastUsed: number;
}

class FileCache {
  maxSize: number;
  cache: Map<string, File>;
  size: number;

  constructor(maxSize: number) {
    this.maxSize = maxSize;
    this.cache = new Map();
    this.size = 0;
  }

  get(fileId: string) {
    const file = this.cache.get(fileId);
    if (!file) {
      return null;
    }
    file.lastUsed = Date.now();
    return file.fileData;
  }

  add(fileId: string, fileData: Blob) {
    if (this.cache.has(fileId)) {
      const existingFile = this.cache.get(fileId);
      existingFile!.lastUsed = Date.now();
      return;
    }

    this.cache.set(fileId, {
      fileData,
      lastUsed: Date.now(),
    });

    this.size += fileData.size;

    while (this.size > this.maxSize) {
      let oldestFileId = "";
      let oldestFile = null;
      for (const [key, value] of this.cache) {
        if (!oldestFile || value.lastUsed < oldestFile.lastUsed) {
          oldestFile = value;
          oldestFileId = key;
        }
      }

      if (oldestFile) {
        this.cache.delete(oldestFileId);
        this.size -= oldestFile.fileData.size;
      }
    }
  }
}

const FileCacheService = new FileCache(100000000); // 100MB

export default FileCacheService; // 100MB
