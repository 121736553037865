import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";
import {
  RequiredDocument,
  RequiredCompanyExperience,
  RequiredEmployeeExperience,
  Requirements,
} from "@/types/entities/Requirements";
import { useIntl } from "react-intl";
import { ObjectId } from "bson";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

interface CreateRequirementDialogProps {
  selectedText: string;
  pageNumber: number;
  documentId: string;
  requirements: Requirements;
  onRequirementsUpdate: (requirements: Requirements) => void;
}

const formSchema = z
  .object({
    type: z.enum(["EMPLOYEE_EXPERIENCE", "DOCUMENT", "COMPANY_EXPERIENCE", ""]),
    title: z.string().min(1).max(255),
    description: z.string().min(1),
    documentType: z.string().optional(),
    resourceName: z.string().optional(),
    isOptional: z.boolean(),
  })
  .refine(
    (data) => {
      // Ensure resourceName is required if type is EMPLOYEE_EXPERIENCE
      return (
        data.type !== "EMPLOYEE_EXPERIENCE" ||
        (data.type === "EMPLOYEE_EXPERIENCE" && data.resourceName)
      );
    },
    {
      message: "Resource name is required",
      path: ["resourceName"], // Specify the path for the error
    },
  );

function CreateRequirementDialog(props: CreateRequirementDialogProps) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [isCreatingNewResource, setIsCreatingNewResource] =
    useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: props.selectedText,
      documentType: "",
      resourceName: "",
      isOptional: false,
    },
  });

  useEffect(() => {
    form.setValue("description", props.selectedText);
  }, [props.selectedText, form]);

  useEffect(() => {
    setOpen(!!props.selectedText);
  }, [setOpen, props.selectedText]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const newRequirement = {
      title: values.title,
      description: values.description,
      exactCopy: props.selectedText,
      pageNumber: props.pageNumber,
      isMandatory: !values.isOptional,
      documentId: props.documentId,
    };

    if (type === "DOCUMENT") {
      props.onRequirementsUpdate({
        ...props.requirements,
        documents: [
          ...props.requirements.documents,
          {
            ...newRequirement,
            type: values.documentType,
            instructions: [],
            id: new ObjectId().toString(),
          } as RequiredDocument,
        ],
      });
    } else if (type === "COMPANY_EXPERIENCE") {
      props.onRequirementsUpdate({
        ...props.requirements,
        companyExperiences: [
          ...props.requirements.companyExperiences,
          {
            ...newRequirement,
          } as RequiredCompanyExperience,
        ],
      });
    } else if (type === "EMPLOYEE_EXPERIENCE") {
      const existingResource = props.requirements.employeesExperiences.find(
        (resource) => resource.resourceName === values.resourceName,
      );

      if (existingResource) {
        existingResource.experiences.push({
          ...newRequirement,
          id: new ObjectId().toString(),
        });

        props.onRequirementsUpdate({
          ...props.requirements,
          employeesExperiences: [...props.requirements.employeesExperiences],
        });
      } else {
        const newResourceExperience = {
          resourceName: values.resourceName,
          experiences: [
            {
              ...newRequirement,
              id: new ObjectId().toString(),
            },
          ],
        };

        props.onRequirementsUpdate({
          ...props.requirements,
          employeesExperiences: [
            ...props.requirements.employeesExperiences,
            newResourceExperience as RequiredEmployeeExperience,
          ],
        });
      }
    }
    form.reset();
    setIsCreatingNewResource(false);
    setType("");
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(state: boolean) => {
        if (!loading) {
          form.reset();
          setIsCreatingNewResource(false);
          setType("");
          setOpen(state);
        }
      }}
    >
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage id="project.requirements.new" />
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
          <div className="!text-sm !font-medium !leading-none -mb-3.5">
            <FormattedMessage id="project.requirements.selectedText" />
          </div>
          <div className="max-h-[200px] overflow-auto border border-input p-2 rounded-md">
            {props.selectedText}
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-6"
            >
              {/* Type Selection */}
              <FormItem>
                <FormLabel>
                  <FormattedMessage id="project.requirements.requirementTypeHeader" />
                </FormLabel>
                <FormControl>
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onValueChange={(value) => {
                          field.onChange(value);
                          setType(value);
                        }}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder={intl.formatMessage({
                              id: "project.requirements.selectRequirementType",
                            })}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="DOCUMENT">
                            <FormattedMessage id="project.requirementCategory.DOCUMENT" />
                          </SelectItem>
                          <SelectItem value="COMPANY_EXPERIENCE">
                            <FormattedMessage id="project.requirementCategory.COMPANY_EXPERIENCE" />
                          </SelectItem>
                          <SelectItem value="EMPLOYEE_EXPERIENCE">
                            <FormattedMessage id="project.requirementCategory.EMPLOYEE_EXPERIENCE" />
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
              </FormItem>

              {/* Fields for DOCUMENT */}
              {type === "DOCUMENT" && (
                <>
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.title" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.description" />
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="documentType"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.type" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="isOptional"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="global.optional" />
                        </FormLabel>
                        <FormControl>
                          <Checkbox
                            className="mx-3"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}

              {/* Fields for COMPANY_EXPERIENCE */}
              {type === "COMPANY_EXPERIENCE" && (
                <>
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.title" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.description" />
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="isOptional"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="global.optional" />
                        </FormLabel>
                        <FormControl>
                          <Checkbox
                            className="mx-3"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}

              {/* Fields for EMPLOYEE_EXPERIENCE */}
              {type === "EMPLOYEE_EXPERIENCE" && (
                <>
                  {/* Resource Name: Select existing or create new */}
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="project.requirements.selectResource" />
                    </FormLabel>
                    <FormControl>
                      <Select
                        value={
                          isCreatingNewResource
                            ? "SpecialWordToRepresentANewResource"
                            : form.watch("resourceName")
                        }
                        onValueChange={(value) => {
                          if (value === "SpecialWordToRepresentANewResource") {
                            setIsCreatingNewResource(true);
                            form.setValue("resourceName", "");
                          } else {
                            setIsCreatingNewResource(false);
                            form.setValue("resourceName", value);
                          }
                        }}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder={intl.formatMessage({
                              id: "project.requirements.selectOrCreateResource",
                            })}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="SpecialWordToRepresentANewResource">
                            <FormattedMessage id="project.requirements.createNewResource" />
                          </SelectItem>
                          {props.requirements.employeesExperiences.map(
                            (resource) => (
                              <SelectItem
                                key={resource.resourceName}
                                value={resource.resourceName}
                              >
                                {resource.resourceName}
                              </SelectItem>
                            ),
                          )}
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItem>

                  {/* Show input if creating new resource */}
                  {isCreatingNewResource && (
                    <FormField
                      control={form.control}
                      name="resourceName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <FormattedMessage id="project.requirements.resourceName" />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.title" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="project.fields.description" />
                        </FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="isOptional"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <FormattedMessage id="global.optional" />
                        </FormLabel>
                        <FormControl>
                          <Checkbox
                            className="mx-3"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
              <DialogFooter>
                <Button type="submit" disabled={loading} loading={loading}>
                  <FormattedMessage
                    id={loading ? "global.creatingProject" : "global.create"}
                  />
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

export default CreateRequirementDialog;
