import { useRef } from "react";

function useDebounce<T extends (...args: Parameters<T>) => void>(
  callback: T,
  delay: number,
) {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const debouncedFunction = (...args: Parameters<T>) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
  return debouncedFunction;
}

export default useDebounce;
