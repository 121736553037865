import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Summary } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "@/components/ui/calendar";

interface SummaryViewProps {
  summary: Summary;
  edit: boolean;
  loading?: boolean;
  onSubmit?: (summary: Summary) => Promise<void>;
  onCancel?: () => void;
}

const formSchema = z.object({
  referenceNumber: z.string().optional(),
  projectStartDate: z.string().optional(),
  projectDuration: z.string().optional(),
  awardeeNumber: z.string().optional(),
  selectionCriteria: z.string().optional(),
  serviceType: z.string().optional(),
  location: z.string().optional(),
  workLocation: z.string().optional(),
  workLocationType: z.string().optional(),
  requiredEmployeeCvCount: z.string().optional(),
  workload: z.string().optional(),
  inquiriesContactInformation: z.string().optional(),
  qnaDeadline: z.date().optional(),
});

const isValidDate = (date: Date) => {
  try {
    return date instanceof Date && !isNaN(date.getTime());
  } catch (error) {
    return false;
  }
};

function dateLocalAsUTC(retDate: Date, reverse = false) {
  retDate.setMinutes(
    retDate.getMinutes() + retDate.getTimezoneOffset() * (reverse ? -1 : 1),
  );
  return retDate;
}

function GeneralInformationView(props: SummaryViewProps) {
  const { summary, edit, loading, onCancel, onSubmit } = props;

  const submit = async (values: z.infer<typeof formSchema>) => {
    if (!onSubmit) return;

    await onSubmit({
      ...summary,
      projectStartDate: values.projectStartDate ?? summary.projectStartDate,
      projectDuration: values.projectDuration ?? summary.projectDuration,
      referenceNumber: values.referenceNumber ?? summary.referenceNumber,
      awardeeNumber: values.awardeeNumber ?? summary.awardeeNumber,
      selectionCriteria: values.selectionCriteria ?? summary.selectionCriteria,
      serviceType: values.serviceType ?? summary.serviceType,
      location: values.location ?? summary.location,
      workLocation:
        values.workLocation ?? summary.workLocation ?? summary.location,
      workLocationType: values.workLocationType ?? summary.workLocationType,
      requiredEmployeeCvCount:
        values.requiredEmployeeCvCount ?? summary.requiredEmployeeCvCount,
      workload: values.workload ?? summary.workload,
      inquiriesContactInformation:
        values.inquiriesContactInformation ??
        summary.inquiriesContactInformation,
      qnaDeadline: values.qnaDeadline
        ? dateLocalAsUTC(values.qnaDeadline, true).toISOString().slice(0, 10)
        : summary.qnaDeadline,
    });
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      projectStartDate: summary.projectStartDate,
      projectDuration: summary.projectDuration,
      awardeeNumber: summary.awardeeNumber,
      referenceNumber: summary.referenceNumber,
      selectionCriteria: summary.selectionCriteria,
      serviceType: summary.serviceType,
      workLocation: summary.workLocation ?? summary.location,
      workLocationType: summary.workLocationType,
      requiredEmployeeCvCount: summary.requiredEmployeeCvCount,
      workload: summary.workload,
      inquiriesContactInformation: summary.inquiriesContactInformation,
      qnaDeadline: summary.qnaDeadline
        ? dateLocalAsUTC(new Date(summary.qnaDeadline), false)
        : undefined,
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex flex-col gap-6"
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width={225}>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.referenceNumber`}
                  />
                </b>
              </TableCell>

              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="referenceNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  summary.referenceNumber
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={225}>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.projectStartDate`}
                  />
                </b>
              </TableCell>
              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="projectStartDate"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  summary.projectStartDate
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.projectDuration`}
                  />
                </b>
              </TableCell>
              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="projectDuration"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  summary.projectDuration
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.selectionCriteria`}
                  />
                </b>
              </TableCell>
              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="selectionCriteria"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a valid criteria" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="price_and_quality">
                              <FormattedMessage
                                id={`project.valueMappings.selectionCriteria.price_and_quality`}
                              />
                            </SelectItem>
                            <SelectItem value="price_only">
                              <FormattedMessage
                                id={`project.valueMappings.selectionCriteria.price_only`}
                              />
                            </SelectItem>
                            <SelectItem value="quality_only">
                              <FormattedMessage
                                id={`project.valueMappings.selectionCriteria.quality_only`}
                              />
                            </SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <FormattedMessage
                    id={`project.valueMappings.selectionCriteria.${summary.selectionCriteria}`}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.awardeeNumber`}
                  />
                </b>
              </TableCell>
              {edit ? (
                <TableCell>
                  <FormField
                    control={form.control}
                    name="awardeeNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
              ) : (
                <TableCell>{summary.awardeeNumber}</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.requiredEmployeeCvCount`}
                  />
                </b>
              </TableCell>
              {edit ? (
                <TableCell>
                  <FormField
                    control={form.control}
                    name="requiredEmployeeCvCount"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
              ) : (
                <TableCell>{summary.requiredEmployeeCvCount}</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.workload`}
                  />
                </b>
              </TableCell>
              {edit ? (
                <TableCell>
                  <FormField
                    control={form.control}
                    name="workload"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
              ) : (
                <TableCell>{summary.workload}</TableCell>
              )}
            </TableRow>
            {!summary.location ? ( // If old location is not defined, show work location type
              <TableRow>
                <TableCell>
                  <b>
                    <FormattedMessage
                      id={`project.summaryFields.generalInformation.workLocationType`}
                    />
                  </b>
                </TableCell>
                <TableCell>
                  {edit ? (
                    <FormField
                      control={form.control}
                      name="workLocationType"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a valid work location mode" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="on_site">
                                <FormattedMessage
                                  id={`project.valueMappings.workLocationType.on_site`}
                                />
                              </SelectItem>
                              <SelectItem value="remote">
                                <FormattedMessage
                                  id={`project.valueMappings.workLocationType.remote`}
                                />
                              </SelectItem>
                              <SelectItem value="remote_and_on_site">
                                <FormattedMessage
                                  id={`project.valueMappings.workLocationType.remote_and_on_site`}
                                />
                              </SelectItem>
                              <SelectItem value="not_applicable">
                                <FormattedMessage
                                  id={`project.valueMappings.workLocationType.not_applicable`}
                                />
                              </SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  ) : (
                    <FormattedMessage
                      id={`project.valueMappings.workLocationType.${
                        summary.workLocationType || "not_applicable"
                      }`}
                    ></FormattedMessage>
                  )}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.workLocation`}
                  />
                </b>
              </TableCell>
              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="workLocation"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  summary.workLocation || summary.location
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage
                    id={`project.summaryFields.generalInformation.inquiriesContactInformation`}
                  />
                </b>
              </TableCell>
              {edit ? (
                <TableCell>
                  <FormField
                    control={form.control}
                    name="inquiriesContactInformation"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TableCell>
              ) : (
                <TableCell>{summary.inquiriesContactInformation}</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <FormattedMessage id="portfolio.fields.qnaDeadline" />
                </b>
              </TableCell>
              <TableCell>
                {edit ? (
                  <FormField
                    control={form.control}
                    name="qnaDeadline"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                className={cn(
                                  "w-[240px] pl-3 text-left",
                                  !field.value && "text-muted-foreground",
                                )}
                              >
                                {field.value && isValidDate(field.value) ? (
                                  new Date(field.value)
                                    .toISOString()
                                    .slice(0, 10)
                                ) : (
                                  <span>
                                    <FormattedMessage id="global.pickDate" />
                                  </span>
                                )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto p-0"
                              align="start"
                            >
                              <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : summary.qnaDeadline &&
                  isValidDate(
                    dateLocalAsUTC(new Date(summary.qnaDeadline), false),
                  ) ? (
                  new Date(summary.qnaDeadline).toISOString().slice(0, 10)
                ) : (
                  <FormattedMessage id="global.noDate" />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {edit && (
          <div className="flex gap-2 mr-4 justify-end">
            <Button onClick={onCancel} variant="outline" disabled={loading}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button type="submit" disabled={loading}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default GeneralInformationView;
