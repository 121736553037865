import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FormattedMessage, useIntl } from "react-intl";
import { useLanguage } from "@/providers/LanguageProvider";
import { useMemo } from "react";
import { useUser } from "@/hooks/UserHook/UserHook";
import { Languages } from "lucide-react";
import { useSidebar } from "@/providers";

function LanguageSelector() {
  const intl = useIntl();
  const { language, setLanguage } = useLanguage();
  const { isCollapsed } = useSidebar();
  const user = useUser();

  const shortLanguageString = useMemo(() => {
    if (language === "fr") {
      return intl.formatMessage({ id: "languages.french.short" });
    }
    return intl.formatMessage({ id: "languages.english.short" });
  }, [language, intl]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {user ? (
          <div className="text-foreground hover:!text-muted-foreground dark:text-white hover:!text-gray-400 py-2 flex items-center gap-4 transition-colors cursor-pointer">
            <Languages className={`h-5 w-5 ${isCollapsed && "ml-1.5"}`} />
            {!isCollapsed && <FormattedMessage id="languages.title" />}
          </div>
        ) : (
          <Button variant="outline" size="icon">
            {shortLanguageString}
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent align={user ? "start" : "end"}>
        <DropdownMenuItem onClick={() => setLanguage("fr")}>
          <FormattedMessage id="languages.french.long" />
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setLanguage("en")}>
          <FormattedMessage id="languages.english.long" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { LanguageSelector };
