import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import {
  ChevronRight,
  ChevronDown,
  WandSparkles,
  CirclePlus,
  Zap,
  // Plus,
  List,
  CircleMinus,
  CircleCheckBig,
  FileSearch,
} from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loading } from "@/components/Loading/Loading";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FormattedMessage } from "react-intl";
import {
  Capture,
  Evaluation,
  PotentialEmployeeEvaluation,
  EmployeeRequirement,
  ExactCopyFromCV,
} from "@/types/entities/Capture";
import { Project } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";
import { useState, useCallback, useEffect } from "react";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";

type HighlightEmployeeCVFunction = (text: string, pageNumber: number) => void;

type ShowEmployeeCVFunction = (
  cv: string,
  text: string,
  number: number,
) => void;

interface ProjectCardProps {
  project: Project;
  setProject: (project: Project) => void;
  showEmployeeCV: ShowEmployeeCVFunction;
}

function percentColor(score: string): string {
  const numScore = +score || 0;
  if (numScore < 40) {
    return "bg-[#FECACA] text-[#991B1B]";
  } else if (numScore <= 70) {
    return "bg-[#FDE68A] text-[#A16207]";
  } else {
    return "bg-[#BBF7D0] text-[#166534]";
  }
}

function EmployeeGroup({
  evaluations,
  showEmployeeCV,
  updateEmployeeResourceEvaluations,
}: {
  evaluations: Evaluation[];
  showEmployeeCV: ShowEmployeeCVFunction;
  updateEmployeeResourceEvaluations: (evaluations: Evaluation[]) => void;
}) {
  const [currentRole, setCurrentRole] = useState({
    id: evaluations?.[0]?.id,
    resourceName: evaluations?.[0]?.resourceName,
  });
  const handleUpdateEmployeeResourceEvaluation = useCallback(
    (evaluation: Evaluation) => {
      const updatedEvaluations = evaluations.map((ev: Evaluation) =>
        ev.id === evaluation.id ? evaluation : ev,
      );
      updateEmployeeResourceEvaluations(updatedEvaluations);
    },
    [updateEmployeeResourceEvaluations, evaluations],
  );
  const [maxEmployeesToShow, setMaxEmployeesToShow] = useState<number>(5);
  return (
    <div>
      {/* Top Bar */}
      <div className="mb-3 px-1 flex flex-wrap w-full items-center justify-between gap-2">
        {/* Left Name */}
        <Select
          defaultValue={JSON.stringify(currentRole)}
          value={JSON.stringify(currentRole)}
          onValueChange={(value) => {
            setCurrentRole(JSON.parse(value));
          }}
        >
          <SelectTrigger className="flex !border-0 items-center gap-4 px-2.5 py-1.5 max-w-[75%] w-auto bg-[#E0E7FF] dark:bg-[#4e5b83] rounded-lg text-base font-medium text-start text-muted-background whitespace-normal break-words">
            <SelectValue placeholder="Select a Role" />
          </SelectTrigger>
          <SelectContent>
            {evaluations?.map((item: Evaluation) => (
              <SelectItem
                key={item.id}
                value={JSON.stringify({
                  id: item?.id,
                  resourceName: item?.resourceName,
                })}
              >
                {`${item.resourceName}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {/* Right Actions */}
        <div>
          <TooltipProvider>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="default" size="sm">
                  <Zap className="mr-2 h-4 w-4" />
                  <FormattedMessage id="global.actions" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {/* More */}
                <Tooltip delayDuration={150}>
                  <TooltipTrigger asChild>
                    <DropdownMenuItem
                      onClick={() => {
                        setMaxEmployeesToShow(maxEmployeesToShow + 5);
                      }}
                    >
                      <List className="mr-2 h-4 w-4" />
                      <FormattedMessage id="global.more" />
                    </DropdownMenuItem>
                  </TooltipTrigger>
                  <TooltipContent side="left" sideOffset={8}>
                    <div className="w-max">
                      <FormattedMessage id="project.capture.moreTooltip" />
                    </div>
                  </TooltipContent>
                </Tooltip>
                {/* Add */}
                {/* <Tooltip delayDuration={150}>
                  <TooltipTrigger asChild>
                    <DropdownMenuItem>
                      <Plus className="mr-2 h-4 w-4" />
                      <FormattedMessage id="global.add" />
                    </DropdownMenuItem>
                  </TooltipTrigger>
                  <TooltipContent side="left" sideOffset={8}>
                    <div className="w-max">
                      <FormattedMessage id="project.capture.addTooltip" />
                    </div>
                  </TooltipContent>
                </Tooltip> */}
                {/* Auto-match */}
                {/* <Tooltip delayDuration={150}>
                  <TooltipTrigger asChild>
                    <DropdownMenuItem className="bg-[#F1F5F9] dark:bg-[#2d333a]">
                      <WandSparkles className="mr-2 h-4 w-4" />
                      <FormattedMessage id="project.autoMatch" />
                    </DropdownMenuItem>
                  </TooltipTrigger>
                  <TooltipContent side="left" sideOffset={8}>
                    <div className="w-max">
                      <FormattedMessage id="project.capture.matchTooltip" />
                    </div>
                  </TooltipContent>
                </Tooltip> */}
              </DropdownMenuContent>
            </DropdownMenu>
          </TooltipProvider>
        </div>
      </div>
      {/* List */}
      {evaluations
        .filter((ev: Evaluation) => ev.id === currentRole.id)
        .map((item: Evaluation) => (
          <EmployeeDetails
            key={item.id}
            evaluation={item}
            maxEmployeesToShow={maxEmployeesToShow}
            showEmployeeCV={showEmployeeCV}
            updateEmployeeResourceEvaluation={
              handleUpdateEmployeeResourceEvaluation
            }
          />
        ))}
    </div>
  );
}

function EmployeeDetails({
  evaluation,
  showEmployeeCV,
  maxEmployeesToShow,
  updateEmployeeResourceEvaluation,
}: {
  evaluation: Evaluation;
  showEmployeeCV: ShowEmployeeCVFunction;
  updateEmployeeResourceEvaluation: (evaluation: Evaluation) => void;
  maxEmployeesToShow: number;
}) {
  const handleUpdatePotentialEmployeeEvaluation = useCallback(
    (potentialEmployeeEvaluation: PotentialEmployeeEvaluation) => {
      const updatedEvaluations = evaluation.potentialEmployeeEvaluations?.map(
        (pev: PotentialEmployeeEvaluation) =>
          pev.id === potentialEmployeeEvaluation.id
            ? potentialEmployeeEvaluation
            : pev,
      );
      updateEmployeeResourceEvaluation({
        ...evaluation,
        potentialEmployeeEvaluations: updatedEvaluations,
      });
    },
    [updateEmployeeResourceEvaluation, evaluation],
  );
  return (
    <div className="w-full">
      {/* List */}
      {evaluation.potentialEmployeeEvaluations
        ?.slice(0, maxEmployeesToShow)
        .map((employeeEv) => (
          <EmployeeEvaluation
            key={employeeEv.id}
            employeeEv={employeeEv}
            showEmployeeCV={showEmployeeCV}
            updateEmployeeResourceEvaluation={
              handleUpdatePotentialEmployeeEvaluation
            }
          />
        ))}
    </div>
  );
}

function EmployeeEvaluation({
  employeeEv,
  showEmployeeCV,
  updateEmployeeResourceEvaluation,
}: {
  employeeEv: PotentialEmployeeEvaluation;
  showEmployeeCV: ShowEmployeeCVFunction;
  updateEmployeeResourceEvaluation: (
    potentialEmployeeEvaluation: PotentialEmployeeEvaluation,
  ) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const initials = `${employeeEv.employee?.firstName?.charAt(0) ?? ""}${
    employeeEv.employee?.lastName?.charAt(0) ?? ""
  }`;

  const highlightEmployeeCV: HighlightEmployeeCVFunction = (
    text,
    pageNumber,
  ) => {
    showEmployeeCV(employeeEv.employee.cv, text, pageNumber);
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
    showEmployeeCV(employeeEv.employee.cv, "", 1);
  };

  return (
    <details className="border border-gray-300 dark:!border-border rounded-md mb-4">
      <summary
        className="!px-4 py-2 flex items-center justify-between gap-3 cursor-pointer rounded-md hover:bg-muted/40 transition-all duration-200"
        onClick={handleToggle}
      >
        <div className="flex items-center gap-3">
          {isOpen ? (
            <ChevronDown className="text-foreground h-4 w-4" />
          ) : (
            <ChevronRight className="text-foreground h-4 w-4" />
          )}
          <Avatar className="h-9 w-9">
            <AvatarImage />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
          <span className="font-medium">
            {employeeEv.employee?.firstName} {employeeEv.employee?.lastName}
          </span>
        </div>
        <div className="flex items-center gap-3.5">
          <div
            className={`font-semibold px-2.5 rounded py-0.5 text-sm ${percentColor(
              employeeEv.score,
            )}`}
          >
            %{employeeEv.score || 0}
          </div>
          <Checkbox
            checked={employeeEv.isSelected}
            onClick={() => {
              updateEmployeeResourceEvaluation({
                ...employeeEv,
                isSelected: !employeeEv.isSelected,
              });
            }}
          />
        </div>
      </summary>
      <EmployeeRequirements
        requirements={employeeEv.requirements || []}
        highlightEmployeeCV={highlightEmployeeCV}
      />
    </details>
  );
}

function EmployeeRequirements({
  requirements,
  highlightEmployeeCV,
}: {
  requirements: EmployeeRequirement[];
  highlightEmployeeCV: HighlightEmployeeCVFunction;
}) {
  return (
    <div className="text-sm gap-3.5 flex flex-col p-4 pl-6">
      {requirements.map((req, index) => (
        <Requirement
          key={req.id || index}
          req={req}
          highlightEmployeeCV={highlightEmployeeCV}
        />
      ))}
    </div>
  );
}

function ExactCopy({
  exactCopies,
  highlightEmployeeCV,
}: {
  exactCopies: ExactCopyFromCV[];
  highlightEmployeeCV: HighlightEmployeeCVFunction;
}) {
  const getDisplayText = (text: string) => {
    return text.length > 60 ? `${text.substring(0, 60)}...` : text;
  };
  const handleShowCopyFromCV = (text: string, pageNumber: number) => {
    highlightEmployeeCV(text, pageNumber);
  };
  return (
    <div className="pl-12 pt-2">
      {exactCopies.map((exactCopy, index) => (
        <div
          key={index}
          className="flex items-center gap-2 mb-2 pt-2 border-b border-b-gray-200 dark:!border-b-gray-700 cursor-pointer"
          onClick={() =>
            handleShowCopyFromCV(exactCopy.text, exactCopy.pageNumber)
          }
        >
          <FileSearch className="text-muted-foreground h-4 w-4 text-lg" />
          <span className="text-muted-foreground text-xs">
            {getDisplayText(exactCopy.text)}
          </span>
        </div>
      ))}
    </div>
  );
}

function Requirement({
  req,
  highlightEmployeeCV,
}: {
  req: EmployeeRequirement;
  highlightEmployeeCV: HighlightEmployeeCVFunction;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="pb-3.5 border-b border-b-gray-200 dark:!border-b-gray-700 flex flex-col gap-2">
      <div
        className="cursor-pointer flex items-center gap-2"
        onClick={handleToggle}
      >
        <div className="flex items-center gap-2">
          {req.exactCopiesFromCv && req.exactCopiesFromCv.length > 0 ? (
            isOpen ? (
              <ChevronDown className="text-foreground h-4 w-4" />
            ) : (
              <ChevronRight className="text-foreground h-4 w-4" />
            )
          ) : (
            <div className="h-4 w-4"></div>
          )}
          <Tooltip>
            <TooltipTrigger>
              {req.met === "YES" ? (
                <CircleCheckBig className="text-[#22C55E] h-5 w-5" />
              ) : req.met === "NO" ? (
                <CirclePlus className="text-[#EF4444] h-5 w-5 rotate-45" />
              ) : (
                <CircleMinus className="text-[#FBBF24] h-5 w-5" />
              )}
            </TooltipTrigger>
            <TooltipContent>
              <div className="w-[350px]">{req.explanation || "-"}</div>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger>
              <div className="flex items-center text-left gap-4">
                {req.title}
                {!req.isMandatory && (
                  <Badge
                    variant="secondary"
                    className="rounded bg-accent font-light px-1"
                  >
                    <FormattedMessage id="global.optional" />
                  </Badge>
                )}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="w-[350px]">{req.description || "-"}</div>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      {isOpen && req.exactCopiesFromCv && req.exactCopiesFromCv.length > 0 && (
        <ExactCopy
          exactCopies={req.exactCopiesFromCv}
          highlightEmployeeCV={highlightEmployeeCV}
        />
      )}
    </div>
  );
}

function QualificationsCard({
  project,
  setProject,
  showEmployeeCV,
}: ProjectCardProps) {
  const [data, setData] = useState<Capture | null>(null);
  const [parseError, setParseError] = useState(
    project.employeeMatchingStatus === "PARSE_FAILED",
  );
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [creatingEvaluation, setCreatingEvaluation] = useState<boolean>(
    project.employeeMatchingStatus === "PARSING",
  );

  const updateEmployeeResourceEvaluations = useCallback(
    async (employeeResourceEvaluationId: string, evaluations: Evaluation[]) => {
      try {
        return await SeevClient.capture.updateEmployeeResourceEvaluations(
          project.id,
          employeeResourceEvaluationId,
          evaluations,
        );
      } catch (error) {
        console.error("Failed to update employee resource evaluation", error);
      }
    },
    [project.id],
  );

  const handleUpdateEmployeeResourceEvaluations = useCallback(
    async (evaluations: Evaluation[]) => {
      if (data) {
        setData({
          ...data,
          evaluations: evaluations,
        });
        await updateEmployeeResourceEvaluations(data.id, evaluations);
      }
    },
    [updateEmployeeResourceEvaluations, data],
  );

  const projectId = project.id;
  const setScoreSortedData = useCallback((captureData: Capture | null) => {
    if (captureData?.evaluations) {
      const sortedEvaluations = captureData.evaluations.map((evaluation) => {
        if (evaluation.potentialEmployeeEvaluations) {
          const sortedEmployees = [
            ...evaluation.potentialEmployeeEvaluations,
          ].sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
          return {
            ...evaluation,
            potentialEmployeeEvaluations: sortedEmployees,
          };
        }
        return evaluation;
      });

      setData({
        ...captureData,
        evaluations: sortedEvaluations,
      });
    } else {
      setData(captureData);
    }
  }, []);

  const createEmployeeResourceEvaluation = useCallback(async () => {
    setCreatingEvaluation(true);
    try {
      setProject({ ...project, employeeMatchingStatus: "PARSING" });
      const evaluation =
        await SeevClient.capture.createEmployeeResourceEvaluation(projectId);
      setScoreSortedData(evaluation);
    } catch (error) {
      setParseError(true);
      setData(null);
    }
  }, [setScoreSortedData, project, projectId, setProject]);

  const getEmployeeResourceEvaluations = useCallback(async () => {
    setLoading(true);
    try {
      const evaluations =
        await SeevClient.capture.getEmployeeResourceEvaluations(projectId);

      const sortedEvaluations = evaluations.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
      if (sortedEvaluations.length > 0) {
        setScoreSortedData(sortedEvaluations[0]);
      }
    } catch (error) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  }, [setScoreSortedData, projectId]);

  useEffect(() => {
    if (project.employeeMatchingStatus === "PARSE_FAILED") {
      setParseError(true);
      setLoading(false);
      setCreatingEvaluation(false);
    } else if (project.employeeMatchingStatus === "PARSING") {
      setCreatingEvaluation(true);
      setLoading(false);
    } else if (project.employeeMatchingStatus === "PARSED") {
      setCreatingEvaluation(false);
      setLoading(true);
      getEmployeeResourceEvaluations();
    }
  }, [project.employeeMatchingStatus, getEmployeeResourceEvaluations]);

  return (
    <Tabs defaultValue="teams" className="w-full flex-1 h-[650px]">
      <Card className="flex-1 h-[650px]">
        <CardHeader>
          <CardTitle>
            <FormattedMessage id="project.qualifications" />
          </CardTitle>
          <div className="flex items-center justify-end gap-4">
            <Button
              variant="default"
              size="sm"
              disabled={creatingEvaluation || loading}
              onClick={createEmployeeResourceEvaluation}
            >
              <WandSparkles className="mr-2 h-4 w-4" />
              <FormattedMessage id="project.autoMatch" />
            </Button>
            <TabsList>
              <TabsTrigger value="teams">
                <FormattedMessage id="project.tabs.teams" />
              </TabsTrigger>
              <TabsTrigger value="projects">
                <FormattedMessage id="project.tabs.projects" />
              </TabsTrigger>
              <TabsTrigger value="proposal">
                <FormattedMessage id="project.tabs.proposal" />
              </TabsTrigger>
            </TabsList>
          </div>
        </CardHeader>
        <CardContent>
          <ScrollArea className="flex-1 h-[550px] pr-3">
            <TooltipProvider>
              <TabsContent value="teams">
                {creatingEvaluation ? (
                  <div className="mt-36 flex-1">
                    <Loading messageId="project.creatingEvaluation" />
                  </div>
                ) : loading ? (
                  <div className="mt-36 flex-1">
                    <Loading messageId="project.loadingEvaluation" />
                  </div>
                ) : fetchError ? (
                  <div className="w-full flex justify-center items-center mt-36 flex-1">
                    <FormattedMessage id="project.fetchingEvaluationFailed" />
                  </div>
                ) : parseError ? (
                  <div className="w-full flex justify-center items-center mt-36 flex-1">
                    <FormattedMessage id="project.creatingEvaluationFailed" />
                  </div>
                ) : !data?.evaluations?.length ? (
                  <div className="w-full flex justify-center items-center mt-36 flex-1">
                    <FormattedMessage id="project.creatingEvaluationStart" />
                  </div>
                ) : (
                  <EmployeeGroup
                    evaluations={data?.evaluations}
                    showEmployeeCV={showEmployeeCV}
                    updateEmployeeResourceEvaluations={
                      handleUpdateEmployeeResourceEvaluations
                    }
                  />
                )}
              </TabsContent>
              <TabsContent value="projects">
                <div
                  className={`absolute left-[220] h-full w-full bg-transparent opacity-70 text-4xl text-gray-400 `}
                >
                  <div className="flex justify-center items-center text-center h-full w-full">
                    <FormattedMessage id="project.capture.noPastProject" />
                  </div>
                </div>{" "}
              </TabsContent>
              <TabsContent value="proposal">
                <div
                  className={`absolute left-[220] h-full w-full bg-transparent opacity-70 text-4xl text-gray-400 `}
                >
                  <div className="flex justify-center items-center text-center h-full w-full">
                    <FormattedMessage id="project.capture.noPastProposal" />
                  </div>
                </div>{" "}
              </TabsContent>
            </TooltipProvider>
          </ScrollArea>
        </CardContent>
      </Card>
    </Tabs>
  );
}

export default QualificationsCard;
