import { cn } from "@/lib/utils";
import { UserNav } from "@/modules/MainNav/components/UserNav";
import { ModeToggle } from "./components/ModeToggle";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { LanguageSelector } from "./components/LanguageSelector";
import { useUser } from "@/hooks/UserHook/UserHook";
import { SeevClient } from "@/lib/SeevClient";
import { featureFlags } from "@/config/featureFlags";
import { useSidebar } from "@/providers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { MenuItem } from "@/types/MenuItems";
import {
  LayoutGrid,
  Layers,
  ChartColumn,
  GlobeLock,
  LogOut,
  BookOpen,
} from "lucide-react";
import { useTheme } from "@/providers";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const navigate = useNavigate();
  const user = useUser();
  const location = useLocation();
  const { isCollapsed } = useSidebar();
  const { theme } = useTheme();

  const getTopMenuItems = (): MenuItem[] => [
    {
      title: "opportunities.title",
      icon: LayoutGrid,
      route: "/opportunities",
      onClick: () => navigate("/opportunities"),
      canSee: featureFlags().enableOpportunities,
    },
    {
      title: "portfolio.title",
      icon: Layers,
      route: "/portfolio",
      onClick: () => navigate("/portfolio"),
      canSee: true,
    },
    {
      title: "knowledge.title",
      icon: BookOpen,
      route: "/knowledge",
      onClick: () => navigate("/knowledge"),
      canSee: featureFlags().enableFileManager,
    },
    {
      title: "analytics.title",
      icon: ChartColumn,
      route: "/analytics",
      onClick: () => navigate("/analytics"),
      canSee: true,
    },
  ];

  const getBottomMenuItems = (): MenuItem[] => [
    {
      title: "privacy.title",
      icon: GlobeLock,
      route: "/privacy",
      onClick: () => navigate("/privacy"),
      canSee: true,
    },
    {
      title: "languages.title",
      route: "lang",
      canSee: true,
    },
    {
      title: "logout.title",
      icon: LogOut,
      route: "/logout",
      onClick: async () => {
        await SeevClient.authManager.logout();
        navigate("/login");
      },
      canSee: true,
    },
  ];

  const renderMenuItems = (item: MenuItem) => {
    if (item.route === "lang") {
      return (
        <div className="px-6" key={item.route}>
          <LanguageSelector />
        </div>
      );
    }

    return (
      <div
        key={item.route}
        onClick={item.onClick}
        className={cn(
          location.pathname === item.route
            ? "!text-primary hover:!text-primary"
            : "text-foreground hover:!text-muted-foreground dark:text-white hover:!text-gray-400",
          "flex items-center gap-4 px-6 py-2 transition-colors cursor-pointer",
          isCollapsed ? "mx-auto" : "justify-start",
        )}
      >
        {item.icon && <item.icon className="h-5 w-5" />}
        {!isCollapsed && <FormattedMessage id={item.title} />}
      </div>
    );
  };

  const renderWrapperMenuItems = (isBottom: boolean = false) => {
    const menuItems = isBottom ? getBottomMenuItems() : getTopMenuItems();
    return menuItems
      .filter((item) => item.canSee)
      .map((item) =>
        isCollapsed ? (
          <Tooltip key={item.route} delayDuration={150}>
            <TooltipTrigger asChild>{renderMenuItems(item)}</TooltipTrigger>
            <TooltipContent side="right" sideOffset={-10}>
              <div className="w-max">
                <FormattedMessage id={item.title} />
              </div>
            </TooltipContent>
          </Tooltip>
        ) : (
          renderMenuItems(item)
        ),
      );
  };

  // sidebar when user authorized
  if (user) {
    const dividerClass =
      "w-[80%] mx-auto h-[1px] bg-gradient-to-r from-[#F3F4F6] via-[#979797] to-[#F3F4F6] dark:from-[#212123] dark:via-[#979797] dark:to-[#212123]";
    return (
      <div className="cursor-default bg-[#F3F4F6] dark:bg-[#121114]">
        <div className="flex flex-col h-screen overflow-y-auto overflow-x-hidden items-start gap-4">
          <div className="w-full flex justify-center pt-7 pb-2">
            <img
              src={
                isCollapsed
                  ? "/favicon_S.png"
                  : theme === "dark"
                    ? "/seevai-high-resolution-logo-transparent-light.png"
                    : "/seevai-high-resolution-logo-transparent-dark.png"
              }
              className="h-8 cursor-pointer"
              alt="Logo"
              onClick={() => navigate("/portfolio")}
            />
          </div>
          <nav
            className={cn(
              "text-sm flex w-full h-full flex-col items-start gap-3 mb-10",
              className,
            )}
            {...props}
          >
            <div className="w-full text-gray-600 dark:text-gray-300 mb-2">
              <div className={`mt-1 mb-7 ${dividerClass}`} />
              {!isCollapsed && (
                <span className="px-6">
                  <FormattedMessage id="global.platform" />
                </span>
              )}
            </div>
            <TooltipProvider>{renderWrapperMenuItems()}</TooltipProvider>

            <div className="mt-auto w-full text-gray-600 dark:text-gray-300 mb-2">
              <div className={`my-7 ${dividerClass}`} />
              {!isCollapsed && (
                <span className="px-6">
                  <FormattedMessage id="global.account" />
                </span>
              )}
            </div>
            <TooltipProvider>{renderWrapperMenuItems(true)}</TooltipProvider>
          </nav>
          <div className="w-full mt-auto">
            <div className="px-6">
              <ModeToggle />
            </div>
            <div className={`mb-1 mt-7  ${dividerClass}`} />
            {user && <UserNav />}
          </div>
        </div>
      </div>
    );
  }
  // navbar when user not authorized
  else {
    return (
      <div className="flex h-16 items-center gap-20 px-8 pt-6">
        <img
          src={
            theme === "dark"
              ? "/seevai-high-resolution-logo-transparent-light.png"
              : "/seevai-high-resolution-logo-transparent-dark.png"
          }
          className="h-8 cursor-pointer"
          alt="Logo"
          onClick={() => navigate("/")}
        />

        <div className="ml-auto flex items-center space-x-4">
          <LanguageSelector />
          <ModeToggle />
        </div>
      </div>
    );
  }
}
