import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ProjectTable } from "@/modules/ProjectTable";
import { FormattedMessage } from "react-intl";
import Calendar from "@/modules/Calendar/Calendar";
import { useEffect, useState } from "react";
import { SeevClient } from "@/lib/SeevClient";
import { PortfolioProject } from "@/types/entities/Project";
import { ProjectProgress } from "@/types/entities/Project";

function Portfolio() {
  const [projects, setProjects] = useState<PortfolioProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredProjects, setFilteredProjects] = useState<PortfolioProject[]>(
    [],
  );

  const fetchProjects = async (silent: boolean = false) => {
    if (!silent) {
      setIsLoading(true);
    }

    const projects = await SeevClient.project.getProjects();

    setIsLoading(false);

    if (projects.length > 0) {
      setProjects(projects);
      setFilteredProjects(projects);
    }
  };

  const filterProjectsOnProgress = (progress: ProjectProgress | "ALL") => {
    if (progress === "ALL") {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(
        projects.filter((project) => project.progress === progress),
      );
    }
  };

  useEffect(() => {
    fetchProjects();

    // 30 seconds pooling
    const interval = setInterval(async () => {
      await fetchProjects(true);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex w-full flex-col gap-4 p-6 pt-4">
      <Tabs defaultValue="projects">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-3xl font-bold tracking-tight">
            <FormattedMessage id="portfolio.title" />
          </h2>
          <TabsList>
            <TabsTrigger value="projects">
              <FormattedMessage id="portfolio.tabs.projects" />
            </TabsTrigger>
            <TabsTrigger value="calendar">
              <FormattedMessage id="portfolio.tabs.calendar" />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="projects">
          <ProjectTable
            projects={filteredProjects}
            isLoading={isLoading}
            onUpdate={() => fetchProjects(true)}
            onCreate={() => fetchProjects()}
            filterOnProgress={(progress) => {
              filterProjectsOnProgress(progress);
            }}
          />
        </TabsContent>
        <TabsContent value="calendar">
          <Calendar projects={projects} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Portfolio;
