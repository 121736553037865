import React, { useCallback, useMemo, useState } from "react";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  PaginationState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FormattedMessage } from "react-intl";

import { DataTablePagination } from "./components/data-table-pagination";
import ToolBar from "./components/tool-bar";
import { Loading } from "@/components/Loading/Loading";
import Employee from "@/types/entities/Employee";
import createColumns from "./columns";
import RemoveEmployeeDialog from "./components/DeleteEmployeeDialog";
import { SeevClient } from "@/lib/SeevClient";

export interface EmployeeTableProps {
  employees: Employee[];
  isLoading: boolean;
  onCreate: () => void;
  onDelete: (employee: Employee) => void;
  selected: Employee | null;
  onSelect: (employee: Employee) => void;
}

export function EmployeeTable({
  employees,
  isLoading,
  onCreate,
  onDelete,
  selected,
  onSelect,
}: EmployeeTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = React.useState<string>();

  const [rowSelection, setRowSelection] = React.useState({});
  const [removeEmployee, setRemoveEmployee] = React.useState<Employee | null>(
    null,
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    createdAt: false,
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 10,
    pageIndex: 0,
  });

  const handleDelete = useCallback((employee: Employee) => {
    setRemoveEmployee(employee);
  }, []);

  const handleDownload = useCallback(async (employee: Employee) => {
    const fileEntity = await SeevClient.file.getFile(employee.cv);
    const fileContent = await SeevClient.file.downloadFile(employee.cv);
    if (!fileContent || !fileEntity) return;
    const fileURL = URL.createObjectURL(fileContent);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download =
      employee.firstName + " " + employee.lastName + "." + fileEntity.extension;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleDownload, handleDelete);
  }, [handleDownload, handleDelete]);

  const table = useReactTable({
    data: employees,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      globalFilter,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
  });

  return (
    <div className="flex flex-col space-y-4 w-full mb-14">
      <ToolBar setGlobalFilter={setGlobalFilter} onCreateFile={onCreate} />
      <div className="rounded-md border w-full">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-40 text-center"
                >
                  <Loading messageId="employee.loading" />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    if (selected?.id === row.original.id) {
                      return;
                    }
                    onSelect(row.original);
                  }}
                  // Highlight the selected row
                  className={`cursor-pointer ${selected?.id === row.original.id ? "bg-primary/50 hover:!bg-primary/50" : ""}`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <FormattedMessage id="employee.empty" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      <RemoveEmployeeDialog
        open={!!removeEmployee}
        onClose={() => setRemoveEmployee(null)}
        loading={isLoading}
        onConfirm={async () => {
          if (!removeEmployee) return;
          await onDelete(removeEmployee);
          setRemoveEmployee(null);
        }}
      />
    </div>
  );
}

export default EmployeeTable;
