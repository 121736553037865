import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage, useIntl } from "react-intl";
import RequirementTable from "./components/RequirementTable";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import { Requirement, RequirementCategory } from "@/types/entities/Requirement";
import { Project } from "@/types/entities/Project";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SearchInput } from "@/components/SearchInput/SearchInput";

export interface RequirementCardProps {
  project: Project;
  onRequirementsUpdate: (requirements: Requirement[]) => void;
  onRequirementClick: (requirement: Requirement) => void;
}

function RequirementCard({
  project,
  onRequirementsUpdate,
  onRequirementClick,
}: RequirementCardProps) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState<
    RequirementCategory | "ALL" | ""
  >("");

  const filteredRequirements = project.requirements.filter((requirement) =>
    categoryFilter && categoryFilter !== "ALL"
      ? requirement.category === categoryFilter
      : true,
  );

  const intl = useIntl();

  const updateRequirement = (updatedRequirement: Requirement) => {
    onRequirementsUpdate(
      project.requirements.map((req) =>
        req.id === updatedRequirement.id ? updatedRequirement : req,
      ),
    );
  };

  const deleteRequirement = (requirementId: string) => {
    onRequirementsUpdate(
      project.requirements.filter((req) => req.id !== requirementId),
    );
  };

  const uniqueCategories = [
    ...new Set(project.requirements.map((req) => req.category)),
  ];

  return (
    <Card className="h-[650px]">
      <CardHeader>
        <CardTitle style={{ paddingRight: "10px" }}>
          <FormattedMessage id="project.requirements.title" />
        </CardTitle>
        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            defaultValue={categoryFilter}
            value={categoryFilter}
            onValueChange={(value) => {
              setCategoryFilter(value as RequirementCategory);
            }}
          >
            <SelectTrigger className="max-w-sm" hideIcon>
              <SelectValue
                placeholder={intl.formatMessage({
                  id: "project.requirementCatagoryFilterPlaceholder",
                })}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="ALL">
                <FormattedMessage id="global.noFilter" defaultMessage="ALL" />
              </SelectItem>
              {uniqueCategories.map((category) => (
                <SelectItem key={category} value={category}>
                  <FormattedMessage
                    id={`project.requirementCategory.${category}`}
                  />
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <SearchInput
            placeholder={intl.formatMessage({
              id: "project.searchPlaceholder",
            })}
            onChange={(value) => {
              setGlobalFilter(value);
            }}
          />
        </div>
      </CardHeader>
      <div>
        <ScrollArea className="h-[550px]">
          <RequirementTable
            requirements={filteredRequirements}
            globalFilter={globalFilter}
            onRowClick={onRequirementClick}
            onChange={(requirement) => {
              updateRequirement(requirement);
            }}
            onDelete={(requirementId) => {
              deleteRequirement(requirementId);
            }}
          />
        </ScrollArea>
      </div>
    </Card>
  );
}

export default RequirementCard;
