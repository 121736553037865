import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FormattedMessage } from "react-intl";

interface RemoveProjectDialogProps {
  onConfirm: () => void;
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

function RemoveProjectDialog({
  onConfirm,
  open,
  loading,
  onClose,
}: RemoveProjectDialogProps) {
  return (
    <Dialog open={open} onOpenChange={() => (loading ? null : onClose())}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage id="global.removeAreYouSure" />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage id="project.removeAreYouSure" />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            type="submit"
            variant="destructive"
            onClick={onConfirm}
            loading={loading}
            disabled={loading}
          >
            <FormattedMessage id="global.remove" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default RemoveProjectDialog;
