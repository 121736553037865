import * as React from "react";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  Row,
  Table as TableType,
  CellContext,
} from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { featureFlags } from "@/config/featureFlags";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import AICheckbox from "@/components/AICheckox/AICheckbox";
import {
  Requirement,
  Requirements,
  RequirementMet,
  RequirementCategory,
} from "@/types/entities/Requirement";
import { Input } from "@/components/ui/input";
import { TextArea } from "@/components/ui/text-area";
import {
  TrashIcon,
  Cross2Icon,
  CheckIcon,
  // BookmarkFilledIcon,
  // BookmarkIcon,
} from "@radix-ui/react-icons";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface EditableCellProps {
  colId: string;
  cell: CellContext<Requirement, unknown>;
  onChange: (value: string) => void;
}

const EditableCell = (props: EditableCellProps) => {
  const { cell, onChange, colId } = props;
  const [edit, setEdit] = React.useState<string>();

  return cell.row.getIsSelected() ? (
    colId === "category" ? (
      <Select
        defaultValue={cell.row.getValue(colId)}
        value={edit}
        onValueChange={(value) => {
          onChange(value);
          setEdit(value);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent>
          {/* <SelectItem value="PROCESS">
            <FormattedMessage id="project.requirementCategory.PROCESS" />
          </SelectItem>
          <SelectItem value="EVALUATION">
            <FormattedMessage id="project.requirementCategory.EVALUATION" />
          </SelectItem>
          <SelectItem value="PENALTY">
            <FormattedMessage id="project.requirementCategory.PENALTY" />
          </SelectItem>
          <SelectItem value="ELIGIBILITY">
            <FormattedMessage id="project.requirementCategory.ELIGIBILITY" />
          </SelectItem>
          <SelectItem value="CERTIFICATION">
            <FormattedMessage id="project.requirementCategory.CERTIFICATION" />
          </SelectItem>
          <SelectItem value="EXPERIENCE">
            <FormattedMessage id="project.requirementCategory.EXPERIENCE" />
          </SelectItem>
          <SelectItem value="EXECUTION">
            <FormattedMessage id="project.requirementCategory.EXECUTION" />
          </SelectItem>
          <SelectItem value="DELIVERABLE">
            <FormattedMessage id="project.requirementCategory.DELIVERABLE" />
          </SelectItem>
          <SelectItem value="SUBMISSION">
            <FormattedMessage id="project.requirementCategory.SUBMISSION" />
          </SelectItem>
          <SelectItem value="LEGAL">
            <FormattedMessage id="project.requirementCategory.LEGAL" />
          </SelectItem>
          <SelectItem value="OTHER">
            <FormattedMessage id="project.requirementCategory.OTHER" />
          </SelectItem> */}
          <SelectItem value="DOCUMENT">
            <FormattedMessage id="project.requirementCategory.DOCUMENT" />
          </SelectItem>
          <SelectItem value="COMPANY_EXPERIENCE">
            <FormattedMessage id="project.requirementCategory.COMPANY_EXPERIENCE" />
          </SelectItem>
          <SelectItem value="EMPLOYEE_EXPERIENCE">
            <FormattedMessage id="project.requirementCategory.EMPLOYEE_EXPERIENCE" />
          </SelectItem>
        </SelectContent>
      </Select>
    ) : colId === "shortSummary" ? (
      <TextArea
        defaultValue={cell.row.getValue(colId)}
        value={edit}
        onChange={(event) => {
          onChange(event.target.value);
          setEdit(event.target.value);
        }}
      />
    ) : (
      <Input
        defaultValue={cell.row.getValue(colId)}
        value={edit}
        onChange={(event) => {
          onChange(event.target.value);
          setEdit(event.target.value);
        }}
      />
    )
  ) : colId === "category" ? (
    <FormattedMessage
      id={`project.requirementCategory.${cell.row.getValue(colId)}`}
    />
  ) : (
    <div>{cell.row.getValue(colId)}</div>
  );
};

export interface RequirementTableProps {
  requirements: Requirements;
  globalFilter: string;
  onRowClick: (requirement: Requirement) => void;
  onChange: (requirement: Requirement) => void;
  onDelete: (requirementId: string) => void;
}

export const RequirementTable = ({
  requirements,
  globalFilter,
  onRowClick,
  onChange,
  onDelete,
}: RequirementTableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [editRequirementId, setEditRequirementId] = React.useState<
    string | null
  >(null);
  const editRef = React.useRef<Requirement | null>(null);
  const tableRef = React.useRef<TableType<Requirement> | null>(null);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      exactCopyFromTheSection: false,
    });

  const unselectAll = React.useCallback(() => {
    if (!tableRef.current) return;

    tableRef.current
      .getRowModel()
      .rows.forEach((row) => row.toggleSelected(false));
  }, [tableRef]);

  const selectRow = (row: Row<Requirement>, requirementId: string) => {
    editRef.current = row.original;
    handleEdit(requirementId);
    if (!row.getIsSelected()) {
      unselectAll();
      row.toggleSelected(true);
    }
  };

  const handleEdit = (requirementId: string) => {
    setEditRequirementId(requirementId);
  };

  const handleChanges = React.useCallback(() => {
    if (!editRef.current || editRequirementId === null) return;

    unselectAll();
    onChange(editRef.current);
  }, [onChange, unselectAll, editRequirementId]);

  const handleCancel = React.useCallback(() => {
    unselectAll();
  }, [unselectAll]);

  const columns: ColumnDef<Requirement>[] = React.useMemo(
    () => [
      {
        accessorKey: "met",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const handleCheckboxChange = (value: RequirementMet) => {
            const updatedRequirement = {
              ...row.original,
              met: value,
            };
            onChange(updatedRequirement);
          };

          return (
            <div className="flex w-full align-center items-center justify-center">
              <AICheckbox
                value={row.getValue("met")}
                onChange={handleCheckboxChange}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "category",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.fields.category" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="category"
            cell={cell}
            onChange={(value: string) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                category: value as RequirementCategory,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "shortSummary",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.fields.shortSummary" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="shortSummary"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                shortSummary: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "relatedResource",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.fields.relatedResource" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="relatedResource"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                relatedResource: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "isMandatory",
        header: ({ column }) => {
          return (
            featureFlags().enableCapture && (
              <Button
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                <FormattedMessage id="global.optional" />
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
          );
        },
        cell: ({ row }) => {
          const handleCheckboxChange = () => {
            const updatedRequirement = {
              ...row.original,
              isMandatory: !row.original.isMandatory,
            };
            onChange(updatedRequirement);
          };

          return (
            featureFlags().enableCapture && (
              <div className="flex w-full align-center items-center justify-center">
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  checked={!row.original.isMandatory}
                />
              </div>
            )
          );
        },
      },
      // {
      //   accessorKey: "bookmarked",
      //   header: ({ column }) => {
      //     return (
      //       <Button
      //         variant="ghost"
      //         onClick={() =>
      //           column.toggleSorting(column.getIsSorted() === "asc")
      //         }
      //       >
      //         <ArrowUpDown className="ml-2 h-4 w-4" />
      //       </Button>
      //     );
      //   },
      //   cell: ({ row }) => {
      //     const handleCheckboxChange = () => {
      //       const updatedRequirement = {
      //         ...row.original,
      //         bookmarked: !row.original.bookmarked,
      //       };
      //       onChange(updatedRequirement);
      //     };

      //     return (
      //       <Button variant="ghost" size="icon" onClick={handleCheckboxChange}>
      //         {row.original.bookmarked ? (
      //           <BookmarkFilledIcon />
      //         ) : (
      //           <BookmarkIcon />
      //         )}
      //       </Button>
      //     );
      //   },
      // },
      {
        accessorKey: "exactCopyFromTheSection",
      },
      {
        accessorKey: "actions",
        header: () => <></>,
        cell: ({ row }) => {
          return (
            <div>
              {row.getIsSelected() ? (
                <div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleChanges()}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCancel()}
                  >
                    <Cross2Icon />
                  </Button>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => onDelete(row.original.id)}
                >
                  <TrashIcon />
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [handleChanges, handleCancel, onDelete, onChange],
  );

  const table = useReactTable({
    data: requirements,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
      columnVisibility,
    },
  });

  tableRef.current = table;

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onRowClick(row.original)}
              onDoubleClick={() => selectRow(row, row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <FormattedMessage id="project.requirementEmpty" />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RequirementTable;
