import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { ReactNode } from "react";

interface EditableCardProps {
  title: ReactNode;
  loading: boolean;
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
  form: ReactNode;
  view: ReactNode;
}

export type ViewMode = "view" | "edit";

function EditableCard(props: EditableCardProps) {
  const { title, form, view, viewMode, onViewModeChange, loading } = props;

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <b>{title}</b>
        </AccordionTrigger>
        <AccordionContent>
          {viewMode === "view" && (
            <div
              className="hover:bg-secondary/80 group p-[10px]"
              onDoubleClick={() => onViewModeChange("edit")}
            >
              <div className="relative w-full flex justify-end h-[20px] mb-[-20px] pt-[10px] pr-[10px] z-1">
                <Button
                  variant="ghost"
                  className="hidden group-hover:flex mt-[-10px] mr-[-10px]"
                  size="icon"
                  disabled={loading}
                  onClick={() => onViewModeChange("edit")}
                >
                  <Pencil1Icon />
                </Button>
              </div>
              {view}
            </div>
          )}
          {viewMode === "edit" && <div>{form}</div>}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export default EditableCard;
