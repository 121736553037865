import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { DialogDescription } from "@radix-ui/react-dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { SeevClient } from "@/lib/SeevClient";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface AddFileDialogProps {
  onCreate: () => void;
}
const fileTypes = [
  "CV",
  "PAST_PROJECT_DOCUMENT",
  "PAST_PROPOSAL",
  "RFP",
  "FORM",
  "ADDENDUM",
  "UNKNOWN",
];

const formSchema = z.object({
  file: z.any(),
  type: z
    .enum([
      "CV",
      "PAST_PROJECT_DOCUMENT",
      "PAST_PROPOSAL",
      "RFP",
      "FORM",
      "ADDENDUM",
      "UNKNOWN",
    ])
    .optional(),
});

function AddFileDialog(props: AddFileDialogProps) {
  const intl = useIntl();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const fileInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);

    const files = fileInput.current?.files;

    if (!files || files.length === 0) {
      form.setError("file", {
        type: "manual",
        message: intl.formatMessage({ id: "global.file.fileNotSelected" }),
      });

      setLoading(false);

      return;
    }

    try {
      const uploadPromises = Array.from(files).map((file) =>
        SeevClient.file.uploadNewFile(file, true, data.type),
      );
      await Promise.all(uploadPromises);

      props.onCreate();

      form.reset();

      setOpen(false);
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: intl.formatMessage({ id: "global.file.uploadFaild" }),
      });
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(state: boolean) => (loading ? null : setOpen(state))}
    >
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" />
          <FormattedMessage id="fileManager.new" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="fileManager.new" />
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>
                      <FormattedMessage id="fileManager.fields.file" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        ref={fileInput}
                        multiple
                        accept=".pdf,.docx,.xlsx"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="fileManager.fields.type" />
                    </FormLabel>
                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder={intl.formatMessage({
                              id: "fileManager.selectFileType",
                            })}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.values(fileTypes).map((type) => (
                            <SelectItem key={type} value={type}>
                              <FormattedMessage
                                id={`fileManager.type.${type}`}
                              />
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </DialogDescription>
            <DialogFooter>
              <Button type="submit" disabled={loading} loading={loading}>
                <FormattedMessage
                  id={loading ? "fileManager.uploading" : "fileManager.upload"}
                />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AddFileDialog;
