import { useState } from "react";
import EditableCard, { ViewMode } from "../../components/EditableCard";
import { FormattedMessage } from "react-intl";
import { Summary } from "@/types/entities/Project";
import GeneralInformationView from "./GeneralInformationView";

interface GeneralInformationProps {
  summary: Summary;
  loading: boolean;
  onSubmit: (summary: Summary) => Promise<void>;
}

function GeneralInformation(props: GeneralInformationProps) {
  const { summary, onSubmit, loading } = props;
  const [viewMode, setViewMode] = useState<ViewMode>("view");

  const handleSubmit = async (summary: Summary) => {
    await onSubmit(summary);
    setViewMode("view");
  };

  return (
    <EditableCard
      title={
        <span>
          <FormattedMessage id="project.summaryFields.generalInformation.title" />
        </span>
      }
      loading={loading}
      viewMode={viewMode}
      onViewModeChange={setViewMode}
      form={
        <GeneralInformationView
          summary={summary}
          edit={true}
          onCancel={() => setViewMode("view")}
          loading={loading}
          onSubmit={handleSubmit}
        />
      }
      view={<GeneralInformationView summary={summary} edit={false} />}
    />
  );
}

export default GeneralInformation;
