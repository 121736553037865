export const mockBiddingFunnelRatesData = [
  {
    submissionDate: "2024-07-15",
    goNoGo: 0,
    noGo: 1,
    proposal: 0,
    canceled: 2,
    submitted: 3,
    won: 1,
    execution: 2,
    completed: 1,
    lost: 1,
  },
  {
    submissionDate: "2024-07-30",
    goNoGo: 0,
    noGo: 2,
    proposal: 0,
    canceled: 1,
    submitted: 4,
    won: 2,
    execution: 1,
    completed: 0,
    lost: 2,
  },
  {
    submissionDate: "2024-08-15",
    goNoGo: 0,
    noGo: 1,
    proposal: 0,
    canceled: 3,
    submitted: 2,
    won: 1,
    execution: 1,
    completed: 1,
    lost: 1,
  },
  {
    submissionDate: "2024-08-31",
    goNoGo: 0,
    noGo: 3,
    proposal: 0,
    canceled: 1,
    submitted: 3,
    won: 2,
    execution: 2,
    completed: 1,
    lost: 0,
  },
  {
    submissionDate: "2024-09-15",
    goNoGo: 0,
    noGo: 2,
    proposal: 0,
    canceled: 2,
    submitted: 4,
    won: 1,
    execution: 1,
    completed: 0,
    lost: 2,
  },
  {
    submissionDate: "2024-09-20",
    goNoGo: 0,
    noGo: 1,
    proposal: 0,
    canceled: 1,
    submitted: 3,
    won: 2,
    execution: 2,
    completed: 1,
    lost: 1,
  },
  {
    submissionDate: "2024-09-30",
    goNoGo: 0,
    noGo: 1,
    proposal: 3,
    canceled: 1,
    submitted: 3,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-10-15",
    goNoGo: 3,
    noGo: 1,
    proposal: 2,
    canceled: 1,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-10-31",
    goNoGo: 2,
    noGo: 2,
    proposal: 3,
    canceled: 1,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-11-15",
    goNoGo: 4,
    noGo: 1,
    proposal: 2,
    canceled: 2,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-11-30",
    goNoGo: 3,
    noGo: 2,
    proposal: 1,
    canceled: 1,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-12-15",
    goNoGo: 2,
    noGo: 3,
    proposal: 2,
    canceled: 1,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2024-12-31",
    goNoGo: 3,
    noGo: 1,
    proposal: 3,
    canceled: 2,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
  {
    submissionDate: "2025-01-15",
    goNoGo: 4,
    noGo: 2,
    proposal: 1,
    canceled: 1,
    submitted: 0,
    won: 0,
    execution: 0,
    completed: 0,
    lost: 0,
  },
];

export const mockBiddingFunnelLeadersRatesData = [
  {
    bidLead: "Georges Sullivan",
    created: 0,
    goNoGo: 5,
    noGo: 3,
    proposal: 2,
    canceled: 1,
    submitted: 1,
    won: 3,
    execution: 0,
    completed: 0,
    lost: 2,
  },
  {
    bidLead: "John Doe",
    created: 0,
    goNoGo: 4,
    noGo: 2,
    proposal: 3,
    canceled: 0,
    submitted: 2,
    won: 4,
    execution: 1,
    completed: 0,
    lost: 1,
  },
  {
    bidLead: "Emily Davis",
    created: 0,
    goNoGo: 3,
    noGo: 2,
    proposal: 1,
    canceled: 2,
    submitted: 3,
    won: 2,
    execution: 0,
    completed: 1,
    lost: 3,
  },
  {
    bidLead: "Michael Brown",
    created: 0,
    goNoGo: 2,
    noGo: 3,
    proposal: 4,
    canceled: 1,
    submitted: 4,
    won: 1,
    execution: 2,
    completed: 0,
    lost: 4,
  },
  {
    bidLead: "Sarah Peterson",
    created: 0,
    goNoGo: 1,
    noGo: 4,
    proposal: 5,
    canceled: 0,
    submitted: 5,
    won: 0,
    execution: 3,
    completed: 1,
    lost: 5,
  },
];

export const mockBiddingFunnelManagersRatesData = [
  {
    manager: "John Quixote",
    created: 0,
    goNoGo: 4,
    noGo: 4,
    proposal: 3,
    canceled: 1,
    submitted: 2,
    won: 2,
    execution: 1,
    completed: 0,
    lost: 3,
  },
  {
    manager: "Jane Marlowe",
    created: 0,
    goNoGo: 3,
    noGo: 3,
    proposal: 4,
    canceled: 1,
    submitted: 3,
    won: 3,
    execution: 1,
    completed: 1,
    lost: 2,
  },
  {
    manager: "Alice Hawthorne",
    created: 0,
    goNoGo: 5,
    noGo: 2,
    proposal: 2,
    canceled: 1,
    submitted: 2,
    won: 2,
    execution: 1,
    completed: 0,
    lost: 3,
  },
  {
    manager: "Bob Thorne",
    created: 0,
    goNoGo: 2,
    noGo: 2,
    proposal: 4,
    canceled: 1,
    submitted: 3,
    won: 3,
    execution: 2,
    completed: 1,
    lost: 3,
  },
  {
    manager: "Charlie Wainwright",
    created: 0,
    goNoGo: 1,
    noGo: 5,
    proposal: 4,
    canceled: 0,
    submitted: 4,
    won: 0,
    execution: 2,
    completed: 1,
    lost: 4,
  },
];

export const mockParsedRfpsRatesData = [
  { date: "2024-08-09", count: 2 },
  { date: "2024-08-20", count: 1 },
  { date: "2024-08-22", count: 1 },
  { date: "2024-08-23", count: 2 },
  { date: "2024-08-24", count: 4 },
  { date: "2024-08-28", count: 1 },
  { date: "2024-08-29", count: 3 },
  { date: "2024-08-30", count: 1 },
  { date: "2024-08-31", count: 2 },
  { date: "2024-09-01", count: 2 },
  { date: "2024-09-02", count: 2 },
  { date: "2024-09-03", count: 3 },
  { date: "2024-09-06", count: 1 },
  { date: "2024-09-09", count: 5 },
  { date: "2024-09-10", count: 5 },
  { date: "2024-09-14", count: 1 },
  { date: "2024-09-16", count: 1 },
  { date: "2024-09-17", count: 2 },
  { date: "2024-09-19", count: 4 },
  { date: "2024-09-20", count: 4 },
  { date: "2024-09-21", count: 1 },
  { date: "2024-09-23", count: 4 },
  { date: "2024-09-24", count: 4 },
];
