import { AuthManager } from "../AuthManager";
import { AnalyticsData } from "@/types/entities/Analytics";

class AnalyticsController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async getAnalyticsData(): Promise<AnalyticsData> {
    try {
      const analyticsData = await this.authManager.request<AnalyticsData>(
        "GET",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/analytics/`,
      );
      return analyticsData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
export { AnalyticsController };
