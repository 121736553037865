import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FormattedMessage } from "react-intl";

interface RemoveEmployeeDialogProps {
  onConfirm: () => void;
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

function RemoveEmployeeDialog({
  onConfirm,
  open,
  onClose,
  loading,
}: RemoveEmployeeDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage id="global.removeAreYouSure" />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage id="employee.removeAreYouSure" />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            type="submit"
            variant="destructive"
            onClick={onConfirm}
            disabled={loading}
          >
            <FormattedMessage id="global.remove" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default RemoveEmployeeDialog;
