import { featureFlags } from "@/config/featureFlags";
import Dashboard from "./dashboard";
// import { ComingSoonOverlay } from "@/components/ComingSoon/ComingSoon";

function AnalyticsPage() {
  return (
    <div className="flex h-full w-full flex-col gap-4">
      {featureFlags().enableAnalytics ? (
        <div className="relative">
          <Dashboard />
        </div>
      ) : (
        // <ComingSoonOverlay />
        <div className="relative">
          <Dashboard />
        </div>
      )}
    </div>
  );
}

export default AnalyticsPage;
