import { OpportunitiesProject } from "@/types/entities/Opportunities";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import {
  ArrowUpDown,
  Trash2,
  Send,
  Bookmark,
  Plus,
  ExternalLink,
} from "lucide-react";
import {
  calculateColorDays,
  calculateDaysBetween,
} from "@/utils/progress-color";

export const columns: ColumnDef<OpportunitiesProject>[] = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.title" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("title")}</div>,
  },
  {
    accessorKey: "agency",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.agency" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("agency")}</div>,
  },
  {
    accessorKey: "region",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.region" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <FormattedMessage
          id={
            row.getValue("region")
              ? `opportunities.progress.${row.getValue("region")}`
              : "opportunities.noRegion"
          }
        />
      );
    },
  },
  {
    accessorKey: "summary.rfpApplicationDeadline",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex justify-center"
        >
          <FormattedMessage id="opportunities.fields.rfpApplicationDeadline" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) =>
      row.original.summary?.rfpApplicationDeadline ? (
        <div className="flex justify-center">
          <div
            className={`${calculateColorDays(
              calculateDaysBetween(
                row?.original?.summary?.rfpApplicationDeadline,
              ) ?? 0,
            )}
            w-max text-gray-700 px-2.5 py-1 rounded-lg text-center`}
          >
            {row.original.summary?.rfpApplicationDeadline}
          </div>
        </div>
      ) : (
        <div className="text-center">-</div>
      ),
  },
  {
    accessorKey: "actions",
    header: () => {
      return (
        <div className="text-center">
          <FormattedMessage id="opportunities.fields.action" />
        </div>
      );
    },
    cell: () => {
      return (
        <div className="justify-center text-center flex items-center gap-1.5">
          <Button
            variant="secondary"
            size="icon-sm"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Bookmark className="!text-foreground !h-4 !w-4" />
          </Button>
          <Button
            variant="secondary"
            size="icon-sm"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Send className="!text-foreground !h-4 !w-4" />
          </Button>
          <Button
            variant="secondary"
            size="icon-sm"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <ExternalLink className="!text-foreground !h-4 !w-4" />
          </Button>
          <Button
            variant="secondary"
            size="icon-sm"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Plus className="!text-foreground !h-4 !w-4" />
          </Button>
          <Button
            variant="secondary"
            size="icon-sm"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Trash2 className="!text-foreground !h-4 !w-4" />
          </Button>
        </div>
      );
    },
  },
  {
    accessorKey: "createdAt",
  },
];
