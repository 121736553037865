import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";

function PrivacyPolicy() {
  return (
    <div>
      <div className="w-full py-10">
        <div className="max-w-3xl mx-auto p-4">
          <Card className="bg-white dark:bg-gray-800 shadow-md">
            <CardHeader>
              <CardTitle className="text-3xl text-center text-gray-900 dark:text-gray-100">
                <FormattedMessage id="privacy.title" />
              </CardTitle>
              <div className="text-sm text-gray-600 dark:text-gray-400 text-center">
                <FormattedMessage id="privacy.effectiveDate" />
              </div>
            </CardHeader>
            <CardContent className="space-y-6 text-gray-800 dark:text-gray-200">
              <div>
                <FormattedMessage id="privacy.intro" />
              </div>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.rprp" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.rprpDetails" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.rprpName" />
                </li>
                <li>
                  <FormattedMessage id="privacy.rprpContact" />
                </li>
              </ul>
              <div>
                <FormattedMessage id="privacy.rprpInfo" />
              </div>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataCollected" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.dataCollectedIntro" />
              </div>
              <div className="font-semibold">
                <FormattedMessage id="privacy.userProvidedData" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.userProvidedDataList.name" />
                </li>
                <li>
                  <FormattedMessage id="privacy.userProvidedDataList.loginInfo" />
                </li>
                <li>
                  <FormattedMessage id="privacy.userProvidedDataList.documents" />
                </li>
              </ul>
              <div className="font-semibold">
                <FormattedMessage id="privacy.automaticallyCollectedData" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.automaticallyCollectedDataList.history" />
                </li>
              </ul>
              <div className="font-semibold">
                <FormattedMessage id="privacy.thirdPartyData" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.thirdPartyDataList.tenders" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataUsage" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.dataUsageIntro" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.dataUsageList.communication" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataUsageList.security" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataUsageList.services" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataUsageList.improvement" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataSharing" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.dataSharingIntro" />
              </div>
              <div>
                <FormattedMessage id="privacy.dataSharingCompliance" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.dataSharingPurposes.functionality" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataSharingPurposes.security" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataSharingPurposes.improvement" />
                </li>
              </ul>
              <div>
                <FormattedMessage id="privacy.internationalTransfers" />
              </div>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataRetention" />
              </CardTitle>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.dataRetentionList.accountData" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataRetentionList.deletion" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataRetentionList.archiving" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataSecurity" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.dataSecurityIntro" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.dataSecurityList.encryption" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataSecurityList.authentication" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataSecurityList.logging" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.yourRights" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.yourRightsIntro" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.yourRightsList.access" />
                </li>
                <li>
                  <FormattedMessage id="privacy.yourRightsList.rectification" />
                </li>
                <li>
                  <FormattedMessage id="privacy.yourRightsList.erasure" />
                </li>
                <li>
                  <FormattedMessage id="privacy.yourRightsList.portability" />
                </li>
              </ul>
              <div>
                <FormattedMessage id="privacy.yourRightsContact" />
              </div>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.internationalTransfersInfo" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.internationalTransfersDetails" />
              </div>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.dataBreachNotification" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.dataBreachNotificationIntro" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.dataBreachNotificationList.notify" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataBreachNotificationList.mitigate" />
                </li>
                <li>
                  <FormattedMessage id="privacy.dataBreachNotificationList.cooperate" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.policyChanges" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.policyChangesIntro" />
              </div>
              <ul className="list-disc pl-6">
                <li>
                  <FormattedMessage id="privacy.policyChangesList.email" />
                </li>
                <li>
                  <FormattedMessage id="privacy.policyChangesList.notification" />
                </li>
              </ul>

              <CardTitle className="text-2xl font-semibold">
                <FormattedMessage id="privacy.contact" />
              </CardTitle>
              <div>
                <FormattedMessage id="privacy.contactInfo" />
              </div>
              <div>
                <FormattedMessage id="privacy.contactEmail" />
              </div>

              <div>
                <FormattedMessage id="privacy.acknowledge" />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
