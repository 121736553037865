import {
  Project,
  ProjectCreate,
  ProjectUpdate,
} from "@/types/entities/Project";
import { OpportunitiesProject } from "@/types/entities/Opportunities";
import { AuthManager } from "../AuthManager";

class OpportunitiesController {
  private authManager: AuthManager;

  constructor(AuthManager: AuthManager) {
    this.authManager = AuthManager;
  }

  public async getOpportunities() {
    try {
      return this.authManager.request<OpportunitiesProject[]>(
        "GET",
        // TODO: change portfolios to opportunities after api served
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/`,
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getOpportunity(projectId: string) {
    try {
      return this.authManager.request<Project>(
        "GET",
        // TODO: change portfolios to opportunities after api served
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
      );
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }

  public async deleteOpportunity(projectId: string) {
    try {
      return this.authManager.request(
        "DELETE",
        // TODO: change portfolios to opportunities after api served
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async updateOpportunity(projectId: string, project: ProjectUpdate) {
    try {
      return this.authManager.request<Project>(
        "PATCH",
        // TODO: change portfolios to opportunities after api served
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
        project,
      );
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }

  public async createOpportunity(project: ProjectCreate, file: File | null) {
    try {
      const projectResponse = await this.authManager.request<Project>(
        "POST",
        // TODO: change portfolios to opportunities after api served
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/`,
        project,
      );
      if (file) {
        const formData = new FormData();
        formData.append("pdf", file);
        await this.authManager.request(
          "POST",
          // TODO: change portfolios to opportunities after api served
          `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectResponse.id}/files/rfp/`,
          formData,
        );
      }
      return projectResponse as Project;
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }
}
export { OpportunitiesController };
