import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState, useRef } from "react";
import { TrashIcon, Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { Todo } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";

interface TodoListProps {
  todoList: Todo[];
  onChange: (todo: Todo, index: number) => void;
  onDelete: (index: number) => void;
  updateDisabled?: boolean;
}

export function TodoList({
  todoList,
  onChange,
  onDelete,
  updateDisabled,
}: TodoListProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [edit, setEdit] = useState<Todo | null>(null);

  const handleToggle = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    index: number,
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const todo = todoList[index];
    onChange({ ...todo, completed: checked }, index);
  };

  const handleDelete = async (index: number) => {
    onDelete(index);
  };

  const handleEdit = (index: number) => {
    const todo = todoList[index];
    setEdit(todo);
  };

  const handleSave = (index: number) => {
    if (!edit || !inputRef.current) return;
    const updatedTodo = { ...edit, title: inputRef.current.value };
    onChange(updatedTodo, index);
    setEdit(null);
  };

  const handleCancel = () => {
    setEdit(null);
  };

  // Utility function to identify URLs and return the text with URLs as <a> tags
  // TODO: its not standard pattern for make link clickable , we need add small editor instead
  const parseTextWithLinks = (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    return text.split(urlPattern).map((part, index) => {
      if (urlPattern.test(part)) {
        const url = part.startsWith("www.") ? `https://${part}` : part;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:text-blue-500 underline"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div>
      {todoList.length === 0 && (
        <div className="flex w-full h-full justify-center items-center">
          <p className="mt-10">
            <FormattedMessage id="project.todos.empty" />
          </p>
        </div>
      )}
      {todoList.map((todo, index) => (
        <div key={index}>
          <div
            className="flex items-center justify-between hover:bg-secondary/80 group h-12 pl-4 pr-4"
            onDoubleClick={() => handleEdit(index)}
          >
            <div className="flex w-full flex-row gap-4">
              <input
                type="checkbox"
                checked={todo.completed}
                onClick={(event) => handleToggle(event, index)}
              />
              {edit === todo ? (
                <div className="flex w-full flex-row items-center">
                  <Input
                    ref={inputRef}
                    type="text"
                    defaultValue={todo.title}
                    className="flex w-full"
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={updateDisabled}
                    onClick={() => handleSave(index)}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCancel()}
                  >
                    <Cross2Icon />
                  </Button>
                </div>
              ) : (
                <p>{parseTextWithLinks(todo.title)}</p>
              )}
            </div>
            {edit !== todo && (
              <Button
                variant="ghost"
                size="icon"
                className="hidden group-hover:flex"
                onClick={() => handleDelete(index)}
                disabled={updateDisabled}
              >
                <TrashIcon />
              </Button>
            )}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}

export default TodoList;
