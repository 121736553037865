import { Card } from "@/components/ui/card";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { pdfjs } from "react-pdf";
import { ScrollArea } from "@/components/ui/scroll-area";
import PDFRenderer from "@/modules/FileRenderer/renderers/PDFRenderer/PDFRenderer";
import { FormattedMessage } from "react-intl";
import { useCallback, useState } from "react";
import SelectMenu from "@/components/SelectMenu/SelectMenu";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

interface PDFExplorerProps {
  fileUrl?: string | null;
  searchText: string;
  pageNumber: number;
  onSubmitSelectedText?: (text: string, pageNumber: number) => void;
  selectedTextButtonText?: string;
  extension?: string | null;
  noBorder?: boolean;
  inDialog?: boolean;
}

function PDFExplorer({
  fileUrl,
  searchText,
  pageNumber,
  onSubmitSelectedText,
  selectedTextButtonText,
  extension = "pdf",
  noBorder = false,
  inDialog = false,
}: PDFExplorerProps) {
  const [pdfRendererRef, setPDFRendererRef] = useState<HTMLDivElement | null>(
    null,
  );
  const onRefChange = useCallback((node: HTMLDivElement) => {
    setPDFRendererRef(node);
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const handleOnSubmit = useCallback(
    (text: string) => {
      if (onSubmitSelectedText) {
        onSubmitSelectedText(text, currentPage);
      }
    },
    [onSubmitSelectedText, currentPage],
  );
  if (extension === "pdf" && fileUrl) {
    return (
      <div ref={onRefChange}>
        <Card
          className={`h-[650px] overflow-hidden ${noBorder ? "border-0" : ""}`}
        >
          {pdfRendererRef && onSubmitSelectedText && selectedTextButtonText && (
            <SelectMenu
              scope={pdfRendererRef}
              onSubmit={handleOnSubmit}
              buttonText={selectedTextButtonText}
            />
          )}
          <ScrollArea className="h-[650px] z-50 p-2">
            <PDFRenderer
              fileUrl={fileUrl}
              searchText={searchText}
              pageNumber={pageNumber}
              updateCurrentPage={setCurrentPage}
              inDialog={inDialog}
            />
          </ScrollArea>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-[600px] w-[600px]">
      <FormattedMessage id="fileManager.noFileSelected" />
    </div>
  );
}

export default PDFExplorer;
