import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { DialogDescription } from "@radix-ui/react-dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";
import { ProjectCreate } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState, useRef } from "react";

interface AddProjectDialogProps {
  onCreate: () => void;
}

const formSchema = z.object({
  title: z.string().min(3).max(255).optional(),
  agency: z.string().min(3).max(255).optional(),
  file: z.string(),
});

function AddProjectDialog(props: AddProjectDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const fileInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const files = fileInput.current?.files ? fileInput.current.files : null;

    try {
      if (!files) {
        throw new Error("File is required");
      }

      const uploadPromises = Array.from(files).map((file) =>
        SeevClient.file.uploadNewFile(file, true),
      );

      const documents = await Promise.all(uploadPromises);

      if (documents.some((doc) => !doc)) {
        throw new Error("Unable to upload one or more files");
      }

      const project: ProjectCreate = {
        title: values.title || "",
        agency: values.agency || "",
        documents: documents.map((doc) => doc!.id),
      };

      const responseProject = await SeevClient.project.createProject(project);

      // SeevClient.project.extractSummary(responseProject.id);
      // SeevClient.project.extractRequirements(responseProject.id);

      if (!responseProject) {
        throw new Error("Unable to create project");
      }

      props.onCreate();

      form.reset();

      setOpen(false);
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: "",
      });
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(state: boolean) => (loading ? null : setOpen(state))}
    >
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" />
          <FormattedMessage id="portfolio.new" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="portfolio.new" />
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.title" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="agency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.agency" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>
                      <FormattedMessage id="portfolio.fields.file" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        {...field}
                        ref={fileInput}
                        accept=".pdf,.docx,.xlsx"
                        multiple
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </DialogDescription>
            <DialogFooter>
              <Button type="submit" disabled={loading} loading={loading}>
                <FormattedMessage
                  id={loading ? "global.creatingProject" : "global.create"}
                />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AddProjectDialog;
