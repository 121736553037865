import { Input } from "@/components/ui/input";
import { FormattedMessage, useIntl } from "react-intl";
import AddFileDialog from "./AddfFileDialog";
import { Button } from "@/components/ui/button";
import React from "react";
import { SearchInput } from "@/components/SearchInput/SearchInput";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

interface ToolBarProps {
  setGlobalFilter: (value: string) => void;
  semanticSearch: (value: string) => void;
  onCreateFile: () => void;
}

function ToolBar({
  setGlobalFilter,
  semanticSearch,
  onCreateFile,
}: ToolBarProps) {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const searchPlaceholder = intl.formatMessage({
    id: "fileManager.searchPlaceholder",
  });
  const semanticSearchPlaceholder = intl.formatMessage({
    id: "fileManager.semanticSearchPlaceholder",
  });
  const [semanticSearchText, setSemanticSearchText] = React.useState("");
  const [querySearch, setQuerySearch] = React.useState("");

  const handleSemanticSearch = (search: string) => {
    setSemanticSearchText(search);
    semanticSearch(search);
    navigate("", { replace: true });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    if (query) {
      setQuerySearch(query);
    } else {
      setQuerySearch("");
    }
  }, [location.search]);

  return (
    <div className="flex items-center py-4 justify-between">
      <AddFileDialog onCreate={onCreateFile} />
      <div className="flex items-center space-x-4">
        {false && (
          <Input
            placeholder={searchPlaceholder}
            onChange={(event) => setGlobalFilter(event.target.value)}
            className="max-w-sm min-w-60"
          />
        )}
        <SearchInput
          defaultValue={querySearch}
          placeholder={semanticSearchPlaceholder}
          onChange={(value) => setSemanticSearchText(value)}
          onSubmit={(value) => handleSemanticSearch(value)}
        />
        <Button
          onClick={() => handleSemanticSearch(semanticSearchText)}
          disabled={!semanticSearchText}
        >
          <FormattedMessage id="fileManager.semanticSearchButton" />
        </Button>
      </div>
    </div>
  );
}
export default ToolBar;
