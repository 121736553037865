import { ReactNode } from "react";
import { LanguageProvider } from "../providers/LanguageProvider";
import { ThemeProvider } from "../providers/ThemeProvider";
import { SidebarProvider } from "../providers/SidebarProvider";

interface AppProvidersProps {
  children: ReactNode;
}

function AppProviders(props: AppProvidersProps) {
  const { children } = props;

  return (
    <ThemeProvider>
      <LanguageProvider>
        <SidebarProvider>{children}</SidebarProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default AppProviders;
