// Input that has a search icon on the left side and a clear icon on the right side when there is text in the input.

import React, { useState, useEffect } from "react";
import { Search, XIcon } from "lucide-react";
import { Input } from "../ui/input";

interface SearchInputProps {
  placeholder: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
}

function SearchInput({ placeholder, defaultValue, onChange, onSubmit }: SearchInputProps) {
  const [value, setValue] = useState("");

  const clearInput = () => {
    setValue("");
    onChange("");
    if (onSubmit) {
      onSubmit("");
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
    if (onSubmit && e.target.value === "") {
      onSubmit(e.target.value);
    }
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <form className="max-w-md mx-auto" onSubmit={handleOnSubmit}>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <Search size={20} />
        </div>
        <Input
          type="text"
          id="default-search"
          className="block w-full p-4 ps-10 pe-10 text-sm min-w-[250px]"
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        {value && (
          <div className="absolute inset-y-0 end-0 flex items-center pe-3">
            <XIcon
              size={20}
              className="cursor-pointer shrink-0"
              onClick={clearInput}
            />
          </div>
        )}
      </div>
    </form>
  );
}

export { SearchInput };
