import { AuthManager } from "./AuthManager";
import { TokenManager } from "./TokenManager";
import { FileController } from "./entities/FileController";
import { ProjectController } from "./entities/ProjectController";
import { RFPController } from "./entities/RFPController";
import { CaptureController } from "./entities/CaptureController";
import { OpportunitiesController } from "./entities/OpportunitiesController";
import { EmployeeController } from "./entities/EmployeeController";
import { AnalyticsController } from "./entities/AnalyticsController";

class SeevAIAPIClient {
  public authManager: AuthManager;
  public project: ProjectController;
  public rfp: RFPController; // Used only in old projects
  public file: FileController;
  public capture: CaptureController;
  public opportunities: OpportunitiesController;
  public employee: EmployeeController;
  public analytics: AnalyticsController;

  constructor(
    authManager: AuthManager,
    projectController: ProjectController,
    rfpController: RFPController,
    fileController: FileController,
    captureController: CaptureController,
    opportunitiesController: OpportunitiesController,
    employeeController: EmployeeController,
    analyticsController: AnalyticsController,
  ) {
    this.project = projectController;
    this.authManager = authManager;
    this.rfp = rfpController;
    this.file = fileController;
    this.capture = captureController;
    this.opportunities = opportunitiesController;
    this.employee = employeeController;
    this.analytics = analyticsController;
  }
}

const tokenManager = new TokenManager();
const authManager = new AuthManager(
  import.meta.env.VITE_SEEV_AI_API_URL,
  tokenManager,
);
const projectController = new ProjectController(authManager);
const rfpController = new RFPController(authManager);
const fileController = new FileController(authManager);
const captureController = new CaptureController(authManager);
const opportunitiesController = new OpportunitiesController(authManager);
const employeeController = new EmployeeController(authManager);
const analyticsController = new AnalyticsController(authManager);

export const SeevClient = new SeevAIAPIClient(
  authManager,
  projectController,
  rfpController,
  fileController,
  captureController,
  opportunitiesController,
  employeeController,
  analyticsController,
);
