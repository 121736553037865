import { ReactNode, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import flattenMessages from "../../utils/intl-utils";
import i18n from "../../i18n/i18n";

import { createContext } from "react";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../../i18n/i18n";

export type LanguageContextProps = {
  language: string;
  setLanguage: (language: string) => void;
  availableLanguages: string[];
};

export const DefaultLanguageContext: LanguageContextProps = {
  language: DEFAULT_LANGUAGE,
  setLanguage: () => null,
  availableLanguages: AVAILABLE_LANGUAGES,
};

export const useLanguage = () => useContext(LanguageContext);

export const createLanguageContext = (
  setLanguageContext: React.Dispatch<
    React.SetStateAction<LanguageContextProps>
  >,
): LanguageContextProps => {
  const language = localStorage.getItem("language") || DEFAULT_LANGUAGE;
  const setLanguage = (lang: string) => {
    localStorage.setItem("language", lang);
    setLanguageContext((prev: LanguageContextProps) => ({
      ...prev,
      language: lang,
    }));
  };
  return {
    ...DefaultLanguageContext,
    setLanguage,
    language,
  };
};

const LanguageContext = createContext<LanguageContextProps>(
  DefaultLanguageContext,
);

type LanguageProviderProps = {
  children: ReactNode;
};

function LanguageProvider({ children }: LanguageProviderProps): JSX.Element {
  const [languageContext, setLanguageContext] = useState<LanguageContextProps>(
    DefaultLanguageContext,
  );

  useEffect(() => {
    const newLanguageContext = createLanguageContext(setLanguageContext);
    setLanguageContext(newLanguageContext);
  }, []);

  const locale = languageContext.language;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messages: Record<string, any> =
    i18n.get(languageContext.language) || i18n.get("fr") || new Map();

  return (
    <LanguageContext.Provider value={languageContext}>
      <IntlProvider locale={locale} messages={flattenMessages(messages)}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
