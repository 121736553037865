import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Textarea } from "@/components/ui/textarea";

export type Element = {
  name: string;
};

interface EditTableViewProps {
  edit: boolean;
  elements: Element[];
  loading: boolean;
  onSubmit?: (newElements: Element[]) => void;
  onCancel?: () => void;
}

const elementSchema = z.object({
  name: z.string().min(1).max(255),
});

const elementsSchema = z.object({
  elements: z.array(elementSchema),
});

function EditTableView(props: EditTableViewProps) {
  const { edit, onCancel, onSubmit, elements, loading } = props;

  const submit = async (values: z.infer<typeof elementsSchema>) => {
    if (!onSubmit) return;
    onSubmit(values.elements);
  };

  const form = useForm<z.infer<typeof elementsSchema>>({
    resolver: zodResolver(elementsSchema),
    defaultValues: {
      elements: [...elements],
    },
  });

  const array = useFieldArray({
    control: form.control,
    name: "elements",
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex flex-col gap-6"
      >
        <Table>
          <TableBody>
            {array.fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormItem>
                    {edit ? (
                      <FormField
                        control={form.control}
                        name={`elements.${index}.name`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ) : (
                      <div>
                        <strong>{field.name}</strong>
                      </div>
                    )}
                  </FormItem>
                </TableCell>
                {edit && (
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={loading}
                      onClick={() => array.remove(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="flex w-full justify-center gap-2">
            <Button
              type="button"
              variant={"outline"}
              disabled={loading}
              onClick={() => array.append({ name: "" })}
            >
              <PlusIcon />
              <FormattedMessage id="global.add" />
            </Button>
          </div>
        )}
        {edit && (
          <div className="flex gap-2 mr-4 justify-end">
            <Button onClick={onCancel} variant="outline" disabled={loading}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button type="submit" disabled={loading}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default EditTableView;
