import "../lib/sentry.ts";
import "./globals.css";
import AppProviders from "./AppProviders";
import AppRoutes from "./routes";
import { useUser } from "../hooks/UserHook/UserHook";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import { initSentry, setSentryUser } from "../lib/sentry";

initSentry();

function App() {
  const user = useUser();

  useEffect(() => {
    if (user) {
      setSentryUser(user);
    }
  }, [user]);

  return (
    <AppProviders>
      <BrowserRouter>
        <Layout>
          <AppRoutes />
        </Layout>
      </BrowserRouter>
    </AppProviders>
  );
}

export default App;
