import { useEffect, useState, useCallback } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import ParsedRfpsChart from "./Cards/ParsedRfpsChart";
import BiddingFunnelRatesChart from "./Cards/BiddingFunnelRatesChart";
import BiddingFunnelLeadersRatesChart from "./Cards/BiddingFunnelLeadersRatesChart";
import BiddingFunnelManagersRatesChart from "./Cards/BiddingFunnelManagersRatesChart";
import { useTheme } from "@/providers/ThemeProvider/ThemeProvider";
import {
  BiddingFunnelLeadersRate,
  BiddingFunnelManagersRate,
  BiddingFunnelRate,
  ParsedRfpsRate,
} from "@/types/entities/Analytics";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  mockBiddingFunnelLeadersRatesData,
  mockBiddingFunnelManagersRatesData,
  mockBiddingFunnelRatesData,
  mockParsedRfpsRatesData,
} from "@/mock/analyticsMock";
import { featureFlags } from "@/config/featureFlags";
import { Loading } from "@/components/Loading/Loading";

interface AnalyticsData {
  biddingFunnelLeadersRates: BiddingFunnelLeadersRate[];
  biddingFunnelRates: BiddingFunnelRate[];
  biddingFunnelManagersRates: BiddingFunnelManagersRate[];
  parsedRfpsRates: ParsedRfpsRate[];
}

type GroupBy = "month" | "week" | "day";

function Dashboard() {
  const [data, setData] = useState<AnalyticsData | null>(null);
  const [hasError, setHasError] = useState(false);
  const [biddingFunnelGroupBy, setBiddingFunnelGroupBy] =
    useState<GroupBy>("day");
  const [parsedRfpsGroupBy, setParsedRfpsGroupBy] = useState<GroupBy>("day");
  const { theme } = useTheme();
  const enableAnalytics = featureFlags().enableAnalytics;

  const getChartColors = useCallback(() => {
    const isDark = theme === "dark";
    return {
      gridColor: isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
      textColor: isDark ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
    };
  }, [theme]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const analyticsData = await SeevClient.analytics.getAnalyticsData();
        setData(analyticsData);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        setHasError(true);
      }
    };

    fetchData();
  }, []);

  if (hasError) {
    return <div>Error loading data</div>;
  }

  if (!data) {
    return (
      <div className="mt-24">
        <Loading messageId="analytics.loading" />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto gap-4 p-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">
          <FormattedMessage id="analytics.title" />
        </h2>
      </div>
      <div className="grid gap-4 py-4 md:grid-cols-1 lg:grid-cols-2 items-stretch">
        <Card className="flex flex-col">
          <CardHeader className="flex-shrink-0">
            <CardTitle>
              <FormattedMessage id="analytics.parsedRfps" />
            </CardTitle>
            <Select
              value={parsedRfpsGroupBy}
              onValueChange={(value) => setParsedRfpsGroupBy(value as GroupBy)}
            >
              <SelectTrigger className="w-[140px] text-md">
                <SelectValue placeholder="Group by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="day" className="text-lg">
                  30 Days
                </SelectItem>
                <SelectItem value="week" className="text-lg">
                  12 Weeks
                </SelectItem>
                <SelectItem value="month" className="text-lg">
                  6 Months
                </SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent className="flex-grow" style={{ minHeight: "400px" }}>
            <ParsedRfpsChart
              data={
                enableAnalytics ? data.parsedRfpsRates : mockParsedRfpsRatesData
              }
              groupBy={parsedRfpsGroupBy}
              chartColors={getChartColors()}
            />
          </CardContent>
        </Card>

        <Card className="flex flex-col">
          <CardHeader className="flex-shrink-0">
            <CardTitle>
              <FormattedMessage id="analytics.biddingFunnel" />
            </CardTitle>
            <Select
              value={biddingFunnelGroupBy}
              onValueChange={(value) =>
                setBiddingFunnelGroupBy(value as GroupBy)
              }
            >
              <SelectTrigger className="w-[140px] text-md">
                <SelectValue placeholder="Group by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="day" className="text-lg">
                  30 Days
                </SelectItem>
                <SelectItem value="week" className="text-lg">
                  12 Weeks
                </SelectItem>
                <SelectItem value="month" className="text-lg">
                  6 Months
                </SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent className="flex-grow">
            <BiddingFunnelRatesChart
              data={
                enableAnalytics
                  ? data.biddingFunnelRates
                  : mockBiddingFunnelRatesData
              }
              groupBy={biddingFunnelGroupBy}
              chartColors={getChartColors()}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
            <CardTitle>
              <FormattedMessage id="analytics.biddingFunnelManager" />
            </CardTitle>
          </CardHeader>
          <CardContent className="pt-4 h-[400px]">
            <BiddingFunnelManagersRatesChart
              data={
                enableAnalytics
                  ? data.biddingFunnelManagersRates
                  : mockBiddingFunnelManagersRatesData
              }
              chartColors={getChartColors()}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
            <CardTitle>
              <FormattedMessage id="analytics.biddingFunnelLeader" />
            </CardTitle>
          </CardHeader>
          <CardContent className="pt-4 h-[400px]">
            <BiddingFunnelLeadersRatesChart
              data={
                enableAnalytics
                  ? data.biddingFunnelLeadersRates
                  : mockBiddingFunnelLeadersRatesData
              }
              chartColors={getChartColors()}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Dashboard;
