import { Button } from "@/components/ui/button";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  OpportunityProgress,
  OpportunityDate,
} from "@/types/entities/Opportunities";
import { useState } from "react";
import { SearchInput } from "@/components/SearchInput/SearchInput";

interface ToolBarProps {
  setGlobalFilter: (value: string) => void;
  filterOnDate: (date: OpportunityDate | "TODAY") => void;
  filterOnProgress: (progress: OpportunityProgress | "ALL") => void;
}

function ToolBar({
  setGlobalFilter,
  filterOnDate,
  filterOnProgress,
}: ToolBarProps) {
  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({
    id: "opportunities.searchPlaceholder",
  });
  const [progressFilter, setProgressFilter] = useState<
    OpportunityProgress | "ALL" | ""
  >("");

  const [dateFilter, setDateFilter] = useState<OpportunityDate | "TODAY" | "">(
    "TODAY",
  );

  return (
    <div className="flex items-center py-4 justify-between">
      <div className="w-max flex items-center gap-1.5 flex-wrap">
        {[
          {
            title: "opportunities.today",
            value: "TODAY",
          },
          {
            title: "opportunities.lastWeek",
            value: "LAST_WEEK",
          },
          {
            title: "opportunities.lastMonth",
            value: "LAST_MONTH",
          },
          {
            title: "opportunities.all",
            value: "ALL",
          },
        ].map((filter) => (
          <Button
            key={filter.value}
            className={
              dateFilter !== filter.value ? "opacity-50" : "opacity-100"
            }
            onClick={() => {
              setDateFilter(filter.value as OpportunityDate);
              filterOnDate(filter.value as OpportunityDate);
            }}
          >
            <FormattedMessage id={filter.title} />
          </Button>
        ))}
      </div>
      <div className="flex items-center space-x-4">
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={(value) => setGlobalFilter(value)}
        />
        <Select
          defaultValue={progressFilter}
          value={progressFilter}
          onValueChange={(value) => {
            setProgressFilter(value as OpportunityProgress);
            filterOnProgress(value as OpportunityProgress);
          }}
        >
          <SelectTrigger className="min-w-28 max-w-max">
            <SelectValue
              placeholder={intl.formatMessage({
                id: "opportunities.progressFilterPlaceholder",
              })}
            />
          </SelectTrigger>
          <SelectContent>
            {/* TODO: check status for opportunities instance with backend */}
            <SelectItem value="ALL">
              <FormattedMessage id="global.noFilter" defaultMessage="ALL" />
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

export default ToolBar;
