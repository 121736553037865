import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import { DialogDescription } from "@radix-ui/react-dialog";
import { FormattedMessage } from "react-intl";
import { Project, ProjectUpdate } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { CalendarIcon } from "@radix-ui/react-icons";

interface ProjectSettingsDialogProps {
  project: Project;
  open: boolean;
  onSave: () => void;
  onClose: () => void;
}

const formSchema = z.object({
  title: z.string().min(3).max(255),
  agency: z.string().min(3).max(255).optional(),
  summary: z.object({
    qnaDeadline: z.date().optional(),
    rfpApplicationDeadline: z.date().optional(),
  }),
  bidLead: z.string().max(255).optional(),
  accountManager: z.string().max(255).optional(),
});

function dateLocalAsUTC(retDate: Date, reverse = false) {
  retDate.setMinutes(
    retDate.getMinutes() + retDate.getTimezoneOffset() * (reverse ? -1 : 1),
  );
  return retDate;
}

const isValidDate = (date: Date) => {
  try {
    return date instanceof Date && !isNaN(date.getTime());
  } catch (error) {
    return false;
  }
};

function ProjectSettingsDialog(props: ProjectSettingsDialogProps) {
  const { open, onClose, onSave, project } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: project.title,
      agency: project.agency,
      summary: {
        qnaDeadline: project.summary?.qnaDeadline
          ? dateLocalAsUTC(new Date(project.summary?.qnaDeadline), false)
          : undefined,
        rfpApplicationDeadline: project.summary?.rfpApplicationDeadline
          ? dateLocalAsUTC(
              new Date(project.summary?.rfpApplicationDeadline),
              false,
            )
          : undefined,
      },
      bidLead: project.bidLead,
      accountManager: project.accountManager,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const updatedProject: ProjectUpdate = {
      title: values.title,
      agency: values.agency,
      summary: {
        qnaDeadline: values.summary.qnaDeadline
          ? dateLocalAsUTC(values.summary.qnaDeadline, true)
              .toISOString()
              .slice(0, 10)
          : null,
        rfpApplicationDeadline: values.summary.rfpApplicationDeadline
          ? dateLocalAsUTC(values.summary.rfpApplicationDeadline, true)
              .toISOString()
              .slice(0, 10)
          : null,
      },
      bidLead: values.bidLead ? values.bidLead : undefined,
      accountManager: values.accountManager ? values.accountManager : undefined,
    };

    try {
      await SeevClient.project.updateProject(project.id, updatedProject);

      await onSave();

      onClose();
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: "",
      });
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="project.settings" />
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.title" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="agency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.agency" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="summary.rfpApplicationDeadline"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.rfpApplicationDeadline" />
                    </FormLabel>
                    <Popover open={isOpen} onOpenChange={setIsOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            onClick={() => setIsOpen(true)}
                            variant={"outline"}
                            className={cn(
                              "w-[240px] pl-3 text-left",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value && isValidDate(field.value) ? (
                              new Date(field.value).toISOString().slice(0, 10)
                            ) : (
                              <span>
                                <FormattedMessage id="global.pickDate" />
                              </span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          onSelect={(date) => {
                            field.onChange(date);
                            setIsOpen(false);
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="summary.qnaDeadline"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.qnaDeadline" />
                    </FormLabel>
                    <Popover open={isOpen2} onOpenChange={setIsOpen2}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            onClick={() => setIsOpen2(true)}
                            variant={"outline"}
                            className={cn(
                              "w-[240px] pl-3 text-left",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value && isValidDate(field.value) ? (
                              new Date(field.value).toISOString().slice(0, 10)
                            ) : (
                              <span>
                                <FormattedMessage id="global.pickDate" />
                              </span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          onSelect={(date) => {
                            field.onChange(date);
                            setIsOpen2(false);
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="bidLead"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.bidLead" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="accountManager"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.accountManager" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </DialogDescription>
            <DialogFooter>
              <Button type="submit" disabled={loading}>
                <FormattedMessage id="global.save" />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default ProjectSettingsDialog;
