import FileEntity, { FileType } from "@/types/entities/File";
import { RelevantDocument } from "@/types/entities/RelevantDocument";
import { AuthManager } from "../AuthManager";
import FileCacheService from "../FileCache";

class FileController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async getFiles(abortSignal?: AbortSignal): Promise<FileEntity[]> {
    try {
      const files = await this.authManager.request<FileEntity[]>(
        "GET",
        `/organizations/${this.authManager.organizationId}/documents/`,
        {},
        "json",
        abortSignal,
      );

      return files;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getFile(fileId: string): Promise<FileEntity | null> {
    try {
      const file = await this.authManager.request<FileEntity>(
        "GET",
        `/organizations/${this.authManager.organizationId}/documents/${fileId}/`,
      );

      return file;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async downloadFile(file_id: string) {
    if (!file_id) {
      throw new Error("File id is required");
    }
    try {
      const path = `/organizations/${this.authManager.organizationId}/documents/${file_id}/file/`;
      const cachedFile = FileCacheService.get(path);

      if (cachedFile) return cachedFile;

      const fileArrayBuffer = await this.authManager.request<ArrayBuffer>(
        "GET",
        `/organizations/${this.authManager.organizationId}/documents/${file_id}/file/`,
        undefined,
        "arraybuffer",
      );

      // TODO: make this dynamic based on the file type
      const fileBlob = new Blob([fileArrayBuffer], { type: "application/pdf" });

      FileCacheService.add(path, fileBlob);

      return fileBlob;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  public async deleteFile(file: FileEntity) {
    try {
      return this.authManager.request(
        "DELETE",
        `/organizations/${this.authManager.organizationId}/documents/${file.id}/`,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async uploadNewFile(
    fileData: File,
    parse: boolean,
    type: FileType | null = null,
  ) {
    try {
      const formData = new FormData();
      formData.append("file", fileData);
      if (type) {
        formData.append("type", type);
      }

      const document = await this.authManager.request<FileEntity>(
        "POST",
        `/organizations/${this.authManager.organizationId}/documents/`,
        formData,
      );

      if (parse) {
        this.authManager.request(
          "POST",
          `/v2/organizations/${this.authManager.organizationId}/documents/${document.id}/parse/`,
          formData,
        );
      }
      return document;
    } catch (error) {
      console.error(error);
    }
  }

  public async semanticSearchFiles(text: string, abortSignal?: AbortSignal) {
    try {
      const files = await this.authManager.request<RelevantDocument[]>(
        "POST",
        `/organizations/${this.authManager.organizationId}/relevant-documents/`,
        { query: text },
        "json",
        abortSignal,
      );

      return files;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
export { FileController };
