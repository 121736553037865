import { useState } from "react";
import { RequirementMet } from "@/types/entities/Requirement";
interface AICheckboxProps {
  value: RequirementMet;
  onChange: (value: RequirementMet) => void;
}

function AICheckbox({ value, onChange }: AICheckboxProps) {
  const [checkboxValue, setCheckboxValue] = useState(value);
  const checked: boolean = checkboxValue === "ai" || checkboxValue === "human";

  const styles =
    checkboxValue === "ai"
      ? { accentColor: "#a930a9" }
      : { accentColor: "#319d34" };

  const handleClick = () => {
    if (checkboxValue === "ai") {
      setCheckboxValue("none");
      onChange("none");
    }
    if (checkboxValue === "human") {
      setCheckboxValue("none");
      onChange("none");
    }
    if (checkboxValue === "none") {
      setCheckboxValue("human");
      onChange("human");
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        style={checked ? styles : {}}
        onChange={handleClick}
        checked={checked}
      />
    </div>
  );
}

export default AICheckbox;
