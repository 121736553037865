import { AuthManager } from "../AuthManager";
import FileCacheService from "../FileCache";

class RFPController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async getRFP(projectId: string) {
    try {
      const path = `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/files/rfp/`;
      const cachedFile = FileCacheService.get(path);

      if (cachedFile) return cachedFile;

      const rfpArrayBuffer = await this.authManager.request<ArrayBuffer>(
        "GET",
        path,
        undefined,
        "arraybuffer",
      );
      const rfpBlob = new Blob([rfpArrayBuffer], { type: "application/pdf" });
      FileCacheService.add(path, rfpBlob);
      return rfpBlob;
    } catch (error) {
      console.error(error);
      return "";
    }
  }
}
export { RFPController };
