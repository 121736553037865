import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import { OutlineSection } from "@/types/entities/Outline";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Plus, Eye, CircleCheckBig } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";

export interface OutlineCardProps {
  section: OutlineSection;
}

function OutlineCard({ section }: OutlineCardProps) {
  return (
    <Card className="h-[650px]">
      <ScrollArea className="h-[640px]">
        <CardHeader>
          <CardTitle style={{ paddingRight: "10px" }}>
            <FormattedMessage id="project.outline.detail.title" />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2">
            <h1>
              {section.number} {section.title}
            </h1>
            {/* Labels */}
            <div className="p-2.5 flex items-center justify-between gap-2 flex-wrap">
              <div className="text-sm flex flex-col items-center justify-center gap-1.5">
                <span className="font-semibold">
                  <FormattedMessage id="project.outline.detail.ContentFormat" />
                </span>
                <span>{section.contentFormat}</span>
              </div>
              <div className="text-sm flex flex-col items-center justify-center gap-1.5">
                <span className="font-semibold">
                  <FormattedMessage id="project.outline.detail.PageLimit" />
                </span>
                <span>N/A</span>
              </div>
              <div className="text-sm flex flex-col items-center justify-center gap-1.5">
                <span className="font-semibold">
                  <FormattedMessage id="project.outline.detail.PageTarget" />
                </span>
                <span>{section.number}</span>
              </div>
              <div className="text-sm flex flex-col items-center justify-center gap-1.5">
                <span className="font-semibold">
                  <FormattedMessage id="project.outline.detail.Reviewer" />
                </span>
                <Avatar className="h-6 w-6 bg-muted">
                  <AvatarImage src="/favicon_S.png" />
                </Avatar>
              </div>
              <div className="text-sm flex flex-col items-center justify-center gap-1.5">
                <span className="font-semibold">
                  <FormattedMessage id="project.outline.detail.ReviewStatus" />
                </span>
                <CircleCheckBig
                  className={`${
                    section.isReviewed
                      ? "text-green-500"
                      : "text-gray-300 dark:text-gray-700"
                  } h-6 w-6 cursor-pointer`}
                />
              </div>
            </div>

            {/* Write-Up */}
            <div className="text-sm flex flex-col mt-4 gap-1.5">
              <span className="font-semibold">
                <FormattedMessage id="project.outline.detail.WriteUpPlan" />
              </span>
              <p className="text-xs w-full px-4 py-2 !border leading-6 rounded-lg">
                {section.description}
              </p>
            </div>

            {/* Relevant Documents & Input Needed */}
            <div className="text-sm grid xl:grid-cols-3 gap-x-10 gap-y-6">
              {/* Relevant Documents */}
              <div className="xl:col-span-2 w-full flex flex-col mt-6 gap-1.5">
                <div className="flex items-center justify-between">
                  <span className="font-medium">
                    <FormattedMessage id="project.outline.detail.RelevantDocuments" />
                  </span>
                  <Button variant="outline" size="xs">
                    <Plus className="mr-2 h-4 w-4" />
                    <FormattedMessage id="global.add" />
                  </Button>
                </div>
                <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
                  {["Standard_Hourly_Rates.xlsx", "Staff_roles.xlsx"].map(
                    (req) => (
                      <div
                        className="flex items-center justify-between pt-3"
                        key={req}
                      >
                        <span>{req}</span>
                        <Eye className="text-gray-500 h-5 w-5 cursor-pointer" />
                      </div>
                    ),
                  )}
                </div>
              </div>
              {/* Input Needed */}
              <div className="w-full flex flex-col mt-6 gap-1.5">
                <span className="font-medium">
                  <FormattedMessage id="project.outline.detail.InputNeeded" />
                </span>
                <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
                  {["Estimate hours"].map((req) => (
                    <div
                      className="flex items-center justify-between pt-3"
                      key={req}
                    >
                      <span>{req}</span>
                      <CircleCheckBig className="text-gray-500 h-5 w-5 cursor-pointer" />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Requirements Supported */}
            <div className="text-sm w-max flex flex-col mt-6 gap-1.5">
              <span className="font-medium">
                <FormattedMessage id="project.outline.detail.RequirementsSupported" />
              </span>
              <div className="flex flex-col divide-y gap-y-2 text-sm w-full px-5 pb-3 !border leading-6 rounded-lg">
                <div className="flex !flex-col 2xl:!flex-row 2xl:!items-center gap-1.5 pt-3">
                  <span> The Pricing is to be provided in the format... </span>
                  <span className="text-primary hover:text-pastel-blue cursor-pointer pr-4">
                    {" "}
                    See in Compliance Matrix{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export default OutlineCard;
