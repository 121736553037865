import { ToolBar } from "./components/ToolBar/ToolBar";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import Dashboard from "./Tabs/dashboard";
import Requirements from "./Tabs/Requirements/requirements";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { Project } from "@/types/entities/Project";
import { Loading } from "@/components/Loading/Loading";
import { Requirement } from "@/types/entities/Requirement";
import { featureFlags } from "@/config/featureFlags";
import Capture from "./Tabs/capture";
import { ComingSoonOverlay } from "@/components/ComingSoon/ComingSoon";
import Proposal from "./Tabs/proposal";
import { SeevClient } from "@/lib/SeevClient";
import RequirementsV2 from "./Tabs/Requirements/requirementsV2";
import { Requirements as RequirementsV2Entity } from "@/types/entities/Requirements";
import { Button } from "@/components/ui/button";

function ProjectPage() {
  const { projectId } = useParams<{ projectId: string }>();
  const [selectedTab, setSelectedTab] = useState<string>("dashboard");

  const [project, setProject] = useState<Project>();
  const [file, setFile] = useState<string | null>(null);

  const fetchProject = useCallback(async () => {
    if (projectId) {
      const project = await SeevClient.project.getProject(projectId);
      setProject(project);
    }
  }, [projectId]);

  const updateRequirements = useCallback(
    async (requirements: Requirement[]) => {
      if (!project?.id) return;

      try {
        await SeevClient.project.updateProject(project.id, {
          requirements,
        });
        setProject((prev) => prev && { ...prev, requirements });
      } catch (error) {
        console.error("Failed to update requirements", error);
      }
    },
    [project?.id],
  );

  const updateRequirementsV2 = useCallback(
    async (requirements: RequirementsV2Entity) => {
      if (!project?.id) return;
      try {
        await SeevClient.project.updateProject(project.id, {
          requirementsV2: requirements,
        });
        setProject((prev) => prev && { ...prev, requirementsV2: requirements });
      } catch (error) {
        console.error("Failed to update requirements", error);
      }
    },
    [project?.id],
  );

  const regenerateSummary = useCallback(async () => {
    if (!project?.id) return;
    try {
      await SeevClient.project.extractSummary(project.id);
      fetchProject();
    } catch (error) {
      console.error("Failed to regenerate summary", error);
    }
  }, [project?.id, fetchProject]);

  const regenerateRequirements = useCallback(async () => {
    if (!project?.id) return;
    try {
      await SeevClient.project.extractRequirements(project.id);
      fetchProject();
    } catch (error) {
      console.error("Failed to regenerate requirements", error);
    }
  }, [project?.id, fetchProject]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    if (
      project?.status === "PARSING" ||
      project?.status === "CREATED" ||
      project?.summaryStatus === "PARSING" ||
      project?.summaryStatus === "NOT_PARSED" ||
      project?.requirementsStatus === "PARSING" ||
      project?.requirementsStatus === "NOT_PARSED" ||
      (project?.employeeMatchingStatus === "PARSING" &&
        featureFlags().enableCapture) ||
      project?.outlineStatus === "PARSING" ||
      (project?.proposalStatus === "IN_PROGRESS" &&
        featureFlags().enableOutlineV2)
    ) {
      const interval = setInterval(() => {
        fetchProject();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [
    fetchProject,
    project?.status,
    project?.summaryStatus,
    project?.requirementsStatus,
    project?.employeeMatchingStatus,
    project?.outlineStatus,
    project?.proposalStatus,
    selectedTab,
  ]);

  useEffect(() => {
    async function fetchFile() {
      if (!project?.id) return;
      const rfp_id = project.documents ? project.documents[0] : null;

      let file: Blob | string = "";

      if (rfp_id) {
        file = await SeevClient.file.downloadFile(rfp_id);
      }

      if (!file) {
        // for backward compatibility, fall back to old rfp endpoint
        file = await SeevClient.rfp.getRFP(project.id);
      }

      if (typeof file == "string") return;
      const fileURL = URL.createObjectURL(file);
      setFile(fileURL);
    }
    fetchFile();
  }, [project?.id, project?.documents]);

  if (!projectId) {
    return <FormattedMessage id="project.empty" />;
  }

  return (
    <div className="flex w-full flex-col gap-4 p-6">
      {!project ? (
        <div className="mt-24">
          <Loading messageId="portfolio.loading" />
        </div>
      ) : (
        <Tabs
          defaultValue="dashboard"
          onValueChange={(value) => {
            setSelectedTab(value);
          }}
          value={selectedTab}
        >
          <ToolBar project={project} onSave={fetchProject} />
          {project?.status === "PARSE_FAILED" ? (
            <div
              className={`absolute h-1/2 w-full bg-transparent opacity-70 text-4xl`}
            >
              <div className="flex justify-center items-center h-full w-full">
                <FormattedMessage id="project.parseFailed" />
              </div>
            </div>
          ) : project?.status === "CREATED" || project?.status === "PARSING" ? (
            <div className="mt-24">
              <Loading messageId="project.parsing" />
            </div>
          ) : (
            <>
              <TabsContent value="dashboard">
                {project?.summaryStatus === "PARSED" ||
                !project?.summaryStatus ? (
                  <Dashboard project={project} onSave={fetchProject} />
                ) : project?.summaryStatus === "PARSING" ||
                  project?.summaryStatus === "NOT_PARSED" ? (
                  <div className="mt-24">
                    <Loading messageId="project.summaryParsing" />
                  </div>
                ) : project?.summaryStatus === "PARSE_FAILED" ? (
                  <div className="mt-12 flex flex-col gap-6 text-center justify-center items-center bg-transparent opacity-70 text-4xl">
                    <FormattedMessage id="project.summaryParseFailed" />
                    <Button
                      variant="default"
                      className="w-max"
                      onClick={regenerateSummary}
                    >
                      <FormattedMessage id="project.summaryRegenerate" />
                    </Button>
                  </div>
                ) : (
                  // This should never happen
                  <div className="mt-24"></div>
                )}
              </TabsContent>
              <TabsContent value="requirements">
                {project?.requirementsStatus === "PARSED" ||
                !project?.requirementsStatus ? (
                  project?.requirementsV2 ? (
                    <RequirementsV2
                      project={project}
                      file={file}
                      onRequirementsUpdate={updateRequirementsV2}
                    />
                  ) : (
                    <Requirements
                      project={project}
                      file={file}
                      onRequirementsUpdate={updateRequirements}
                    />
                  )
                ) : project?.requirementsStatus === "PARSING" ||
                  project?.requirementsStatus === "NOT_PARSED" ? (
                  <div className="mt-24">
                    <Loading messageId="project.requirementsParsing" />
                  </div>
                ) : project?.requirementsStatus === "PARSE_FAILED" ? (
                  <div className="mt-12 flex flex-col gap-6 text-center justify-center items-center bg-transparent opacity-70 text-4xl">
                    <FormattedMessage id="project.requirementsParseFailed" />
                    <Button
                      variant="default"
                      className="w-max"
                      onClick={regenerateRequirements}
                    >
                      <FormattedMessage id="project.requirementsRegenerate" />
                    </Button>
                  </div>
                ) : (
                  // This should never happen
                  <div className="mt-24"></div>
                )}
              </TabsContent>
              <TabsContent value="qualifications">
                {featureFlags().enableCapture ? (
                  <Capture project={project} setProject={setProject} />
                ) : (
                  <ComingSoonOverlay color={"none"} />
                )}
              </TabsContent>
              <TabsContent value="proposal">
                <Proposal project={project} setProject={setProject} />
              </TabsContent>
            </>
          )}
        </Tabs>
      )}
    </div>
  );
}

export default ProjectPage;
