import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { SeevClient } from "@/lib/SeevClient";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { Card, CardTitle } from "@/components/ui/card";

const formSchema = z.object({
  email: z.string().optional(),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const email = values.email || "";

    try {
      await SeevClient.authManager.forgetPassword(email);
      setSent(true);
    } catch (error) {
      form.setError("email", {
        type: "manual",
        message: intl.formatMessage({ id: "login.validation.invalidEmail" }),
      });
    }
    setLoading(false);
  };

  return (
    <div className="w-full max-w-md">
      <Card className="p-8 bg-white/80 dark:bg-gray-800/80 rounded-lg shadow-lg">
        <div className="mb-6">
          <CardTitle className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100">
            <FormattedMessage id="login.sendResetTitle" />
          </CardTitle>
          <p className="mt-2 text-center text-gray-600 dark:text-gray-300">
            <FormattedMessage id="login.sendResetDescription" />
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "global.email",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              disabled={loading || sent}
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              {sent ? (
                <FormattedMessage id="login.resetLinkSent" />
              ) : (
                <FormattedMessage id="login.sendResetLink" />
              )}
            </Button>
          </form>
        </Form>
        <div className="mt-4 text-center">
          <Button
            variant="link"
            className="text-sm text-indigo-600 dark:text-indigo-400 hover:underline"
            onClick={() => navigate("/login")}
          >
            <FormattedMessage id="login.remember" />
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default ForgotPassword;
