import {
  CellContext,
  ColumnDef,
  Row,
  Table as TableType,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import { Input } from "@/components/ui/input";
import { useCallback, useMemo, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { TrashIcon, Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { Note } from "@/types/entities/Project";

interface NoteListProps {
  noteList: Note[];
  onChange: (note: Note, index: number) => void;
  onDelete: (index: number) => void;
  updateDisabled?: boolean;
}

const EditableCell = (props: {
  colId: string;
  cell: CellContext<Note, unknown>;
  onChange: (value: string) => void;
}) => {
  const { cell, onChange, colId } = props;
  const [edit, setEdit] = useState<string>();

  return cell.row.getIsSelected() ? (
    <Input
      defaultValue={cell.row.getValue(colId)}
      value={edit}
      onChange={(event) => {
        onChange(event.target.value);
        setEdit(event.target.value);
      }}
    />
  ) : (
    <div>{cell.row.getValue(colId)}</div>
  );
};

export function Notes(props: NoteListProps) {
  const { noteList, onChange, onDelete, updateDisabled } = props;

  const editRef = useRef<Note | null>(null);
  const tableRef = useRef<TableType<Note> | null>(null);

  const unselectAll = useCallback(() => {
    if (!tableRef.current) return;

    tableRef.current
      .getRowModel()
      .rows.forEach((row) => row.toggleSelected(false));
  }, [tableRef]);

  const [editIndex, setEditIndex] = useState<number | null>(null);

  const handleEdit = (index: number) => {
    setEditIndex(index);
  };

  const selectRow = useCallback(
    (row: Row<Note>, index: number) => {
      if (index === editIndex) return;

      editRef.current = row.original;

      handleEdit(index);
      if (!row.getIsSelected()) {
        unselectAll();
        row.toggleSelected(true);
      }
    },
    [editIndex, unselectAll],
  );

  const handleChanges = useCallback(() => {
    if (!editRef.current || editIndex === null) return;

    unselectAll();
    onChange(editRef.current, editIndex);
  }, [onChange, unselectAll, editIndex]);

  const handleCancel = useCallback(() => {
    unselectAll();
  }, [unselectAll]);

  const columns: ColumnDef<Note>[] = useMemo(
    () => [
      {
        accessorKey: "property",
        header: () => (
          <div className="ml-2">
            <FormattedMessage id="project.fields.property" />
          </div>
        ),
        cell: (cell) => (
          <EditableCell
            colId="property"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                property: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "value",
        header: () => (
          <div className=" ml-2">
            <FormattedMessage id="project.fields.value" />
          </div>
        ),
        cell: (cell) => (
          <EditableCell
            colId="value"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                value: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "actions",
        header: () => <></>,
        cell: ({ row }) => {
          return (
            <div>
              {row.getIsSelected() ? (
                <div>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={updateDisabled}
                    onClick={() => handleChanges()}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCancel()}
                  >
                    <Cross2Icon />
                  </Button>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  size="icon"
                  disabled={updateDisabled}
                  onClick={() => {
                    if (tableRef.current) {
                      const rowIndex = tableRef.current
                        .getRowModel()
                        .rows.findIndex((tableRow) => tableRow.id === row.id);
                      onDelete(rowIndex);
                    }
                  }}
                >
                  <TrashIcon />
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [handleChanges, handleCancel, onDelete, updateDisabled],
  );

  const reactTable = useReactTable({
    data: noteList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  tableRef.current = reactTable;

  return (
    <Table>
      <TableHeader>
        {reactTable.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {reactTable.getRowModel().rows?.length ? (
          reactTable.getRowModel().rows.map((row, index) => (
            <TableRow key={row.id} onDoubleClick={() => selectRow(row, index)}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="py-1">
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                  })}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <FormattedMessage id="project.notes.empty" />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default Notes;
