import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState, useEffect } from "react";
import {
  Requirements,
  RequiredDocument,
  RequiredCompanyExperience,
  RequiredExperience,
  RequiredEmployeeExperience,
} from "@/types/entities/Requirements";
import * as React from "react";

import { FormattedMessage } from "react-intl";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import DocumentsTable from "./tabs/RequiredDocumentTable";
import RequiredCompanyExperienceTable from "./tabs/RequiredCompanyExperienceTable";
import RequiredEmployeeExperienceTable from "./tabs/RequiredEmployeeExperienceTable";

interface RequirementsCardProps {
  requirements: Requirements;
  onRequirementsUpdate: (requirements: Requirements) => void;
  onRequirementClick: (
    requirement:
      | RequiredDocument
      | RequiredCompanyExperience
      | RequiredExperience,
  ) => void;
}

function RequirementsCard({
  requirements,
  onRequirementsUpdate,
  onRequirementClick,
}: RequirementsCardProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const [currentRequirements, setCurrentRequirements] =
    useState<Requirements>(requirements);

  useEffect(() => {
    setCurrentRequirements(requirements);
  }, [requirements]);

  const handleDocumentUpdate = (updatedDocument: RequiredDocument) => {
    const updatedDocuments = currentRequirements.documents.map((doc) =>
      doc.id === updatedDocument.id ? updatedDocument : doc,
    );
    const newRequirements = {
      ...currentRequirements,
      documents: updatedDocuments,
    };
    setCurrentRequirements(newRequirements);
    onRequirementsUpdate(newRequirements);
  };

  const handleCompanyExperienceUpdate = (
    updatedCompanyExperience: RequiredCompanyExperience,
  ) => {
    const updatedCompanyExperiences =
      currentRequirements.companyExperiences.map((exp) =>
        exp.id === updatedCompanyExperience.id ? updatedCompanyExperience : exp,
      );
    const newRequirements = {
      ...currentRequirements,
      companyExperiences: updatedCompanyExperiences,
    };
    setCurrentRequirements(newRequirements);
    onRequirementsUpdate(newRequirements);
  };

  const handleEmployeeExperienceUpdate = (
    updatedEmployeeExperience: RequiredExperience,
  ) => {
    const newRequirements = {
      ...currentRequirements,
      employeesExperiences: currentRequirements.employeesExperiences.map(
        (employeeExperience) => ({
          ...employeeExperience,
          experiences: employeeExperience.experiences.map((exp) =>
            exp.id === updatedEmployeeExperience.id
              ? updatedEmployeeExperience
              : exp,
          ),
        }),
      ),
    };
    setCurrentRequirements(newRequirements);
    onRequirementsUpdate(newRequirements);
  };

  // Handler for deleting the requirement
  const deleteRequirement = (requirementId: string) => {
    const updatedRequirements = {
      ...requirements,
      documents: requirements.documents.filter(
        (doc) => doc.id !== requirementId,
      ),
      companyExperiences: requirements.companyExperiences.filter(
        (exp) => exp.id !== requirementId,
      ),
      employeesExperiences: requirements.employeesExperiences.map(
        (employeeExperience) => ({
          ...employeeExperience,
          experiences: employeeExperience.experiences.filter(
            (exp) => exp.id !== requirementId,
          ),
        }),
      ),
    };

    onRequirementsUpdate(updatedRequirements);
  };

  useEffect(() => {
    if (requirements) {
      setLoading(false);
    } else {
      setError(true);
    }
  }, [requirements]);

  if (loading) {
    return <div>Loading requirements...</div>;
  }

  if (error) {
    return <div>Error loading requirements</div>;
  }

  return (
    <Tabs defaultValue="documents" className="w-full flex-1 h-[650px]">
      <Card className="flex-1 h-[650px]">
        <CardHeader>
          <CardTitle>
            <FormattedMessage id="project.tabs.requirements" />
          </CardTitle>
          <TabsList>
            <TabsTrigger value="documents">
              <FormattedMessage id="project.documents" />
            </TabsTrigger>
            <TabsTrigger value="company_experiences">
              <FormattedMessage id="project.companyExperiences" />
            </TabsTrigger>
            <TabsTrigger value="employees_experiences">
              <FormattedMessage id="project.employeeExperiences" />
            </TabsTrigger>
          </TabsList>
        </CardHeader>
        <CardContent>
          <ScrollArea className="flex-1 h-[550px] pr-3">
            <TabsContent value="documents">
              <DocumentsTable
                globalFilter={""}
                requirements={currentRequirements.documents}
                onRowClick={onRequirementClick}
                onChange={handleDocumentUpdate}
                onDelete={deleteRequirement}
              />
            </TabsContent>

            <TabsContent value="company_experiences">
              <RequiredCompanyExperienceTable
                globalFilter={""}
                requirements={currentRequirements.companyExperiences}
                onRowClick={onRequirementClick}
                onChange={handleCompanyExperienceUpdate}
                onDelete={deleteRequirement}
              />
            </TabsContent>
            <TabsContent value="employees_experiences">
              <EmployeeExperiencesTab
                experiences={currentRequirements.employeesExperiences}
                onRequirementClick={onRequirementClick}
                onRequirementChange={handleEmployeeExperienceUpdate}
                onRequirementDelete={deleteRequirement}
              />
            </TabsContent>
          </ScrollArea>
        </CardContent>
      </Card>
    </Tabs>
  );
}

function EmployeeExperiencesTab({
  experiences,
  onRequirementClick,
  onRequirementChange,
  onRequirementDelete,
}: {
  experiences: RequiredEmployeeExperience[];
  onRequirementClick: (requirement: RequiredExperience) => void;
  onRequirementChange: (requirement: RequiredExperience) => void;
  onRequirementDelete: (requirementId: string) => void;
}) {
  const [selectedResource, setSelectedResource] = React.useState<string>(
    experiences.length > 0 ? experiences[0].resourceName : "",
  );

  const selectedExperiences = React.useMemo(() => {
    const selectedEmployeeExperience = experiences.find(
      (exp) => exp.resourceName === selectedResource,
    );
    return selectedEmployeeExperience
      ? selectedEmployeeExperience.experiences
      : [];
  }, [experiences, selectedResource]);

  if (experiences.length === 0) {
    return (
      <div className="text-center">
        <FormattedMessage id="project.compliance.employeeExperiences.empty" />
      </div>
    );
  }

  return (
    <div>
      <Select value={selectedResource} onValueChange={setSelectedResource}>
        <SelectTrigger className="w-[200px] mb-4 ml-1">
          <SelectValue placeholder="Select a resource" />
        </SelectTrigger>
        <SelectContent>
          {experiences.map((exp) => (
            <SelectItem key={exp.resourceName} value={exp.resourceName}>
              {exp.resourceName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {selectedResource && (
        <RequiredEmployeeExperienceTable
          globalFilter={""}
          requirements={selectedExperiences}
          onRowClick={onRequirementClick}
          onChange={onRequirementChange}
          onDelete={onRequirementDelete}
        />
      )}
    </div>
  );
}

export default RequirementsCard;
