import { AuthManager } from "../AuthManager";
import { Capture, Evaluation } from "@/types/entities/Capture";

class CaptureController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async createEmployeeResourceEvaluation(projectId: string) {
    return this.authManager.request<Capture>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/employee-resource-evaluations/`,
    );
  }

  public async getEmployeeResourceEvaluations(projectId: string) {
    return await this.authManager.request<Capture[]>(
      "GET",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/employee-resource-evaluations/`,
    );
  }

  public async getEmployeeResourceEvaluation(
    projectId: string,
    evaluationId: string,
  ) {
    return await this.authManager.request<Capture>(
      "GET",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/employee-resource-evaluations/${evaluationId}/`,
    );
  }

  public async updateEmployeeResourceEvaluations(
    projectId: string,
    evaluationId: string,
    evaluations: Evaluation[],
  ) {
    return this.authManager.request<Capture>(
      "PATCH",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/employee-resource-evaluations/${evaluationId}/`,
      { evaluations },
    );
  }
}
export { CaptureController };
