import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
// import { Button } from "@/components/ui/button";
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuGroup,
//   DropdownMenuItem,
//   DropdownMenuLabel,
//   DropdownMenuSeparator,
//   DropdownMenuTrigger,
// } from "@/components/ui/dropdown-menu";
import { useUser } from "@/hooks/UserHook/UserHook";
import { useSidebar } from "@/providers";

// import { SeevClient } from "@/lib/SeevClient";
// import { FormattedMessage } from "react-intl";
// import { useNavigate } from "react-router-dom";

export function UserNav() {
  // const navigate = useNavigate();
  const user = useUser();
  const { isCollapsed } = useSidebar();

  // const onPrivacyPolicyClick = () => {
  //   navigate("/privacy");
  // };

  // const onLogout = async () => {
  //   await SeevClient.authManager.logout();
  //   navigate("/login");
  // };

  return (
    <div className="w-full py-4">
      <div className="flex items-center pl-5">
        <Avatar>
          <AvatarImage />
          <AvatarFallback>
            {user?.firstName?.length ? user.firstName.charAt(0) : ""}
            {user?.lastName?.length ? user.lastName.charAt(0) : ""}
          </AvatarFallback>
        </Avatar>
        {!isCollapsed && (
          <div className="ml-3 flex flex-col gap-1 font-light">
            <p className="text-sm !text-foreground w-[140px] truncate">
              {user?.firstName || ""} {user?.lastName || ""}
            </p>
            <p className="text-xs text-muted-foreground w-[140px] truncate">
              {" "}
              {user?.email || ""}
            </p>
          </div>
        )}
      </div>
    </div>

    // <DropdownMenu>
    //   <DropdownMenuTrigger asChild>
    //     <Button variant="ghost" className="relative h-8 w-8 rounded-full">
    //       <Avatar className="h-8 w-8">
    //         <AvatarImage />
    //         <AvatarFallback>
    //           {user?.firstName?.length ? user.firstName.charAt(0) : ""}
    //           {user?.lastName?.length ? user.lastName.charAt(0) : ""}
    //         </AvatarFallback>
    //       </Avatar>
    //     </Button>
    //   </DropdownMenuTrigger>
    //   <DropdownMenuContent className="w-56" align="end" forceMount>
    //     <DropdownMenuLabel>
    //       <div className="flex flex-col space-y-1">
    //         <p className="text-sm font-medium leading-none">
    //           {user?.firstName || ""} {user?.lastName || ""}
    //         </p>
    //         <p className="text-xs leading-none text-muted-foreground">
    //           {user?.email || ""}
    //         </p>
    //       </div>
    //     </DropdownMenuLabel>
    //     <DropdownMenuSeparator />
    //     <DropdownMenuGroup>
    //       <DropdownMenuItem onClick={onPrivacyPolicyClick}>
    //         <FormattedMessage id="privacy.title" />
    //       </DropdownMenuItem>
    //     </DropdownMenuGroup>
    //     <DropdownMenuSeparator />
    //     <DropdownMenuItem onClick={onLogout}>
    //       <FormattedMessage id="logout.title" />
    //     </DropdownMenuItem>
    //   </DropdownMenuContent>
    // </DropdownMenu>
  );
}
