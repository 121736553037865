import { EvaluationCriterion, Summary } from "@/types/entities/Project";
import EditableListCard from "./EvaluationCriteriaCard";
import { FormattedMessage } from "react-intl";

interface EvaluationCriteriaSectionProps {
  summary: Summary;
  loading: boolean;
  onSubmit: (summary: Summary) => Promise<void>;
}

function EvaluationCriteriaSection(props: EvaluationCriteriaSectionProps) {
  const { summary, onSubmit, loading } = props;

  const handleSubmit = async (newEvaluationCriteria: EvaluationCriterion[]) => {
    await onSubmit({
      ...summary,
      evaluationCriteria: newEvaluationCriteria,
    });
  };

  return (
    <EditableListCard
      title={
        <span>
          <FormattedMessage id="project.summaryFields.evaluationCriteria.title" />
          {summary.evaluationCriteria.length === 0 && (
            <span className="text-muted-foreground text-sm ml-1">
              (<FormattedMessage id="global.empty" />)
            </span>
          )}
        </span>
      }
      evaluationCriteria={summary.evaluationCriteria}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default EvaluationCriteriaSection;
