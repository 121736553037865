import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import * as Sentry from "@sentry/react";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useLocation, useNavigate } from "react-router-dom";
import { SeevClient } from "@/lib/SeevClient";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { Card, CardTitle } from "@/components/ui/card";
import { featureFlags } from "@/config/featureFlags";

const formSchema = z.object({
  email: z.string().optional(),
  password: z.string().optional(),
});

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    const from = location.state?.from?.pathname || "";
    const email = values.email || "";
    const password = values.password || "";

    try {
      const user = await SeevClient.authManager.login(email, password);
      Sentry.setUser({
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
      navigate(from || "/portfolio");
    } catch (error) {
      form.setError("password", {
        type: "manual",
        message: intl.formatMessage({ id: "login.invalidCredentials" }),
      });
    }

    setLoading(false);
  };

  // Handle Enter key press to submit the form
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      form.handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="w-full max-w-md">
      <Card className="p-8 bg-white/80 dark:bg-gray-800/80 rounded-lg shadow-lg">
        <div className="mb-6">
          <CardTitle className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100">
            <FormattedMessage id="global.login" />
          </CardTitle>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: "global.email",
                      })}
                      className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder={intl.formatMessage({
                          id: "global.password",
                        })}
                        className="bg-white/50 dark:bg-gray-700/50 backdrop-blur-sm"
                        onKeyDown={handleKeyDown}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-left">
                <Button
                  variant="link"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="text-xs text-indigo-600 dark:text-indigo-400 hover:underline p-0 h-auto"
                >
                  <FormattedMessage id="login.forgot" />
                </Button>
              </div>
            </div>
            <Button
              type="submit"
              disabled={loading}
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white dark:bg-indigo-500 dark:hover:bg-indigo-600 mt-2"
            >
              <FormattedMessage id="global.login" />
            </Button>
          </form>
        </Form>
        {featureFlags().enableSignup && (
          <div className="mt-6 text-center">
            <Button
              variant="link"
              onClick={() => {
                navigate("/signup");
              }}
              className="text-sm text-indigo-600 dark:text-indigo-400 hover:underline p-0"
            >
              <FormattedMessage id="login.signup" />
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
}

export default Login;
