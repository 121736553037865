import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import { ScrollArea } from "@/components/ui/scroll-area";
import RequirementsSummaryList from "./requirementsSummaryList";
import { Project } from "@/types/entities/Project";
import { Requirements } from "@/types/entities/Requirements";
import { RequirementMet } from "@/types/entities/Requirements";

interface RequirementsSummaryCardProps {
  project: Project;
}

function RequirementsSummaryCard({ project }: RequirementsSummaryCardProps) {
  const requirements_v2: Requirements | undefined = project.requirementsV2;

  const processRequirements = (
    items: Array<{ isMandatory: boolean; met?: RequirementMet }>,
    category: string,
  ) => {
    const total = items.length;
    const count = items.filter((item) =>
      ["human", "ai"].includes(item.met || ""),
    ).length;
    return { category, count, total };
  };

  const employeeExperiences =
    requirements_v2?.employeesExperiences.flatMap(
      (employee) => employee.experiences,
    ) || [];

  const requirementsSummary = requirements_v2
    ? [
        processRequirements(requirements_v2.documents, "DOCUMENT"),
        processRequirements(
          requirements_v2.companyExperiences,
          "COMPANY_EXPERIENCE",
        ),
        processRequirements(employeeExperiences, "EMPLOYEE_EXPERIENCE"),
      ]
    : [];

  return (
    <Card className="h-[317px]">
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="project.requirements.title" />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[243px]">
          {requirementsSummary.length > 0 ? (
            <RequirementsSummaryList requirements={requirementsSummary} />
          ) : (
            <FormattedMessage id="project.requirementsNotExists" />
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
}

export default RequirementsSummaryCard;
