import * as React from "react";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  Row,
  Table as TableType,
  CellContext,
} from "@tanstack/react-table";
import {
  RequiredCompanyExperience,
  RequirementMet,
} from "@/types/entities/Requirements";
import { ArrowUpDown } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { TextArea } from "@/components/ui/text-area";
import { TrashIcon, Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import AICheckbox from "@/components/AICheckox/AICheckbox";

interface EditableCellProps {
  colId: string;
  cell: CellContext<RequiredCompanyExperience, unknown>;
  onChange: (value: string) => void;
}

const EditableCell = (props: EditableCellProps) => {
  const { cell, onChange, colId } = props;
  const [edit, setEdit] = React.useState<string>();

  return cell.row.getIsSelected() ? (
    colId === "description" ? (
      <TextArea
        defaultValue={cell.row.getValue(colId)}
        value={edit}
        onChange={(event) => {
          onChange(event.target.value);
          setEdit(event.target.value);
        }}
      />
    ) : (
      <Input
        defaultValue={cell.row.getValue(colId)}
        value={edit}
        onChange={(event) => {
          onChange(event.target.value);
          setEdit(event.target.value);
        }}
      />
    )
  ) : (
    <div>{cell.row.getValue(colId)}</div>
  );
};

export interface RequirementTableProps {
  requirements: RequiredCompanyExperience[];
  globalFilter: string;
  onRowClick: (requirement: RequiredCompanyExperience) => void;
  onChange: (requirement: RequiredCompanyExperience) => void;
  onDelete: (requirementId: string) => void;
}

export const RequiredCompanyExperienceTable = ({
  requirements,
  globalFilter,
  onRowClick,
  onChange,
  onDelete,
}: RequirementTableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [editRequirementId, setEditRequirementId] = React.useState<
    string | null
  >(null);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const editRef = React.useRef<RequiredCompanyExperience | null>(null);
  const tableRef = React.useRef<TableType<RequiredCompanyExperience> | null>(
    null,
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      exactCopyFromTheSection: false,
      actions: false,
    });

  const unselectAll = React.useCallback(() => {
    if (!tableRef.current) return;

    tableRef.current
      .getRowModel()
      .rows.forEach((row) => row.toggleSelected(false));
    setIsEditing(false);
  }, [tableRef]);

  const selectRow = (
    row: Row<RequiredCompanyExperience>,
    requirementId: string,
  ) => {
    editRef.current = row.original;
    setEditRequirementId(requirementId);
    if (!row.getIsSelected()) {
      unselectAll();
      row.toggleSelected(true);
      setIsEditing(true);
    }
  };

  const handleChanges = React.useCallback(() => {
    if (!editRef.current || editRequirementId === null) return;

    unselectAll();
    onChange(editRef.current);
  }, [onChange, unselectAll, editRequirementId]);

  const handleCancel = React.useCallback(() => {
    unselectAll();
  }, [unselectAll]);

  React.useEffect(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      actions: isEditing,
    }));
  }, [isEditing]);

  const columns: ColumnDef<RequiredCompanyExperience>[] = React.useMemo(
    () => [
      {
        accessorKey: "met",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const handleCheckboxChange = (value: RequirementMet) => {
            const updatedRequirement = {
              ...row.original,
              met: value,
            };
            onChange(updatedRequirement);
          };

          return (
            <div className="flex w-full align-center items-center justify-center">
              <AICheckbox
                value={row.getValue("met")}
                onChange={handleCheckboxChange}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "title",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.fields.title" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="title"
            cell={cell}
            onChange={(value: string) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                title: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "description",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="project.fields.description" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: (cell) => (
          <EditableCell
            colId="description"
            cell={cell}
            onChange={(value) => {
              editRef.current = editRef.current && {
                ...editRef.current,
                description: value,
              };
            }}
          />
        ),
      },
      {
        accessorKey: "isMandatory",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              <FormattedMessage id="global.optional" />
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const handleCheckboxChange = () => {
            const updatedRequirement = {
              ...row.original,
              isMandatory: !row.original.isMandatory,
            };
            onChange(updatedRequirement);
            editRef.current = editRef.current && {
              ...editRef.current,
              isMandatory: !row.original.isMandatory,
            };
          };

          return (
            <div className="flex w-full align-center items-center justify-center">
              {row.getIsSelected() ? (
                <Badge
                  variant="secondary"
                  className="rounded bg-accent font-light px-1 cursor-pointer"
                  onClick={handleCheckboxChange}
                >
                  {row.original.isMandatory ? (
                    <FormattedMessage id="project.fields.isMandatory" />
                  ) : (
                    <FormattedMessage id="global.optional" />
                  )}
                </Badge>
              ) : !row.original.isMandatory ? (
                <Badge
                  variant="secondary"
                  className="rounded bg-accent font-light px-1"
                >
                  <FormattedMessage id="global.optional" />
                </Badge>
              ) : null}
            </div>
          );
        },
      },
      {
        accessorKey: "exactCopyFromTheSection",
      },
      {
        accessorKey: "actions",
        header: () => <></>,
        cell: ({ row }) => {
          return (
            row.getIsSelected() && (
              <div className="flex flex-wrap justify-center">
                <div className="flex">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleChanges()}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleCancel()}
                  >
                    <Cross2Icon />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      onDelete(row.original.id);
                      unselectAll();
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>
            )
          );
        },
      },
    ],
    [handleChanges, handleCancel, onDelete, onChange, unselectAll],
  );

  const table = useReactTable({
    data: requirements,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
      columnVisibility,
    },
  });

  tableRef.current = table;

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onRowClick(row.original)}
              onDoubleClick={() => selectRow(row, row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              <FormattedMessage id="project.compliance.companyExperiences.empty" />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RequiredCompanyExperienceTable;
