import { ProjectProgress } from "@/types/entities/Project";

export const calculateProgressColor = (projectProgress: ProjectProgress) => {
  let color = "bg-pastel-gray";
  switch (projectProgress) {
    case "GO_NO_GO":
      color = "bg-pastel-gray";
      break;
    case "NO_GO":
      color = "bg-pastel-red";
      break;
    case "PROPOSAL":
      color = "bg-sky-100";
      break;
    case "CANCELED":
      color = "bg-pastel-red";
      break;
    case "SUBMITTED":
      color = "bg-sky-100";
      break;
    case "WON":
      color = "bg-pastel-green";
      break;
    case "EXECUTION":
      color = "bg-sky-100";
      break;
    case "COMPLETED":
      color = "bg-pastel-green";
      break;
    case "LOST":
      color = "bg-pastel-red";
      break;
    default:
      color = "bg-pastel-gray";
      break;
  }
  return color;
};

export function calculateDaysBetween(
  submissionDeadline: string | null | undefined,
) {
  if (!submissionDeadline) {
    return 0;
  }
  const deadlineDate = new Date(submissionDeadline);
  const currentDate = new Date();
  const timeDifference = deadlineDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return daysDifference;
}

export function calculateColorDays(days: number) {
  if (days < 0) {
    return "bg-pastel-gray";
  } else if (days <= 5) {
    return "bg-pastel-red";
  } else if (days >= 6 && days <= 10) {
    return "bg-pastel-yellow";
  } else {
    return "bg-pastel-green";
  }
}

export function getExtImage(ext: string = "") {
  switch (ext) {
    case "pdf":
      return "/pdf.png";
    case "docx":
    case "doc":
    case "word":
      return "/doc.png";
    case "xls":
    case "xlsx":
    case "excel":
      return "/xls.png";
    default:
      return "/file.png";
  }
}
