import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type JsonObject = { [key: string]: JsonValue };
type JsonArray = JsonValue[];
export type JsonValue =
  | string
  | number
  | boolean
  | JsonObject
  | JsonArray
  | null
  | FormData;

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

function camelToSnake(s: string): string {
  return s.replace(/([A-Z])/g, (m) => `_${m.toLowerCase()}`);
}

export function recursiveCamelize(data: JsonValue): JsonValue {
  if (Array.isArray(data)) {
    return data.map(recursiveCamelize);
  } else if (data !== null && typeof data === "object") {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = snakeToCamel(key);
      (acc as JsonObject)[camelKey] = recursiveCamelize(
        (data as JsonObject)[key],
      );
      return acc;
    }, {} as JsonObject);
  }
  return data;
}

export function recursiveSnakify(data: JsonValue): JsonValue {
  if (data instanceof FormData) {
    const formData = new FormData();
    for (const [key, value] of data.entries()) {
      const snakeKey = camelToSnake(key);
      formData.append(snakeKey, value as string);
    }
    return formData;
  } else if (Array.isArray(data)) {
    return data.map(recursiveSnakify);
  } else if (data !== null && typeof data === "object") {
    return Object.keys(data).reduce((acc, key) => {
      const snakeKey = camelToSnake(key);
      (acc as JsonObject)[snakeKey] = recursiveSnakify(
        (data as JsonObject)[key],
      );
      return acc;
    }, {} as JsonObject);
  }
  return data;
}
