import { Token } from "@/types/Token";

class TokenManager {
  private authTokenKey: string = "authToken";
  private refreshTokenKey: string = "refreshToken";
  private authToken: Token;
  private refreshToken: Token;

  constructor() {
    this.authToken = { token: "", expiresAt: 0 };
    this.refreshToken = { token: "", expiresAt: 0 };
    this.loadTokens();
  }

  private loadTokens() {
    const authToken = localStorage.getItem(this.authTokenKey);
    if (authToken) {
      this.authToken = JSON.parse(authToken);
    }

    const refreshToken = localStorage.getItem(this.refreshTokenKey);
    if (refreshToken) {
      this.refreshToken = JSON.parse(refreshToken);
    }
  }

  public setAuthToken(authToken: Token) {
    this.authToken = authToken;
    localStorage.setItem(this.authTokenKey, JSON.stringify(authToken));
  }

  private setRefreshToken(refreshToken: Token) {
    this.refreshToken = refreshToken;
    localStorage.setItem(this.refreshTokenKey, JSON.stringify(refreshToken));
  }

  public getAuthToken() {
    return this.authToken;
  }

  public getRefreshToken() {
    return this.refreshToken;
  }

  public setTokens(authToken: Token, refreshToken: Token) {
    this.setAuthToken(authToken);
    this.setRefreshToken(refreshToken);
  }

  public clear() {
    this.authToken = { token: "", expiresAt: 0 };
    localStorage.removeItem("authToken");

    this.refreshToken = { token: "", expiresAt: 0 };
    localStorage.removeItem("refresh Token");
  }
}

export { TokenManager };
