import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { fuzzySearch } from "./FuzzySearch";
import { pdfjs } from "react-pdf";
import useDebounce from "@/utils/debounce";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

interface PDFPageProps {
  activePage: number;
  pageNumber: number;
  searchText: string;
  onScreen: (isOnScreen: boolean) => void;
}
interface FuzzySearchResult {
  start: number;
  end: number;
  dist: number;
}

function cleanText(text: string) {
  return text.toLowerCase().replace(/[^a-z]/g, "");
}

function findStartEndIndices(arr: string[], searchString: string): number[] {
  let cleanSearchText = cleanText(searchString);
  const cleanArr = arr.map(cleanText);
  const concatenated = cleanArr.join(""); // Concatenate all the cleaned array elements

  // Define a minimum length to stop at (e.g., when the text is too short)
  const minimumLength = cleanSearchText.length / 5;
  const maxDistance = 10;

  let results: FuzzySearchResult[] = [];

  // Repeatedly reduce the search text by 10% until we find a match or the text is too short
  while (results.length === 0 && cleanSearchText.length > minimumLength) {
    // Perform fuzzy search with the current cleanSearchText
    results = [...fuzzySearch(cleanSearchText, concatenated, maxDistance)];

    // If no match, reduce the search text by 10% from both the start and end
    if (results.length === 0) {
      const reductionLength = Math.floor(cleanSearchText.length / 10);

      // If the reduction would result in a string too short, break the loop
      if (reductionLength === 0 || cleanSearchText.length <= minimumLength) {
        break;
      }

      // Reduce from both start and end
      cleanSearchText = cleanSearchText.slice(
        reductionLength,
        cleanSearchText.length - reductionLength,
      );
    }
  }

  // If still no fuzzy matches are found, return [-1, -1]
  if (results.length === 0) {
    return [-1, -1];
  }

  // Take the best match found
  results.sort((a, b) => a.dist - b.dist);
  const bestMatch = results[0];
  const globalStart = bestMatch.start;
  const globalEnd = bestMatch.end;

  let startIndex = -1;
  let endIndex = -1;

  let accumulatedLength = 0;

  // Find both startIndex and endIndex in the same loop
  for (let i = 0; i < cleanArr.length; i++) {
    const currentTextLength = cleanArr[i].length;

    // Check if the start of the match falls within this string
    if (
      startIndex === -1 &&
      accumulatedLength + currentTextLength > globalStart
    ) {
      startIndex = i;
    }

    // Check if the end of the match falls within this string
    if (endIndex === -1 && accumulatedLength + currentTextLength >= globalEnd) {
      endIndex = i;
      break; // We can stop once both startIndex and endIndex are found
    }

    accumulatedLength += currentTextLength;
  }

  return [startIndex, endIndex];
}

function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);
  const debouncedSetIntersecting = useDebounce((isIntersecting: boolean) => {
    setIntersecting(isIntersecting);
  }, 100);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        debouncedSetIntersecting(entry.isIntersecting);
      }),
    [debouncedSetIntersecting],
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}

function PDFPage({
  activePage,
  pageNumber,
  searchText,
  onScreen,
}: PDFPageProps) {
  const pageRef = useRef<HTMLDivElement>(null);

  const [dictionary, setDictionary] = useState<
    { str: string; itemIndex: number }[]
  >([]);

  const highlightParts: { startIndex: number; endIndex: number } | null =
    useMemo(() => {
      if (dictionary.length === 0) return null;
      if (!searchText) return null;

      const text = dictionary.map((item) => item.str);
      const [startIndex, endIndex] = findStartEndIndices(text, searchText);

      return { startIndex, endIndex };
    }, [dictionary, searchText]);

  const textRenderer = useCallback(
    ({ str, itemIndex }: { str: string; itemIndex: number }) => {
      if (
        highlightParts &&
        highlightParts.startIndex <= itemIndex &&
        itemIndex <= highlightParts.endIndex
      ) {
        return `<mark style="background: #93dddc!important">${str}</mark>`;
      }
      return str;
    },
    [highlightParts],
  );

  useEffect(() => {
    setDictionary([]);
  }, []);

  const dictionaryBuilder = useCallback(
    ({ str, itemIndex }: { str: string; itemIndex: number }) => {
      dictionary.push({ str, itemIndex });
      setDictionary([...dictionary]);
      return str;
    },
    [dictionary],
  );

  const customTextRenderer =
    dictionary.length === 0 ? dictionaryBuilder : textRenderer;

  const customTextRendererIsActive =
    activePage === pageNumber ||
    activePage - 1 === pageNumber ||
    activePage + 1 === pageNumber;

  const isOnScreen = useOnScreen(pageRef);

  useEffect(() => {
    onScreen(isOnScreen);
  }, [isOnScreen, onScreen]);

  useEffect(() => {
    if (pageNumber === activePage) {
      pageRef.current?.scrollIntoView();
    }
  }, [pageNumber, activePage]);

  return (
    <div ref={pageRef}>
      <Page
        height={1000}
        width={625}
        key={pageNumber}
        pageNumber={pageNumber}
        renderTextLayer={true}
        customTextRenderer={
          customTextRendererIsActive ? customTextRenderer : undefined
        }
      />
    </div>
  );
}

export default PDFPage;
