import { Summary } from "@/types/entities/Project";

interface SummaryViewProps {
  summary: Summary;
}

function SummaryView(props: SummaryViewProps) {
  const { summary } = props;
  return <p className="p-4">{summary.rfpSummary}</p>;
}

export default SummaryView;
