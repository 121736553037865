import { SeevClient } from "@/lib/SeevClient";
import { User } from "@/types/User";
import { useEffect, useState } from "react";

function useUser() {
  const [user, setUser] = useState<User | null>(
    SeevClient.authManager.getUser(),
  );

  useEffect(() => {
    SeevClient.authManager.onLogin((user: User) => {
      setUser(user);
    });

    SeevClient.authManager.onLogout(() => {
      setUser(null);
    });
  }, []);

  return user;
}

export { useUser };
