import * as React from "react";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { OpportunitiesProject } from "@/types/entities/Opportunities";
//TODO: make this modules more globally for inherent both Project and Opportunities
import { DataTablePagination } from "@/modules/ProjectTable/components/data-table-pagination";
import ToolBar from "./tool-bar";
import { columns } from "./columns";
import { Loading } from "@/components/Loading/Loading";
import {
  OpportunityProgress,
  OpportunityDate,
} from "@/types/entities/Opportunities";

export interface OpportunitiesTableProps {
  projects: OpportunitiesProject[];
  isLoading: boolean;
  filterOnProgress: (progress: OpportunityProgress | "ALL") => void;
  filterOnDate: (date: OpportunityDate | "TODAY") => void;
}

export function OpportunitiesTable({
  // TODO: change name if opportunities item not define as a project
  projects,
  isLoading,
  filterOnProgress,
  filterOnDate,
}: OpportunitiesTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = React.useState<string>();

  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      createdAt: false,
    });

  const table = useReactTable({
    data: projects,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const navigate = useNavigate();

  const onRowClick = (projectId: string) => {
    // TODO: check if opportunities item define as a project?
    navigate(`/project/${projectId}`);
  };

  return (
    <div className="space-y-4 mb-14">
      <ToolBar
        setGlobalFilter={setGlobalFilter}
        filterOnProgress={filterOnProgress}
        filterOnDate={filterOnDate}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-40 text-center"
                >
                  <Loading messageId="opportunities.loading" />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => onRowClick(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <FormattedMessage id="opportunities.empty" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

export default OpportunitiesTable;
